// BvdS:  these should rather be functions that consume a number
// and construct the appropriate text.
export enum LicenseStatusText {
  consumed = 'license consumed',
  consumedPlural = 'licenses consumed',
  reserved = 'license reserved',
  reservedPlural = 'licenses reserved',
  unreserved = 'license available',
  unreservedPlural = 'licenses available',
  used = 'license used',
  usedPlural = 'licenses used',
  all = 'rostered student',
  allPlural = 'rostered students',
  // Before, unavailable = 'No license available', but we need to
  // distinguish between no license and no allocated licenses.
  unavailable = '-',
  unavailablePlural = 'No licenses available',
  purchased = 'license purchased',
  purchasedPlural = 'licenses purchased',
}
