// @ts-strict-ignore
export interface PartialUser {
  id?: number;
  name?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
}

export interface StudentTaskEvalDict {
  [id: number]: { [taskId: string]: string };
}

const debugPrint = false;

export const formatName = (user: PartialUser): string => {
  if (user.name) return user.name + (debugPrint ? ' ' + user.id : '');
  return (
    [user.firstName, user.middleName, user.lastName].filter((n) => n).join(' ') + (debugPrint ? ' ' + user.id : '')
  );
};

export const formatInitials = (user: PartialUser): string => {
  let nameArray = [];
  if (user.name) nameArray = user.name.split(' ');
  else nameArray = [user.firstName, user.middleName, user.lastName];
  return nameArray
    .filter((n) => n)
    .map((n) => n[0])
    .join('');
};

// sortBy order function for student name sorting
export const sortOrder = [
  (student: PartialUser): string =>
    (student.lastName || (student.name && student.name.split(' ').pop()) || '').toLowerCase(),
  (student: PartialUser): string => (student.firstName || student.name || '').toLowerCase(),
];

// sortBy order function for student task evaluation sorting
export const studentTaskEvalutationSortOrder = (studentTaskEvalDict: StudentTaskEvalDict) => [
  (student: PartialUser): number =>
    student.id in studentTaskEvalDict
      ? Object.values(studentTaskEvalDict[student.id]).reduce(
          (sum: number, x: string) => sum + (x == 'incorrect' || x == 'correctWithDifficulties' ? -1 : 0),
          0,
        )
      : 0,
  ...sortOrder,
];
