import React, { Dispatch, useEffect } from 'react';
import styled from '@emotion/styled/base';

import { classDisplayName, classSortOrder } from '../../../utils/class';
import { Filters } from '../../../api/adminAnalytics';
import { ClassWithTimes as Class } from '../../../../types/routes/accountManagement';

import { Text } from '../../../components/text';

interface Props {
  classes: Class[];
  ancestors: number[];
  show: boolean;
  filters: Filters;
  setFilters: Dispatch<React.SetStateAction<Filters>>;
  debugPrint: boolean;
}

export const ClassesSelector = ({ show, ancestors, filters, setFilters, classes, debugPrint }: Props) => {
  useEffect(() => {
    // If any ancestor is selected, unselect all classes
    let updated = false;
    ancestors.forEach((id) => {
      if (filters.institutions.includes(id))
        classes.forEach((theClass) => {
          const i = filters.classes.indexOf(theClass.id);
          if (i > -1) {
            updated = true;
            filters.classes.splice(i, 1);
          }
        });
    });
    if (updated) setFilters({ ...filters });
  }, [filters]);
  const sorted = classes.slice();
  sorted.sort(classSortOrder);

  const changeHandler = (classId: number): void => {
    const checked = filters.classes.indexOf(classId);
    if (debugPrint) console.log(`**** click checkbox for ${classId}: checked ${checked}`);
    if (checked > -1) {
      // checked -> unchecked
      filters.classes.splice(checked, 1);
    } else {
      // unchecked -> checked
      // Uncheck any ancestors (there will never be more than one)
      for (const id of ancestors) {
        const i = filters.institutions.indexOf(id);
        if (i > -1) filters.institutions.splice(i, 1);
      }
      filters.classes.push(classId);
    }
    setFilters({ ...filters });
  };

  // Only show section when there is an ambiguity
  return (
    <>
      {show &&
        classes &&
        sorted.map((classItem) => (
          <LeftAlignedClassText variant={'p'} key={`class-row-${classItem.id}`}>
            <label onClick={(e) => e.stopPropagation()}>
              <input
                type="checkbox"
                name={classDisplayName(classItem)}
                onChange={() => changeHandler(classItem.id)}
                checked={filters.classes.includes(classItem.id)}
              />
              {classDisplayName(classItem, {
                section: sorted.some(
                  (c) =>
                    c.id != classItem.id &&
                    c.nickName == classItem.nickName &&
                    c.externalName == classItem.externalName,
                ),
              }) +
                (classItem.active ? '' : ' (deleted)') +
                (debugPrint ? ' (' + classItem.id + ')' : '')}
            </label>
          </LeftAlignedClassText>
        ))}
    </>
  );
};

const LeftAlignedClassText = styled(Text)({
  marginLeft: '1rem',
  textAlign: 'left',
});
