import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { PageContainer } from '../components/pageContainer';
import { Header } from '../components/header';

import { primary } from '../utils/colors';
import { LeftNav } from '../components/leftNav';
import { Text } from '../components/text';
import { ActionBar } from '../components/styles';
import { setState } from '../redux/actions/user';

export const TutorialVideo: React.FC = () => {
  const dashboard = useSelector((state) => state.user.dashboard);
  const dispatch = useDispatch();
  const showVideo = true;

  useEffect(() => {
    if (!dashboard?.tutorialVideoViewed) dispatch(setState({ tutorialVideoViewed: true }));
  }, []);

  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar data-cy="tutorial-video-page">
        <Text variant="lg2" color={primary}>
          Welcome to Prisms
        </Text>
      </ActionBar>
      {!showVideo && (
        <StyledText variant="md">
          The Prisms Dashboard is designed to provide educators the ability to see and monitor student progress in VR
          and provide just-in-time feedback and assistance. Please reach out to Prisms staff for training on the Prisms
          Dashboard.
        </StyledText>
      )}
      {/* Add video here! use sample youtube video for now */}
      {showVideo && (
        <>
          <StyledText variant="md">
            <span>
              The Prisms Dashboard is designed to provide educators the ability to see and monitor student progress in
              VR and provide just-in-time feedback and assistance. If you need additional assistance with the Dashboard,
              please submit a support ticket{' '}
            </span>
            <StyledAnchor href="https://share.hsforms.com/1ItTGWzdXRk-l-WTou7feYwbwtrt" target="_blank">
              here
            </StyledAnchor>
            <span>.</span>
          </StyledText>
          <StyledVideo
            width="560"
            height="315"
            src="https://assets.prismsvr.com/teacher-dashboard/tutorial.mp4"
            controls
          ></StyledVideo>
        </>
      )}
    </PageContainer>
  );
};

const StyledVideo = styled.video({
  margin: '1rem auto 1rem',
  display: 'block',
});

const StyledText = styled(Text)({
  margin: '3rem',
  display: 'block',
});

const StyledAnchor = styled.a({
  color: primary,
  textDecoration: 'none',
});
