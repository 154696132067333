// @ts-strict-ignore
import React from 'react';
import { Alert } from './Alert';

export type LicensesLeftAlertProps = {
  licensesLeft: number | null;
};

export const LicensesLeftAlert: React.FC<LicensesLeftAlertProps> = ({ licensesLeft }) => {
  const LICENSES_LEFT_ALERT_THRESHOLD = 5;
  return (
    Number.isInteger(licensesLeft) &&
    licensesLeft < LICENSES_LEFT_ALERT_THRESHOLD && (
      <Alert
        severity={licensesLeft ? 'warning' : 'error'}
        title="License Usage Alert"
      >{`You have ${licensesLeft} license${licensesLeft === 1 ? '' : 's'} left.`}</Alert>
    )
  );
};
