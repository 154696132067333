// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import { primary } from '../utils/colors';
import { Text } from './text';
import { Class as ClassWithStudents } from '../../types/routes/class';
import { classDisplayName } from '../utils/class';

interface Props {
  pageTitle?: string;
  CustomIcon: React.FC<any>;
  classOnlyData?: boolean;
  classroomClasses?: ClassWithStudents[];
  currentClass?: ClassWithStudents;
}

export const ClassroomPageTitle: React.FC<Props> = ({
  pageTitle = '',
  CustomIcon,
  classOnlyData = false,
  classroomClasses = [],
  currentClass = { nickName: '', externalName: '' },
}) => {
  if (pageTitle && CustomIcon) {
    return (
      <TitleFlexText variant="lg2" color={primary}>
        <StyledIconHolder>
          <CustomIcon />
        </StyledIconHolder>
        <div data-cy="classroomPageTitle-header-title">
          {pageTitle}
          {!classOnlyData && (
            <Text variant="nav" color={primary}>
              {classroomClasses.map((c, index) => (
                <span key={index}>
                  {index != 0 && <> & </>}
                  {classDisplayName(c)}
                </span>
              ))}
            </Text>
          )}
          {classOnlyData && (
            <Text variant="nav" color={primary}>
              {classDisplayName(currentClass)}
            </Text>
          )}
        </div>
      </TitleFlexText>
    );
  }
};

const TitleFlexText = styled(Text)({
  display: 'flex',
});

const StyledIconHolder = styled.div({
  display: 'inline-flex',
  alignContent: 'center',
  margin: 'auto',
  height: '100%',
  paddingRight: '1rem',
});
