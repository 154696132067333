// @ts-strict-ignore
import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetEventsResponse } from '../../../types/routes/module';
import {
  getEvents as apiGetEvents,
  markMessagesAsRead as apiMarkMessagesAsRead,
  MarkMessageRequest,
  getDefinitions as apiGetDefinitions,
  getVersionDefinitions as apiGetVersionDefinitions,
  getLibrary as apiGetLibrary,
} from '../../api/module';
import { State as ModuleState } from '../reducers/module';

export interface EventSelector {
  classroomId?: number | null;
  classId?: number;
  moduleId?: string | null;
}

export const eventSelector = ({ classId, classroomId, moduleId }: EventSelector): string => {
  return `${classroomId ? 'classroom' : 'class'}:${classroomId || classId}:${moduleId || '*'}`;
};

export const getEvents = createAsyncThunk<
  GetEventsResponse & { classId?: number; classroomId?: number; moduleId?: string; after: number },
  EventSelector & { before?: number | null },
  { state: { module: ModuleState }; before?: number | null }
>('/events/module/get', async ({ classId, classroomId, moduleId, before }, { getState }) => {
  const debugPrint = false;
  const state = getState();
  const cc = classroomId ? 'classroomId' : 'classId';
  const ccmPath = eventSelector({ [cc]: classroomId || classId, moduleId });
  const after = state.module.maxEventId[ccmPath] || 0;
  if (debugPrint)
    console.log(
      `*** request log events for ${cc} ${classroomId || classId}, module ${moduleId || '*'}, after = ${after}`,
    );
  const result = await apiGetEvents(classroomId || null, classId, moduleId || null, after, before);
  if (debugPrint) console.log(`*** result with ${result.events.length} events`);
  const x = {
    ...result,
    [cc]: classroomId || classId,
    after,
  };
  if (moduleId) return { ...x, moduleId };
  else return x;
});

export const markMessagesAsRead = createAsyncThunk(
  'modules/events/mark-messages-as-read',
  async (data: MarkMessageRequest): Promise<void> => {
    await apiMarkMessagesAsRead(data);
  },
);

export const getDefinitions = createAsyncThunk('module/definition', async () => {
  return await apiGetDefinitions();
});

export const getVersionDefinitions = createAsyncThunk('module/version/definition', async (version: string) => {
  return await apiGetVersionDefinitions(version);
});

export const getLibrary = createAsyncThunk('module/library', async () => {
  return await apiGetLibrary();
});
