import React, { MouseEvent } from 'react';
import styled, { CSSObject } from '@emotion/styled';

interface Props {
  variant: VariantKey;
  color?: string;
  bold?: boolean;
  letterSpacing?: number;
  center?: boolean;
  right?: boolean;
  onClick?: (event: MouseEvent) => void;
  dangerouslySetInnerHTML?: { __html: string };
  children?: React.ReactNode;
  className?: string;
}

type VariantKey = 'sm' | 'md' | 'lg' | 'lg2' | 'lg3' | 'nav' | 'p' | 'normal' | 'inherit' | 'not';

export const Text = ({ children, ...rest }: Props) => {
  return <StyledDiv {...rest}>{children}</StyledDiv>;
};

const variants: { [key in VariantKey]: CSSObject } = {
  sm: {
    fontWeight: 400,
    fontSize: '.625rem',
    lineHeight: '.875rem',
  },
  md: {
    fontWeight: 400,
    fontSize: '1.125rem',
  },
  lg: {
    fontWeight: 400,
    fontSize: '3.75rem',
  },
  lg2: {
    fontWeight: 300,
    fontSize: '2rem',
  },
  lg3: {
    fontWeight: 300,
    fontSize: '1.8rem',
  },
  nav: {
    fontWeight: 400,
    fontSize: '.875rem',
  },
  p: {
    marginTop: '0.25rem',
    fontWeight: 300,
    fontSize: '.875rem',
  },
  normal: {
    fontWeight: 300,
    fontSize: '.875rem',
  },
  not: {
    fontWeight: 300,
    fontSize: '.875rem',
    textDecoration: 'line-through',
  },
  inherit: {},
};

const baseStyle: CSSObject = {
  fontFamily: 'Roboto, sans-serif',
  transition: 'color .3s',
  whiteSpace: 'break-spaces',
};

const StyledDiv = styled.div<Props>(
  baseStyle,
  ({ color = 'inherit', bold = false, letterSpacing, center, right, variant }) => {
    const css = {
      ...variants[variant],
      color,
      fontWeight: bold ? 'bold' : variants[variant].fontWeight,
    };
    if (letterSpacing) css.letterSpacing = `${letterSpacing}px`;
    if (center) css.textAlign = 'center';
    if (right) css.textAlign = 'right';
    return css;
  },
);

export const textStyle = (variant: VariantKey) => {
  return { ...baseStyle, ...variants[variant] };
};
