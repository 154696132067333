import {
  Array,
  Partial,
  Record,
  String,
  Number,
  Static,
  InstanceOf,
  Boolean,
  Null,
  Undefined,
  Unknown,
} from 'runtypes';
import { ContractRecord } from './accountManagement';
import { Route } from './utils';

export const classRoutes = (): { [name: string]: Route } => ({
  getClass: {
    method: 'get',
    path: '/class/:classId',
    requestBody: Record({}),
    responseData: ClassRecord,
  },
  getClasses: {
    method: 'get',
    path: '/classes',
    requestBody: GetClassesRequestRecord,
    responseData: GetClassesResponseRecord,
  },
  createClass: {
    method: 'post',
    path: '/class',
    requestBody: PostClassRequestRecord,
    responseData: PostClassResponseRecord,
  },
  editClass: {
    method: 'put',
    path: '/class',
    requestBody: EditClassRequestRecord,
    responseData: EditClassResponseRecord,
  },
  editClassNickName: {
    method: 'put',
    path: '/class/:classId/nickname',
    requestBody: EditClassNickNameRequestRecord,
    responseData: EditClassNickNameResponseRecord,
  },
  removeClass: {
    method: 'put',
    path: '/class/:classId/remove',
    requestBody: Record({}),
    responseData: RemoveClassResponseRecord,
  },
  /*
   * Licenses are associated with a contract but,
   * in order to handle permissions properly, the call
   * is made with respect to a class.  If the contractId is
   * used, it should be verified.
   */
  updateLicenseStatus: {
    method: 'patch',
    path: '/class/:classId/user/:userId/license',
    requestBody: UpdateUserLicenseRequestRecord,
    responseData: UpdateUserLicenseResponseRecord,
  },
  getContracts: {
    method: 'get',
    path: '/class/:classId/contracts',
    requestBody: Record({}),
    responseData: ClassContractsRecord,
  },
  getClassLearnerTypes: {
    method: 'get',
    path: '/class/:classId/learner-types',
    requestBody: GetLearnerTypesRequestRecord,
    responseData: GetLearnerTypesResponseRecord,
  },
  updateLearnerType: {
    method: 'patch',
    path: '/class/:classId/user/:userId/learner-type',
    requestBody: updateLearnerTypeRequestRecord,
    responseData: updateLearnerTypeResponseRecord,
  },
  pushOrInsertClassState: {
    method: 'patch',
    path: '/class/:classId/state',
    requestBody: PushOrInsertClassStateRequestRecord,
    responseData: ClassStateResponseRecord,
  },
  getClassState: {
    method: 'get',
    path: '/class/:classId/state',
    requestBody: Record({}),
    responseData: ClassStateResponseRecord,
  },
});

const TeacherRecord = Record({
  id: Number,
  name: String.Or(Null),
  firstName: String.Or(Null),
  middleName: String.Or(Null),
  lastName: String.Or(Null),
  email: String,
  key: String,
  createdAt: InstanceOf(Date),
});

export const StudentRecord = TeacherRecord.And(
  Record({
    licenseStatus: String.Or(Null),
    contractId: Number.Or(Null),
  }),
);

const NewStudentRecord = Record({
  firstName: String.Or(Null),
  middleName: String.Or(Null),
  lastName: String.Or(Null),
  email: String,
});

export const ClassRecord = Record({
  id: Number,
  externalName: String,
  nickName: String.Or(Null),
  section: String.Or(Null),
  institutionId: Number.Or(Null),
  classroomId: Number.Or(Null),
  createdAt: InstanceOf(Date),
  students: Array(StudentRecord),
  teachers: Array(TeacherRecord),
  externalSource: String.Or(Null),
  externalInstance: String.Or(Null),
  externalId: String.Or(Null),
  startAt: InstanceOf(Date).Or(Null),
  endAt: InstanceOf(Date).Or(Null),
});

export const GetClassesRequestRecord = Record({});
export const GetClassesResponseRecord = Record({
  classes: Array(ClassRecord),
});
export const PostClassRequestRecord = Record({
  externalName: String,
  nickName: String.Or(Null),
  section: String.Or(Null),
  institutionId: Number.Or(Null),
  students: Array(NewStudentRecord),
});
export const PostClassResponseRecord = ClassRecord;
export const EditClassRequestRecord = Record({
  id: Number,
  externalName: String,
  nickName: String.Or(Null),
  section: String.Or(Null),
  institutionId: Number.Or(Null),
  students: Array(NewStudentRecord.Or(StudentRecord)),
});
export const EditClassNickNameRequestRecord = Record({
  id: Number,
  nickName: String.Or(Null),
});
export const EditClassNickNameResponseRecord = Record({
  id: Number,
  nickName: String.Or(Null),
});

export const UpdateUserLicenseRequestRecord = Record({
  licenseStatus: String,
  contractId: Number,
});

export const GetLearnerTypesRequestRecord = Record({});
export const GetLearnerTypesResponseRecord = Array(
  Record({
    classId: Number,
    userId: Number,
    firstLearnerType: String,
    secondLearnerType: String,
  }),
);

export const updateLearnerTypeRequestRecord = Record({
  firstLearnerType: String,
  secondLearnerType: String,
});

export const updateLearnerTypeResponseRecord = Record({
  classId: Number,
  userId: Number,
  firstLearnerType: String,
  secondLearnerType: String,
});

export const StateRecord = Record({
  name: String,
  value: Unknown.Or(Undefined),
});

const UserStateRecord = StateRecord.And(
  Record({
    userId: Number.Or(Null),
  }),
);

export const ClassUserStateDetailRecord = StateRecord.And(
  Record({
    userId: Number.Or(Null),
    createdAt: InstanceOf(Date),
  }),
);

export const PushOrInsertClassStateRequestRecord = Record({
  push: Boolean,
  undoOverrides: Boolean,
  data: Array(UserStateRecord),
}).And(
  Partial({
    save: Boolean,
  }),
);

export const ClassStateResponseRecord = Record({
  classId: Number,
  userStates: Array(ClassUserStateDetailRecord),
});
export const EditClassResponseRecord = ClassRecord;
export const DeleteClassRequestRecord = Record({});
export const DeleteClassResponseRecord = Undefined.Or(Null);
export const RemoveClassResponseRecord = Undefined.Or(Null);
export const ClassContractsRecord = Record({
  classId: Number,
  contracts: Array(ContractRecord),
});
export const UpdateUserLicenseResponseRecord = Undefined.Or(Null);

export type NewStudent = Static<typeof NewStudentRecord>;
export type Student = Static<typeof StudentRecord>;
export type Teacher = Static<typeof TeacherRecord>;
export type Class = Static<typeof ClassRecord>;
export type GetClassesRequest = Static<typeof GetClassesRequestRecord>;
export type GetClassesResponse = Static<typeof GetClassesResponseRecord>;
export type PostClassRequest = Static<typeof PostClassRequestRecord>;
export type PostClassResponse = Static<typeof PostClassResponseRecord>;
export type EditClassRequest = Static<typeof EditClassRequestRecord>;
export type EditClassResponse = Static<typeof EditClassResponseRecord>;
export type EditClassNickNameRequest = Static<typeof EditClassNickNameRequestRecord>;
export type EditClassNickNameResponse = Static<typeof EditClassNickNameResponseRecord>;
export type ClassContracts = Static<typeof ClassContractsRecord>;
export type UpdateUserLicenseRequest = Static<typeof UpdateUserLicenseRequestRecord>;
export type UpdateUserLicenseResponse = Static<typeof UpdateUserLicenseResponseRecord>;
export type GetLearnerTypesResponse = Static<typeof GetLearnerTypesResponseRecord>;
export type updateLearnerTypeRequest = Static<typeof updateLearnerTypeRequestRecord>;
export type updateLearnerTypeResponse = Static<typeof updateLearnerTypeResponseRecord>;
export type State = Static<typeof StateRecord>;
export type UserState = Static<typeof UserStateRecord>;
export type ClassUserStateDetail = Static<typeof ClassUserStateDetailRecord>;
export type ClassStateResponse = Static<typeof ClassStateResponseRecord>;
export type SetClassState = Static<typeof PushOrInsertClassStateRequestRecord>;
