// @ts-strict-ignore
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Alert as MuiAlert, AlertColor, AlertProps, AlertTitle } from '@mui/material';
import { textStyle } from './text';
import { omit } from 'lodash';
import { white } from '../utils/colors';

// Two main types of alert display behavior are supported by <Alert>:
// Default: Managed by Child Component
//    One-off alerts with close button
//    <Alert>
// Advanced Bind: Managed By Parent
//    Multi-use alerts. Parent can pass "show Alert" type variable to isOpen for visibiltiy toggle control):
//    <Alert isOpen={bindLocalDynamicDisplayVar}>

// Three alert themes are supported by <Alert>:
// Inline
//   <Alert>
// Blocking (centered with overlay behind)
//   <Alert blocking={true}>

interface Props {
  title?: string;
  // render children (custom alerts)
  children?: React.ReactNode;
  severity?: AlertColor;
  blocking?: boolean;
  // only pass isOpen if you plan to bind it to your local display code
  isOpen?: boolean;
  hideX?: boolean;
  closeAlert?: () => void;
}

export const Alert: React.FC<Props> = (props) => {
  const { severity = 'info', blocking = false, title = '', children = null, hideX = false } = props;
  let { isOpen = true } = props;
  // set defaults

  const [hideAlert, setHideAlert] = useState(false);

  const closeAlertLogic = () => {
    // When closing any alert, call closeAlert callback function first
    if ('closeAlert' in props) {
      props.closeAlert();
    }
    // If visibility is not externally managed and alert isOpen: close alert.
    if (!('isOpen' in props)) {
      if (isOpen) {
        setHideAlert(true);
        isOpen = false;
      }
    }
  };

  // if there are child objects, disable alert onClick dismiss
  // prevents issues with buttons in alert children
  const childLockCloseAlertLogic = () => {
    if (children == null) {
      closeAlertLogic();
    }
  };

  const muiProps: AlertProps = {
    title: title,
    severity,
    ...(!hideX && { onClose: closeAlertLogic }),
    onClick: childLockCloseAlertLogic,
  };

  // Eagerly hide alert
  if (!isOpen || hideAlert) return null;
  if (!blocking)
    return (
      <InlineAlert variant="outlined" {...omit(muiProps, 'title')}>
        {muiProps.title && <AlertTitle>{muiProps.title}</AlertTitle>}
        {children}
      </InlineAlert>
    );
  if (blocking)
    return (
      <AlertOverlay onClick={closeAlertLogic}>
        <OverlayAlert variant="outlined" {...omit(muiProps, 'title')}>
          {muiProps.title && <AlertTitle>{muiProps.title}</AlertTitle>}
          {children}
        </OverlayAlert>
      </AlertOverlay>
    );
};

// Styling
const InlineAlert = styled(MuiAlert)({
  ...textStyle('nav'),
  margin: '1rem',
});

const OverlayAlert = styled(MuiAlert)({
  ...textStyle('nav'),
  position: 'relative',
  margin: 'auto',
  top: '20vh',
  width: '400px',
  zIndex: 102,
  backgroundColor: white,
});

const AlertOverlay = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .25)',
  zIndex: 101, // zIndex 100 holds some other filter overlays etc we want to remain compatible with.
});

export const ExtraAlertText = styled.p({
  ...textStyle('nav'),
  marginBottom: 0,
});
