import React from 'react';
import styled from '@emotion/styled';
import { Text } from '../../components/text';
import { black, charcoal15, primary } from '../../utils/colors';
import { moduleListStyles } from '../../pages/moduleList';
import { Institution } from '../../../types/routes/accountManagement';
import { displayName } from '../../utils/institution';

export const InstitutionTile: React.FC<{
  institution: Institution;
}> = ({ institution }) => {
  return (
    <Institution>
      <InstitutionContent to={`/admin/classes/institution/${institution.id}`}>
        <Text variant="sm" center color={black}>
          {institution.level ? institution.level[0].toUpperCase() + institution.level.slice(1) : ' '}
        </Text>
        <Title variant="md" center color={black}>
          {displayName(institution)}
        </Title>
      </InstitutionContent>
    </Institution>
  );
};

const Institution = styled.div({
  border: `1px solid ${primary}`,
  borderRadius: 3,
  boxShadow: `0 5px 10px ${charcoal15}`,
  position: 'relative',
  margin: '1rem',
});

const InstitutionContent = styled(moduleListStyles.ModuleContent)({
  padding: '1.25rem 1.25rem 1.25rem',
});

const { Title } = moduleListStyles;
