// @ts-strict-ignore
import React, { FC, useEffect, useState } from 'react';
import { RouteProps, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';

import { AuthOverlay } from './authOverlay';
import { Modal } from './modal';
import { user, signout as signoutAction } from '../redux/actions/user';
import { UserAccountStatus } from '../../types/models';
import { COOKIE_MAX_AGE, debugAuth } from '../../settings';

const printMilliseconds = (ms: number): string => {
  if (Math.abs(ms) < 100 * 1000.0) return Math.floor(ms / 1000.0) + ' seconds';
  else return Math.floor(ms / (60.0 * 1000)) + ' minutes';
};

const minutesToMs = (minutes: number) => {
  return minutes * 60 * 1000;
};

export const AuthRoute: FC<RouteProps> = (props: RouteProps) => {
  const [focus, setFocus] = useState(document.hasFocus());
  const status = useSelector((state) => state.user.status);
  const userFetchLastSuccess = useSelector((state) => state.user.userFetchLastSuccess);
  const fetchedUser = useSelector((state) => state.user.fetchedUser);
  const fetchingUser = useSelector((state) => state.user.fetchingUser);
  const externalSource = useSelector((state) => state.user.externalSource);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [lastExternalSource, setLastExternalSource] = useState(null);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(60);

  // LAUSD requires 60 minutes
  // Debug mode is 12 seconds and 18 seconds
  const idleMinutesLimit = debugAuth ? 0.3 : 59;
  const popupAfterXMinutes = debugAuth ? 0.2 : 50;
  const totalTimeBeforeLogout = minutesToMs(idleMinutesLimit); // time between user going idle and onIdle called in milliseconds
  const timeout = minutesToMs(popupAfterXMinutes); // time between prompt and onIdle called in milliseconds
  const promptTimeout = minutesToMs(idleMinutesLimit - popupAfterXMinutes); // time between user going idle and onPrompt in milliseconds

  const checkCookieIdle = () => {
    if (userFetchLastSuccess) {
      if (userFetchLastSuccess < Date.now() - idleMinutesLimit) {
        checkPopulateAndShowPrompt('checkCookieIdle');
        return true;
      }
      return false;
    }
  };

  const checkCookieTimeout = () => {
    if (userFetchLastSuccess) {
      if (userFetchLastSuccess < Date.now() - COOKIE_MAX_AGE) {
        dispatch(user());
      }
    }
  };

  const checkPopulateAndShowPrompt = (source: string) => {
    const now = Date.now();
    const previousActionTime = Number(window.localStorage.getItem('customPersistIdleTimer'));
    const timeoutLimit = previousActionTime + totalTimeBeforeLogout;
    const tRemaining = timeoutLimit - now;
    const timeoutLimitCookie = userFetchLastSuccess + COOKIE_MAX_AGE;
    const tRemainingCookie = timeoutLimitCookie - now;
    const minTimeRemaining = Math.min(tRemaining, tRemainingCookie);
    debugAuth && console.log(`${source} Remaining ${tRemaining / 1000}s Cookie Remaining=${tRemainingCookie / 1000}s`);
    if (minTimeRemaining < promptTimeout) {
      setTimeRemaining(minTimeRemaining);
      setOpenPrompt(true);
    } else {
      setOpenPrompt(false);
    }
  };

  const closePromptAction = () => {
    if (!fetchingUser && fetchedUser) {
      const cookieIdle = checkCookieIdle();
      if (cookieIdle) {
        dispatch(user());
      } else {
        activate();
      }
    }
  };

  const focusSafeSignoutAction = () => {
    // only sign out if page is in focus
    if (focus) {
      window.localStorage.removeItem('customPersistIdleTimer');
      dispatch(signoutAction());
    } else {
      checkPopulateAndShowPrompt('blocked background signout');
    }
  };

  const checkAndHandleExpiration = () => {
    const now = Date.now();
    // check for expiration.
    if (window.localStorage.getItem('customPersistIdleTimer')) {
      const previousActionTime = Number(window.localStorage.getItem('customPersistIdleTimer'));
      const timeoutLimit = previousActionTime + totalTimeBeforeLogout;
      if (timeoutLimit <= now) {
        if (!fetchingUser) {
          if (fetchedUser) {
            focusSafeSignoutAction();
          } else {
            dispatch(signoutAction());
            navigate('/login');
          }
        }
      }
    }
    // if logged in, set action time to now.
    if (focus && !fetchingUser && fetchedUser) {
      window.localStorage.setItem('customPersistIdleTimer', now.toString());
      // check for cookie session maxAge timeout
      checkCookieTimeout();
      checkCookieIdle();
    }
  };

  const onAction = () => {
    // each time action is detected.
    focus && activate();
    checkAndHandleExpiration();
  };

  const onIdle = () => {
    debugAuth && console.log('onIdle');
    focusSafeSignoutAction();
    setTimeRemaining(0);
  };

  const onActive = () => {
    debugAuth && console.log('onActive');
  };

  const onPrompt = () => {
    checkPopulateAndShowPrompt('onPrompt');
  };

  // setup react-idle-timer
  const { activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    promptTimeout,
    timeout,
    onAction,
  });

  // additional focus logic added for multi-tab support and cases where timer fails
  // focus also fires when page first loads
  useEffect(() => {
    // if page comes into focus and we are not fetching user
    // and we think we are logged in double check by calling user().
    debugAuth && console.log(focus);
    if (focus && !fetchingUser && fetchedUser) {
      if (timeRemaining <= 0) {
        focusSafeSignoutAction();
      } else {
        checkCookieTimeout();
      }
    }
  }, [focus]);

  useEffect(() => {
    if (!fetchingUser) {
      if (fetchedUser) {
        // Handle refresh if user has just been disabled.
        if (status == UserAccountStatus.disabled) {
          dispatch(signoutAction());
          navigate('/login');
        }
        checkAndHandleExpiration();
        setLastExternalSource(externalSource);
      } else {
        dispatch(signoutAction());
        if (['lti'].includes(lastExternalSource)) {
          // When the last_login_method param is set, the prisms web login options are hidden.
          // User is presented only with "Logout Successful"
          navigate('/login?last_login_method=' + lastExternalSource);
        } else {
          navigate('/login');
        }
      }
    }
  }, [fetchingUser, fetchedUser]);

  const initializeFocus = () => {
    const onFocus = () => setFocus(true);
    const onBlur = () => setFocus(false);
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  };

  useEffect(() => {
    onAction();
    setTimeout(() => {
      initializeFocus();
    }, 1000);
  }, []);

  if (fetchingUser) {
    return <AuthOverlay />;
  } else
    return (
      <>
        <Modal
          isOpen={openPrompt}
          closeModal={() => closePromptAction()}
          title={
            timeRemaining > 0
              ? `You have been idle for ${printMilliseconds(
                  totalTimeBeforeLogout - timeRemaining,
                )}, you will be automatically logged out in ${printMilliseconds(timeRemaining)}.`
              : `Your session expired, please log in again.`
          }
        />
        {props.element != undefined ? props.element : <AuthOverlay />}
      </>
    );
};
