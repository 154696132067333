// @ts-strict-ignore
import React, { useEffect, Dispatch } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { gray } from '../../../utils/colors';

import { Filters } from '../../../api/adminAnalytics';
import { Institution } from '../../../../types/routes/accountManagement';
import {
  getInstitutionChildrenAction,
  getInstitutionClassesWithTimesAction,
} from '../../../redux/actions/accountManagement';

import { textStyle, Text } from '../../../components/text';
import { ClassesSelector } from './ClassesSelector';
import { displayName, institutionSortOrder } from '../../../utils/institution';
import { classSortOrder } from '../../../utils/class';

interface Props {
  filters: Filters;
  ancestors: number[];
  setFilters: Dispatch<React.SetStateAction<Filters>>;
  institution: Institution;
  debugPrint: boolean;
}

export const InstitutionsSelector = ({ filters, ancestors, setFilters, institution, debugPrint }: Props) => {
  const query = new URLSearchParams(window.location.search);
  const timing = query.get('timing') ? true : false;
  const dispatch = useDispatch();
  const institutions = useSelector((state) => state.accountManagement.institutions);
  const allClasses = useSelector((state) => state.accountManagement.classesWithTimes);
  const children = institution.children.map((id) => institutions[id]).filter((x) => x);
  children.sort(institutionSortOrder);

  const classes = institution.classes.map((id) => allClasses[id]).filter((x) => x);
  classes.sort(classSortOrder);

  useEffect(() => {
    // If any ancestor is selected, unselect this institution
    let updated = false;
    ancestors.forEach((id) => {
      if (filters.institutions.includes(id)) {
        const i = filters.institutions.indexOf(institution.id);
        if (i > -1) {
          updated = true;
          filters.institutions.splice(i, 1);
        }
      }
    });
    if (updated) setFilters({ ...filters });
  }, [filters]);

  // Use localStorage instead of REACT state mechanism
  const statePath = `admin/filter/institution/${institution.id}`;
  const state = JSON.parse(window.localStorage.getItem(statePath)) || {
    open: false,
  };

  const detailsHandler = (target: EventTarget) => {
    state.open = (target as HTMLDetailsElement).open;
    window.localStorage.setItem(statePath, JSON.stringify(state));

    // Dispatch all children of the institution, if open
    if (state.open) {
      for (const childId of institution.children)
        if (!(childId in institutions)) {
          if (debugPrint) console.log(`***    get child ${childId}`);
          dispatch(getInstitutionChildrenAction({ id: institution.id, timing }));
          break;
        }
      for (const classId of institution.classes)
        if (!(classId in allClasses)) {
          if (debugPrint) console.log(`***    get class ${classId}`);
          dispatch(getInstitutionClassesWithTimesAction({ id: institution.id, timing }));
          break;
        }
    }
    if (debugPrint) console.log(`*** summaryClickhandler for ${institution.id} -> ${state.open ? 'open' : 'closed'}`);
  };

  const checkboxHandler = (): void => {
    const checked = filters.institutions.indexOf(institution.id);
    if (debugPrint) console.log(`**** click checkbox for ${institution.id}: checked ${checked}`);
    if (checked > -1) {
      // checked -> unchecked

      filters.institutions.splice(checked, 1);
    } else {
      // unchecked -> checked
      // Uncheck any ancestors (there will never be more than one)
      for (const id of ancestors) {
        const i = filters.institutions.indexOf(id);
        if (i > -1) filters.institutions.splice(i, 1);
      }
      filters.institutions.push(institution.id);
    }
    setFilters({ ...filters });
  };

  const label = (inst: Institution) => `institution-${inst.id}`;

  return (
    <details open={state.open} onToggle={(e) => detailsHandler(e.target)}>
      <summary>
        <Label onClick={(e) => e.stopPropagation()}>
          <input
            type="checkbox"
            name={displayName(institution)}
            onChange={checkboxHandler}
            checked={filters.institutions.includes(institution.id)}
          />
          {displayName(institution) + (debugPrint ? ' ' + institution.id : '')}
        </Label>
      </summary>
      <IndentChildren>
        {children &&
          children.map((child) => (
            <InstitutionsSelector
              key={label(child)}
              institution={child}
              ancestors={[...ancestors, institution.id]}
              filters={filters}
              setFilters={setFilters}
              debugPrint={debugPrint}
            />
          ))}
        {institution.children.length > 0 && (!children || children.length < institution.children.length) && (
          <Text variant="p" color={gray}>
            Loading {debugPrint ? `(${children?.length} of ${institution.children.length})` : ''}
          </Text>
        )}
      </IndentChildren>
      <ClassesSelector
        classes={classes}
        ancestors={[...ancestors, institution.id]}
        filters={filters}
        setFilters={setFilters}
        show={true}
        debugPrint={debugPrint}
      />
      {institution.classes.length > 0 && (!classes || classes.length < institution.classes.length) && (
        <Text variant="p" color={gray}>
          Loading classes {debugPrint ? `(${classes?.length} of ${institution.classes.length})` : ''}
        </Text>
      )}
    </details>
  );
};

const Label = styled.label({
  ...textStyle('normal'),
});

const IndentChildren = styled.div({
  marginLeft: '1rem',
});
