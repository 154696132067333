import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { black, charcoal15, white, darkgray30 } from '../../utils/colors';
import { TaskDefinition } from '../../../types/routes/module';
import { Button } from '../../components/button';
import { sendSessionMessage, sendSessionMessageClass } from '../../redux/actions/session';
import { MessageModalState, UserWithClassId } from './types';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

interface Props {
  classIds?: number[];
  student: UserWithClassId;
  task?: TaskDefinition;
  setMessageModalState: React.Dispatch<React.SetStateAction<MessageModalState>>;
}

export const MessageModal = ({ classIds, student, task, setMessageModalState }: Props) => {
  const debugPrint = false;
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();

  const doSend = async () => {
    if (message === '') return;
    // Constructing the associated log event requires classId
    if (student) {
      if (debugPrint) console.log(`*** sending message to student ${student.id} class ${student.classId}`);
      dispatch(sendSessionMessage({ classId: student.classId, studentId: student.id, message }));
    } else if (classIds) {
      if (debugPrint) console.log(`*** sending message to classes ${classIds.join(',')}`);
      for (const classId of classIds) {
        dispatch(sendSessionMessageClass({ classId, message }));
      }
    }
    setMessageModalState((prev) => {
      const newState = { ...prev, open: false };
      return newState;
    });
  };

  return (
    <Container>
      <StyledTextField
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Enter your message here ..."
        multiline
        fullWidth
        minRows={3}
      />
      <Buttons>
        <a
          onClick={() => {
            setMessageModalState((prev) => {
              const newState = { ...prev, open: false };
              return newState;
            });
          }}
        >
          <Cancel variant="cancel" data-cy="liveClassroom-messageModal-cancel-message">
            Cancel
          </Cancel>
        </a>
        <StyledButton onClick={doSend} data-cy="liveClassroom-messageModal-send-message">
          Send Message
        </StyledButton>
      </Buttons>
      {task &&
        task.messages.length > 0 &&
        task.messages.map((suggestion, index) => (
          <a
            key={index}
            onClick={() => {
              setMessage(suggestion);
            }}
          >
            <StyledChip label={suggestion}></StyledChip>
          </a>
        ))}
    </Container>
  );
};

const Container = styled.div({
  position: 'relative',
  backgroundColor: white,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'auto',
  a: {
    cursor: 'pointer',
  },
});

const StyledTextField = styled(TextField)({
  marginBottom: '1rem',
});

const Buttons = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

const StyledButton = styled(Button)({
  display: 'flex',
  marginBottom: '1rem',
  marginLeft: '1rem',
});

const Cancel = styled(Button)({
  display: 'flex',
});

const StyledChip = styled(Chip)({
  marginBottom: '.75rem',
  backgroundColor: charcoal15,
  color: black,
  fontWeight: 'normal',
  padding: '.75rem 1rem',
  '&:hover': {
    backgroundColor: `${darkgray30}`,
  },
  width: '100%',
});
