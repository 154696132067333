import {
  PostModuleEventRequest,
  GetEventsResponse,
  GetEventsResponseRecord,
  GetDefinitionsResponse,
  GetDefinitionsResponseRecord,
  GetVersionDefinitionsResponse,
  GetVersionDefinitionsResponseRecord,
  GetLibraryResponse,
  GetLibraryResponseRecord,
} from '../../types/routes/module';
import { makeRequest } from '.';
import { routes } from '../../types/routes';
import { ModuleEvent } from '../../types/models';

export interface MarkMessageRequest {
  readMessages: ModuleEvent[];
  sessionId: string;
  runId: string | null;
}

export const getEvents = async (
  classroomId: number | null,
  classId: number,
  moduleId: string | null,
  after: number | null = null,
  before: number | null = null,
): Promise<GetEventsResponse> => {
  const rge = moduleId
    ? classroomId
      ? routes.getClassroomModuleEvents
      : routes.getClassModuleEvents
    : classroomId
    ? routes.getClassroomEvents
    : routes.getClassEvents;
  let path = rge.path;
  if (moduleId) path = path.replace(':moduleId', moduleId);
  if (classroomId) path = path.replace(':classroomId', classroomId.toString());
  else path = path.replace(':classId', classId.toString());
  const query = new URLSearchParams();
  if (after) query.append('after', after.toString());
  if (before) query.append('before', before.toString());
  if (query.toString()) path += '?' + query;
  const response = await makeRequest(rge.method, path);
  return GetEventsResponseRecord.check({
    events: response.data.events.map(mapEvent),
  });
};

// June 2024:  this is no longer used
export const getLastEvent = async (
  classroomId: number | null,
  classId: number,
  filter: {
    after?: number | null;
    dt?: number | null;
    before: number | null;
  },
): Promise<GetEventsResponse> => {
  const rge = classroomId ? routes.getClassroomEvents : routes.getClassEvents;
  let path = rge.path;
  if (classroomId) path = path.replace(':classroomId', classroomId.toString());
  else path = path.replace(':classId', classId.toString());
  const query = new URLSearchParams();
  query.append('last', '1');
  if (filter.after) query.append('after', filter.after.toString());
  if (filter.dt) query.append('dt', filter.dt.toString());
  if (filter.before) query.append('before', filter.before.toString());
  if (query.toString()) path += '?' + query;
  const response = await makeRequest(rge.method, path);
  return GetEventsResponseRecord.check({
    events: response.data.events.map(mapEvent),
  });
};

/*
 * Use the moduleId, taskId, and runId of the original message.
 * Use the most recent sessionId, so it doesn't screw up sessions
 */
export const markMessagesAsRead = async ({ readMessages, sessionId }: MarkMessageRequest): Promise<void> => {
  await makeRequest(
    routes.postModuleEvent.method,
    routes.postModuleEvent.path,
    readMessages.map(
      ({ id, moduleId, taskId, runId }: ModuleEvent): PostModuleEventRequest => ({
        moduleId,
        taskId,
        action: 'message read',
        properties: { id },
        sessionId,
        runId,
      }),
    ),
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapEvent = (rawEvent: any): ModuleEvent => ({
  ...rawEvent,
  createdAt: new Date(rawEvent.createdAt),
});

export const getDefinitions = async (): Promise<GetDefinitionsResponse> => {
  const route = routes.getDefinitions;
  const response = await makeRequest(route.method, route.path);
  return GetDefinitionsResponseRecord.check(response.data);
};

export const getVersionDefinitions = async (version: string): Promise<GetVersionDefinitionsResponse> => {
  const route = routes.getVersionDefinitions;
  const query = route.path.replace(':versionId', version);
  const response = await makeRequest(route.method, query);
  return GetVersionDefinitionsResponseRecord.check(response.data);
};

export const getLibrary = async (): Promise<GetLibraryResponse> => {
  const route = routes.getLibrary;
  const response = await makeRequest(route.method, route.path);
  return GetLibraryResponseRecord.check(response.data);
};
