import { ClassWithTimes, ClassWithUsers } from '../../types/routes/accountManagement';

type MinimalClassDisplayNameData = { nickName: string; externalName: string; section?: string };
type ClassDisplayNameOptions = { section?: boolean; verbose?: boolean };

// Idea: show section by default only when there is an ambiguity
export const classDisplayName = (c: MinimalClassDisplayNameData, options?: ClassDisplayNameOptions): string => {
  if (c === undefined) {
    return '';
  } else {
    const sectionDisplay = options && options.section && c.section ? ', ' + c.section : '';
    if (options && options.verbose && c.nickName && c.externalName) {
      return c.nickName + ' (' + c.externalName + ')' + sectionDisplay;
    } else {
      return (c.nickName ? c.nickName : c.externalName) + sectionDisplay;
    }
  }
};

export const classSortOrder = <T extends ClassWithTimes | ClassWithUsers>(x: T, y: T): number => {
  const xName = x.nickName ? x.nickName : x.externalName;
  const yName = y.nickName ? y.nickName : y.externalName;
  return xName.toLowerCase().localeCompare(yName.toLowerCase());
};
