// @ts-strict-ignore
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { black, primary, gray } from '../../utils/colors';

import { RowSummary, RowDetails } from '../../components/StyledComponents';
import { Text } from '../../components/text';

import { ClassRow } from './ClassRow';
import {
  getInstitutionChildrenAction,
  getInstitutionClassesWithUsersAction,
} from '../../redux/actions/accountManagement';
import { Institution } from '../../../types/routes/accountManagement';
import { displayName, institutionSortOrder } from '../../utils/institution';
import { classSortOrder } from '../../utils/class';
import { classFilterLogic, Filter } from './RootInstitution';

// This has a lot of code duplication with ManageInstitution.tsx

export const InstitutionRow: React.FC<{ institution: Institution; filter: Filter }> = ({ institution, filter }) => {
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const timing = query.get('timing') ? true : false;
  const dispatch = useDispatch();
  const institutions = useSelector((state) => state.accountManagement.institutions);
  const children = institution.children.map((id) => institutions[id]).filter((x) => x);
  children.sort(institutionSortOrder);

  const allClasses = useSelector((state) => state.accountManagement.classesWithUsers);
  const classes = institution.classes.map((id) => allClasses[id]).filter((x) => x);
  classes.sort(classSortOrder);

  // Use localStorage instead of REACT state mechanism
  const statePath = `admin/classes/institution/${institution.id}`;
  const state = JSON.parse(window.localStorage.getItem(statePath)) || {
    open: false,
  };

  const onRowToggle = (target: EventTarget) => {
    state.open = (target as HTMLDetailsElement).open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
    // Dispatch all children of the institution, if open
    if (state.open)
      for (const childId of institution.children)
        if (!(childId in institutions)) {
          if (debugPrint) console.log(`***    get ${institution.id} children`);
          dispatch(getInstitutionChildrenAction({ id: institution.id, timing }));
          break;
        }
    for (const c of institution.classes)
      if (!(c in classes)) {
        if (debugPrint) console.log(`***    get ${institution.id} classes`);
        dispatch(getInstitutionClassesWithUsersAction({ id: institution.id, timing }));
        break;
      }
  };

  return (
    <RowDetails
      open={state.open}
      onToggle={(e) => {
        e.stopPropagation();
        onRowToggle(e.target);
      }}
      style={{ color: state.open ? primary : black }}
    >
      <RowSummary>
        <strong>{displayName(institution)}</strong>
        {debugPrint && <span> ({institution.id})</span>}:&nbsp;
      </RowSummary>
      {children &&
        children.map((child) => <InstitutionRow institution={child} key={`institution-${child.id}`} filter={filter} />)}
      {institution.children.length > (children ? children.length : 0) && (
        <Text variant="p" color={gray}>
          Getting institution &hellip;
        </Text>
      )}

      <ul>
        {classes
          .filter((c) => classFilterLogic(c, filter))
          .map((theClass) => (
            <ClassRow classItem={theClass} key={`class-${theClass.id}`} />
          ))}
      </ul>
      {institution.classes.length > (classes ? classes.length : 0) && (
        <Text variant="p" color={gray}>
          Getting classes &hellip;
        </Text>
      )}
    </RowDetails>
  );
};
