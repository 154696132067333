import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import { black, white } from '../utils/colors';
import { Text } from './text';
import { Button, ButtonVariant } from './button';
import { map } from 'lodash';

// A call to action item.
export type CTAItem = {
  text: string;
  onClick: (event?: React.MouseEvent) => void;
  variant?: ButtonVariant;
};

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  title: string;
  ctas?: CTAItem[];
  children?: React.ReactNode;
}

export const Modal: FC<Props> = ({ title, ctas, isOpen, closeModal, children }) => {
  useEffect(() => {
    isOpen ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <Container>
      <Overlay onClick={closeModal} data-cy="close-modal-background-overlay" />
      <Content>
        <StyledText variant="lg2" data-cy="modal-header-title">
          {title}
        </StyledText>
        {children}
        {ctas && (
          <Actions>
            {map(ctas, (cta) => (
              <StyledButton key={cta.text} onClick={cta.onClick} variant={cta.variant || 'default'}>
                {cta.text}
              </StyledButton>
            ))}
          </Actions>
        )}
      </Content>
    </Container>
  );
};

const Container = styled.div({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  zIndex: 1000,
});

const Overlay = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: black,
  opacity: '.5',
});

const Content = styled.div({
  position: 'relative',
  minWidth: '30rem',
  maxWidth: '80vw',
  padding: '2rem 2.5rem',
  backgroundColor: white,
  borderRadius: '5px',
  maxHeight: '90vh',
  boxShadow: '0 1rem 4rem 0 rgba(0,0,0,0.3)',
});

const StyledText = styled(Text)({
  textAlign: 'center',
  marginBottom: '1rem',
});

const Actions = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '1rem -1rem 0',
});

const StyledButton = styled(Button)({
  flex: 1,
  margin: '0 1rem',
});
