import React, { useEffect, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled/base';

import { black, primary, white } from '../../../utils/colors';
import FilterIcon from '../../../assets/icons/filter.svg';

import { Text } from '../../../components/text';
import { FilterSelector } from './FilterSelector';
import { ActiveFilters } from './ActiveFilters';
import { Filters } from '../../../api/adminAnalytics';
import { Institution } from '../../../../types/routes/accountManagement';
import { getModuleUsageAction, resetModuleUsage } from '../../../redux/actions/adminAnalytics';
import { Spinner } from '../../../components/Spinner';

/*
 *  Expandable Tree View with lazy loading.
 *  See https://stackoverflow.com/questions/59480773/fetching-async-treeview-data
 */

interface Props {
  filters: Filters;
  setFilters: Dispatch<React.SetStateAction<Filters>>;
  rootInstitutions: Institution[];
}

export const Filter = ({ filters, setFilters, rootInstitutions }: Props) => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.has('debug') ? query.get('debug') : null;
  const dispatch = useDispatch();

  const fetchingRootInstitutions = useSelector((s) => s.accountManagement.fetchingRootInstitutions);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  useEffect(() => {
    const classList = document.body.classList;
    isOpen ? classList.add('no-scroll') : classList.remove('no-scroll');
    if (!isOpen) {
      if (filters.institutions.length + filters.classes.length > 0) dispatch(getModuleUsageAction({ filters, debug }));
      else dispatch(resetModuleUsage());
    }
  }, [isOpen]);

  const filterToggle = () => setIsOpen(!isOpen);
  const closeFilter = () => setIsOpen(false);

  return (
    <FilterContainer>
      <ActiveFilters filters={filters} />
      <FilterButton data-cy="admin-analytics-filter" variant={'p'} color={primary} onClick={filterToggle}>
        <StyledFilterIcon /> Filter
      </FilterButton>
      <div style={{ display: isOpen ? 'block' : 'none' }}>
        <Container>
          <Overlay onClick={closeFilter} />
          <Content>
            {!fetchingRootInstitutions ? (
              <FilterSelector
                filters={filters}
                setFilters={setFilters}
                rootInstitutions={rootInstitutions}
                setIsOpen={setIsOpen}
                debugPrint={debug ? true : false}
              />
            ) : (
              <Spinner center />
            )}
          </Content>
        </Container>
      </div>
    </FilterContainer>
  );
};

export const FilterContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const FilterButton = styled(Text)({
  cursor: 'pointer',
  marginRight: '1rem',
  display: 'flex',
  alignItems: 'center',
});

export const StyledFilterIcon = styled(FilterIcon)({
  heigth: '0.5rem',
});

export const Container = styled('div')({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  zIndex: 100,
});

export const Overlay = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: black,
  opacity: '.5',
});

export const Content = styled('div')({
  position: 'fixed',
  right: '0',
  top: '9.5rem',
  minWidth: '20rem',
  padding: '1rem 1rem',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: primary,
  backgroundColor: white,
  maxHeight: 'calc(100vh - 12rem)',
  overflowY: 'auto',
});
