// @ts-strict-ignore
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getClassContractsAction } from './accountManagement';
import {
  get as apiGet,
  getAll as apiGetAll,
  getExternal as apiGetExternal,
  create as apiCreate,
  importExternal as apiImportExternal,
  updateExternal as apiUpdateExternal,
  edit as apiEdit,
  editNickName as apiEditNickName,
  remove as apiRemove,
  updateLicense as apiUpdateLicense,
  getClassLearnerTypes as apiGetClassLearnerTypes,
  postClassUserLearnerType as apiPostClassUserLearnerType,
  getClassState as apiGetClassState,
  pushOrInsertClassState as apiPushOrInsertClassState,
  getClassStudentUsage as apiGetClassStudentUsage,
  GetClassModuleUsageArgs,
} from '../../api/class';
import {
  GetExternalClassesResponse,
  ImportExternalClassRequest,
  ImportExternalClassResponse,
  UpdateExternalClassResponse,
} from '../../../types/routes/external';
import {
  EditClassResponse,
  Class,
  GetClassesResponse,
  GetLearnerTypesResponse,
  PostClassResponse,
  updateLearnerTypeResponse,
  ClassStateResponse,
  EditClassNickNameRequest,
  EditClassNickNameResponse,
} from '../../../types/routes/class';
import { GetStudentUsageResponse } from '../../../types/routes/module';
import { FirstLearnerType, SecondLearnerType } from '../../../server/services/class';
import { PostClassRequest, EditClassRequest, SetClassState } from '../../../types/routes/class';
import { getDefinitions as getModuleDefinitions, getLibrary as getModuleLibrary } from './module';

export type RejectedPayload = {
  message: string;
  name: string;
  httpCode: number;
};

export type UpdateLicenseArgs = {
  studentId: number;
  classId: number;
  contractId: number;
  licenseStatus: string;
};

export type GetLearnerTypesArgs = {
  classId: number;
};

export type updateLearnerTypeArgs = {
  classId: number;
  userId: number;
  firstLearnerType: FirstLearnerType;
  secondLearnerType: SecondLearnerType;
};

export type GetClassStateArgs = {
  classId: number;
};

export type SetClassStateArgs = SetClassState & {
  classId: number;
};

export const resetGet = createAction('class/reset');
export const resetGetError = createAction('class/reset/error');
export const onLocationChange = createAction('classes/on-location-change');
export const get = createAsyncThunk<
  Class,
  number,
  {
    rejectValue: RejectedPayload;
  }
>('class/get', async (classId: number, { rejectWithValue }) => {
  try {
    return await apiGet(classId);
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error);
  }
});

export const resetGetAll = createAction('classes/reset');
export const resetGetAllError = createAction('classes/reset/error');
export const getAll = createAsyncThunk<
  GetClassesResponse,
  void,
  {
    rejectValue: RejectedPayload;
  }
>('classes/get', async (_external: void, { rejectWithValue }) => {
  try {
    return await apiGetAll();
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetExternalGet = createAction('external/classes/reset');
export const getExternal = createAsyncThunk<
  GetExternalClassesResponse,
  void,
  {
    rejectValue: RejectedPayload;
  }
>('external/classes/get', async (_external: void, { rejectWithValue }) => {
  try {
    const result = await apiGetExternal();
    return result;
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetCreate = createAction('classes/create/reset');
export const create = createAsyncThunk<
  PostClassResponse,
  PostClassRequest,
  {
    rejectValue: RejectedPayload;
  }
>('classes/create', async (ClassToCreate: PostClassRequest, { rejectWithValue }) => {
  try {
    return await apiCreate(ClassToCreate);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetExternalImport = createAction('external/import-class/reset');
export const importExternal = createAsyncThunk<
  ImportExternalClassResponse,
  ImportExternalClassRequest,
  {
    rejectValue: RejectedPayload;
  }
>('external/import-class', async (classData: ImportExternalClassRequest, { rejectWithValue }) => {
  try {
    return await apiImportExternal(classData);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetExternalUpdate = createAction('external/update-class/reset');
export const updateExternal = createAsyncThunk<
  UpdateExternalClassResponse,
  number,
  {
    rejectValue: RejectedPayload;
  }
>('external/update-class', async (classId: number, { rejectWithValue }) => {
  try {
    return await apiUpdateExternal(classId);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetEdit = createAction('classes/edit/reset');
export const edit = createAsyncThunk<
  EditClassResponse,
  EditClassRequest,
  {
    rejectValue: RejectedPayload;
  }
>('classes/edit', async (classToEdit: EditClassRequest, { rejectWithValue }) => {
  try {
    return apiEdit(classToEdit);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});
export const editNickName = createAsyncThunk<
  EditClassNickNameResponse,
  EditClassNickNameRequest,
  {
    rejectValue: RejectedPayload;
  }
>('classes/nickname', async (classToEditNickName: EditClassNickNameRequest, { rejectWithValue }) => {
  try {
    return await apiEditNickName(classToEditNickName);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetRemove = createAction('classes/remove/reset');
export const remove = createAsyncThunk<
  number,
  number,
  {
    rejectValue: RejectedPayload;
  }
>('classes/remove', async (classId: number, { dispatch, rejectWithValue }) => {
  try {
    await apiRemove(classId);
    await dispatch(getAll());
    return classId;
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

/*
 * There are a bunch of other things that need updating
 * if a license is updated:
 *   - The student in state.class.classes
 *   - In state.accountManagement
 *     - The student in classesWithUsers
 *     - The stats in institutionContracts
 *     - The stats in classContracts (which may be soon eliminated)
 *     - The stats in instututionLicenseUsage
 *     - The stats in classLicenseUsage
 * These should be updated after a successfull update
 * to the server.
 */
export const resetUpdateLicense = createAction('classes/update-license/reset');
export const updateLicense = createAsyncThunk<
  UpdateLicenseArgs,
  UpdateLicenseArgs,
  {
    rejectValue: RejectedPayload;
  }
>('class/update-license', async (licenseToUpdate: UpdateLicenseArgs, { dispatch, rejectWithValue }) => {
  try {
    const result = await apiUpdateLicense(licenseToUpdate);
    /*
     * This isn't terribly efficient since we could, in principle,
     * update the class contracts locally.  But it is safer
     * in the event that another user is using up licenses.
     */
    dispatch(getClassContractsAction(licenseToUpdate.classId));
    return result;
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetGetLearnerTypes = createAction('classes/learner-types/reset');
export const getLearnerTypes = createAsyncThunk<
  GetLearnerTypesResponse,
  GetLearnerTypesArgs,
  {
    rejectValue: RejectedPayload;
  }
>('class/learner-types', async (learnerTypesArgs: GetLearnerTypesArgs, { rejectWithValue }) => {
  try {
    if (!learnerTypesArgs.classId) return [];
    const learnerTypes = await apiGetClassLearnerTypes(learnerTypesArgs);
    return learnerTypes;
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetupdateLearnerType = createAction('class-user/learner-type/reset');
export const updateLearnerType = createAsyncThunk<
  updateLearnerTypeResponse,
  updateLearnerTypeArgs,
  {
    rejectValue: RejectedPayload;
  }
>('class-user/learner-type', async (learnerTypeArgs: updateLearnerTypeArgs, { rejectWithValue }) => {
  try {
    return await apiPostClassUserLearnerType(learnerTypeArgs);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const getClassState = createAsyncThunk<
  ClassStateResponse,
  GetClassStateArgs,
  {
    rejectValue: RejectedPayload;
  }
>('class/get-user-states', async (args: GetClassStateArgs, { rejectWithValue }) => {
  try {
    return await apiGetClassState(args);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const pushOrInsertClassState = createAsyncThunk<
  ClassStateResponse,
  SetClassStateArgs,
  {
    rejectValue: RejectedPayload;
  }
>('class/set-user-states', async (args: SetClassStateArgs, { rejectWithValue }) => {
  try {
    return await apiPushOrInsertClassState(args);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});

export const resetGetStudentUsage = createAction('classes/student-usage/reset');
export const getStudentUsage = createAsyncThunk<
  GetStudentUsageResponse,
  number,
  {
    rejectValue: RejectedPayload;
  }
>('classes/student-usage', async (classId: number, { dispatch, rejectWithValue }) => {
  try {
    const moduleRes = await dispatch(getModuleDefinitions());
    await dispatch(getModuleLibrary());
    const moduleIds = Object.keys(moduleRes.payload['modules']);
    const args: GetClassModuleUsageArgs = { classId, moduleIds };
    return await apiGetClassStudentUsage(args);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});
