import React from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { Text } from './text';

interface Props {
  label?: string;
  center?: boolean;
  size?: string;
}

export const Spinner: React.FC<Props> = ({
  label,
  center,
  size,
}: {
  label?: string;
  center?: boolean;
  size?: string;
}) => {
  return (
    <>
      <StyledSpinner center={center}>
        <CircularProgress sx={{ color: 'black' }} size={size ?? '1.25rem'} />
        {label && <Text variant={'p'}>{label}</Text>}
      </StyledSpinner>
    </>
  );
};

const StyledSpinner = styled.div<{ center?: boolean }>(
  {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: '1rem 1rem',
  },
  ({ center }) =>
    center && {
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
);
