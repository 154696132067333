// These utility functions pertain to task numbering for a student given up-to-date student statuses.
import { StudentStatus } from '../types';

export function getFirstTaskFinished(studentStatus: StudentStatus): number {
  const finishedTasks = studentStatus.part?.tasks.filter((t) => studentStatus.finishedTaskSet.has(t.id));
  const earliestFinishedTask = finishedTasks ? finishedTasks[0] : undefined;
  if (earliestFinishedTask) {
    const earliestFinishedTaskNumber = (studentStatus.part?.tasks.indexOf(earliestFinishedTask) ?? 0) + 1;
    return earliestFinishedTaskNumber;
  }
  return -1;
}

export function getLastTaskFinished(studentStatus: StudentStatus): number {
  const finishedTasks = studentStatus.part?.tasks.filter((t) => studentStatus.finishedTaskSet.has(t.id));
  const latestFinishedTask = finishedTasks ? finishedTasks[finishedTasks.length - 1] : undefined;
  if (latestFinishedTask) {
    const latestFinishedTaskNumber = (studentStatus.part?.tasks.indexOf(latestFinishedTask) ?? 0) + 1;
    return latestFinishedTaskNumber;
  }
  return -1;
}
