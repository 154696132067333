import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux';
import { Router } from './router';
import { MathJaxContext } from 'better-react-mathjax';
import { user } from './redux/actions/user';

store.dispatch(user());

export const App = (): React.JSX.Element => {
  // expose store when run in Cypress
  if (window.Cypress) {
    window.__store__ = store;
  }

  return (
    <MathJaxContext>
      <Provider store={store}>
        <Router />
      </Provider>
    </MathJaxContext>
  );
};

declare global {
  interface Window {
    __store__: any;
    Cypress: any;
  }
}
