// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';

import { primary, black } from '../../utils/colors';
import { tallyClass } from '../licenseManagement/util';

import { textStyle } from '../../components/text';
import { ClassWithUsers as Class } from '../../../types/routes/accountManagement';
import { formatName } from '../../utils/user';
import { Link } from 'react-router-dom';
import { classDisplayName } from '../../utils/class';

export const ClassRow: React.FC<{ classItem: Class }> = ({ classItem }) => {
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;
  const totals = tallyClass(classItem);

  let name = classDisplayName(classItem, { section: true });
  if (debug) name += ` (${classItem.id})  (${classItem.createdAt.toLocaleString()}) ${classItem.active ? '1' : '0'}`;

  /*
   * It is tricky to pass a boolean into the dom element.
   * Convert to a number, instead
   * https://stackoverflow.com/questions/49784294
   */
  return (
    <StyledListItem>
      <RowSummary to={`/class/${classItem.id}`} active={classItem.active ? 1 : 0}>
        <>
          {name}:&nbsp; <b>{totals.studentIds.size}</b>
          &nbsp;students;&nbsp; {classItem.teachers.length == 1 ? 'teacher' : 'teachers'}:&nbsp;
          {classItem.teachers.map((teacher) => formatName(teacher)).join(', ')}
        </>
      </RowSummary>
    </StyledListItem>
  );
};

const RowSummary = styled(Link)<{ active: number }>(
  {
    cursor: 'pointer',
    color: black,
  },
  ({ active }) => ({
    textDecoration: active ? 'none' : 'line-through',
    ...textStyle('md'),
    '&:hover': {
      color: primary,
    },
  }),
);

const StyledListItem = styled.li({
  lineHeight: '150%',
  marginTop: '1rem',
});
