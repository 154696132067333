import { Record, Static, Number, String, Boolean } from 'runtypes';
import { Route, route } from './utils';
import { UserAccountStatusRecord } from './user';

export const mailerRoutes = (): { [name: string]: Route } => ({
  alertUserStatusEmail: route({
    method: 'patch',
    path: '/user/alert-user-status-email',
    requestBody: UserStatusEmailRequestRecord,
    responseData: SentEmailResponseRecord,
  }),
  dailyStaffEmail: route({
    method: 'patch',
    path: '/mailer/daily-staff-email',
    requestBody: DailyStaffEmailRequestRecord,
    responseData: SentEmailResponseRecord,
  }),
});

const UserStatusEmailRequestRecord = Record({
  userId: Number,
  status: UserAccountStatusRecord,
  subject: String,
  emailContent: String,
  secret: String,
});
const DailyStaffEmailRequestRecord = Record({
  secret: String,
});

const SentEmailResponseRecord = Record({
  sent: Boolean,
});

export type StandardSentEmailResponse = Static<typeof SentEmailResponseRecord>;
