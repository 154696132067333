import { omit } from 'lodash';
import { routes } from '../../types/routes';
import { ClassroomResponse, ClassroomResponseRecord } from '../../types/routes/classroom';
import { makeRequest } from '.';
import { UpdateClassroomArgs } from '../redux/actions/classroom';

export const update = async (data: UpdateClassroomArgs): Promise<ClassroomResponse> => {
  const response = data.id
    ? await makeRequest(
        routes.editClassroom.method,
        routes.editClassroom.path.replace(':classroomId', data.id.toString()),
        omit(data, ['id']),
      )
    : await makeRequest(routes.createClassroom.method, routes.createClassroom.path, data);
  return ClassroomResponseRecord.check(response.data);
};
