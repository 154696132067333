import { Number, Record, Static, String, Undefined } from 'runtypes';
import { Route } from './utils';

export const sessionRoutes = (): { [name: string]: Route } => ({
  postSessionMessage: {
    method: 'post',
    path: '/session/message',
    requestBody: PostSessionMessageRequestRecord,
    responseData: PostSessionMessageResponseRecord,
  },
  postSessionMessageClass: {
    method: 'post',
    path: '/session/message-class',
    requestBody: PostSessionMessageClassRequestRecord,
    responseData: PostSessionMessageClassResponseRecord,
  },
});

// Constructing the associated log event requires classId
export const PostSessionMessageRequestRecord = Record({
  studentId: Number,
  classId: Number,
  message: String,
});
export const PostSessionMessageResponseRecord = Undefined;
export const PostSessionMessageClassRequestRecord = Record({
  classId: Number,
  message: String,
});
export const PostSessionMessageClassResponseRecord = Undefined;

export type PostSessionMessageRequest = Static<typeof PostSessionMessageRequestRecord>;
export type PostSessionMessageResponse = Static<typeof PostSessionMessageResponseRecord>;
export type PostSessionMessageClassRequest = Static<typeof PostSessionMessageClassRequestRecord>;
export type PostSessionMessageClassResponse = Static<typeof PostSessionMessageClassResponseRecord>;
