// @ts-strict-ignore
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { update as apiUpdate } from '../../api/classroom';
import { ClassroomResponse } from '../../../types/routes/classroom';

export type UpdateClassroomArgs = {
  id: number | null;
  classIds: number[];
};

export type RejectedPayload = {
  message: string;
  name: string;
  httpCode: number;
};

export const resetUpdate = createAction('classroom/update/reset');
export const update = createAsyncThunk<
  ClassroomResponse,
  UpdateClassroomArgs,
  {
    rejectValue: RejectedPayload;
  }
>('classroom/update', async (classToUpdate: UpdateClassroomArgs, { rejectWithValue }) => {
  try {
    return apiUpdate(classToUpdate);
  } catch (error) {
    return rejectWithValue(error.response ? { ...error.response.data, httpCode: error.response.status } : error);
  }
});
