// @ts-strict-ignore
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { charcoal05, gray, primary } from '../../utils/colors';
import { useParams } from 'react-router-dom';

import { AuthOverlay } from '../../components/authOverlay';
import { get as getClass } from '../../redux/actions/class';
import { Text } from '../../components/text';
import { AccessKey } from '../../components/styles';
import { formatName, sortOrder } from '../../utils/user';
import { Student } from '../../../types/routes/class';
import { Button } from '../../components/button';

export const PrintCards: React.FC = () => {
  const debugPrint = false;
  const { classId } = useParams();
  const cardsPerPage = 10;
  const dispatch = useDispatch();
  const classToEdit = useSelector((state) => state.class.classes.find((c) => c.id === parseInt(classId)));
  const fetchingClass = useSelector((state) => state.class.fetchingClass);

  useEffect(() => {
    if (classToEdit) {
      if (debugPrint) console.log(`*** print cards class ${classId} already loaded`);
    } else {
      dispatch(getClass(parseInt(classId)));
    }
  }, []);

  if (!classToEdit) {
    if (fetchingClass) return <AuthOverlay text="Loading class" />;
    else return <Text variant="p">Something went wrong. Please contact support@prismsvr.com</Text>;
  } else {
    const students: Student[] = sortBy<Student>(classToEdit.students, sortOrder);

    const pages: Student[][] = [];
    for (let page = 0; page < students.length / cardsPerPage; page++) {
      const startIndex = page * cardsPerPage;
      pages.push(students.slice(startIndex, startIndex + cardsPerPage));
    }

    return (
      <>
        <div className={noPrint}>
          <CardsTools>
            {students.length <= 0 && <Text variant="lg3">Preparing login card document...</Text>}
            {students.length > 0 && (
              <>
                <Text variant="lg3">Your login cards are ready!</Text>
                <PrintButton
                  onClick={() => {
                    window.print();
                  }}
                >
                  Print
                </PrintButton>
              </>
            )}
          </CardsTools>
        </div>
        {students.length > 0 && (
          <div className={printOnly}>
            {pages.map((pageStudents, index) => (
              <CardsPage key={index}>
                {pageStudents.map((student, index) => (
                  <StudentCard key={index}>
                    <StudentName variant="md">{formatName(student)}</StudentName>
                    <StyledHr></StyledHr>
                    <AccessKeySizeLimit color={gray} variant="lg3">
                      {student.email}
                    </AccessKeySizeLimit>
                    {student.key && (
                      <AccessKeySizeLimit color={primary} variant="lg3">
                        Password: {student.key}
                      </AccessKeySizeLimit>
                    )}
                  </StudentCard>
                ))}
              </CardsPage>
            ))}
          </div>
        )}
      </>
    );
  }
};

const printOnly = css`
  @media screen {
    display: none;
  }
`;

const noPrint = css`
  @media print {
    display: none;
  }
`;

const StyledHr = styled.hr({
  color: charcoal05,
  margin: 0, // avoid extra page overflow
});

const CardsTools = styled.div({
  display: 'flex',
  padding: '10vh',
  justifyContent: 'center',
  alignContent: 'center',
});

const PrintButton = styled(Button)({
  marginLeft: '2rem',
});

const CardsPage = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'left',
  justifyItems: 'start',
  justifyContent: 'left',
  alignContent: 'start',
  width: '99vw',
  height: '99vh',
  padding: 0,
  margin: 0,
  breakAfter: 'always',
});

const StudentCard = styled.div({
  padding: '2rem',
  width: '18rem',
  margin: 0,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const StudentName = styled(Text)({
  marginRight: '2rem',
  display: 'inline',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const AccessKeySizeLimit = styled(AccessKey)({
  width: '18rem',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
