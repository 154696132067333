// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Dictionary, intersection, uniq } from 'lodash';
import styled from '@emotion/styled';

import { UserStateName, ModuleEventWithUserClass } from '../../../types/models';
import { ClassStateResponse, UserState } from '../../../types/routes/class';
import {
  PauseNowRecord,
  PauseNow,
  PauseAtTasksRecord,
  PauseAtTasks,
  ModulePartRecord,
  ModulePart,
} from '../../../types/user-state';

import AddClassroomIcon from '../../assets/icons/classroom.svg';
import AudioOnIcon from '../../assets/icons/audio-on.svg';
import AudioOffIcon from '../../assets/icons/audio-off.svg';
import LanguageIcon from '../../assets/icons/language.svg';
import LaunchIcon from '../../assets/icons/launch.svg';
import PlayIcon from '../../assets/icons/play.svg';
import PauseIcon from '../../assets/icons/pause.svg';
import PauseAtTaskIcon from '../../assets/icons/pause-at-task.svg';
import MessageIcon from '../../assets/icons/message.svg';

import { Text } from '../../components/text';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { Alert } from '../../components/Alert';
import { ShowSetSelectedTask, SetSelectedTask } from '../../components/TaskSelector';
import { darkgray30, primary } from '../../utils/colors';
import { getEpochTimeAfterHoursInSeconds } from '../../utils/time';
import { ModuleFormat } from '../../utils/module';
import { getClassState, pushOrInsertClassState } from '../../redux/actions/class';
import { getDefinitions } from '../../redux/actions/module';

import {
  StudentStatus,
  MessageModalState,
  SettingsModalState,
  ClassroomModalState,
  UserWithClassId,
  ErrorPrints,
  ConfirmModalState,
} from './types';
import { getStudentIdle } from './event-util';
import { Spinner } from '../../components/Spinner';

interface Props {
  classId: number | null;
  students: UserWithClassId[];
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
  selectedStudent?: number;
  setSelectedStudent: (studentId: number) => void;
  setMessageModalState: (state: MessageModalState) => void;
  setSettingsModalState: (state: SettingsModalState) => void;
  setClassroomModalState: (state: ClassroomModalState) => void;
  setTaskSelectorState: (state: ShowSetSelectedTask) => void;
  setConfirmOrchestrationModalState: (state: ConfirmModalState) => void;
  errorList: ErrorPrints;
}

// Time interval that a pause is enforced, in seconds.
export const TIMEOUT = 300;

export const EXPIRATION_AFTER_HOURS = 24;

export const idleness = (status: StudentStatus): number => (!status.started ? 2 : status.idle ? 1 : 0);

const MESSAGE_NOTIF_KEY = 'messageNotif';

export const ClassroomButtons = ({
  classId,
  students,
  eventsByStudent,
  setSelectedStudent,
  setMessageModalState,
  setSettingsModalState,
  setClassroomModalState,
  setConfirmOrchestrationModalState,
  setTaskSelectorState,
  errorList,
}: Props) => {
  const dispatch = useDispatch();

  const moduleDefinitions = useSelector((state) => state.module.definitions);
  const fetchingDefinitions = useSelector((state) => state.module.fetchingDefinitions);
  const moduleVersionDefinitions = useSelector((state) => state.module.versionDefinitions);
  const allClassStates = useSelector((state) => state.class.classState);
  const classStateLoading = useSelector(
    (state) => state.class.fetchingClassState || state.class.postingClassState || state.class.updatingClassroom,
  );
  const classHasError = useSelector((state) => state.class.hasError);
  const classErrorMessage = useSelector((state) => state.class.errorMessage);

  const classIds = uniq(students.map((student) => student.classId));
  const theseClassStates: ClassStateResponse[] = allClassStates.filter((cs) => classIds.includes(cs.classId));

  const [errorPrints, setErrorPrints] = errorList;
  const [audioNotif, setAudioNotif] = useState<boolean>(
    window.localStorage.getItem(MESSAGE_NOTIF_KEY) === 'true' ? true : false,
  );

  // lastEventByStudent is the last event with module & task defined
  // so it won't work for a student not yet in a module.
  // See getStudentIdle(...)
  const classWideFeatureList = students.reduce((f, s) => {
    if (s.id in eventsByStudent) {
      const events = eventsByStudent[s.id];
      if (!getStudentIdle(events, debug ? 0 : null) && events.length > 0) {
        const studentVersion = events[events.length - 1].version;
        if (studentVersion in moduleVersionDefinitions)
          return intersection(f, moduleVersionDefinitions[studentVersion].features);
      }
    }
    return f;
  }, moduleDefinitions.features);

  const allStudentsHaveLaunchIntoModule = classWideFeatureList.includes('launch-into-module');
  const allStudentsHavePauseAtTasks = classWideFeatureList.includes('pause-at-task');
  const allStudentsHavePauseNow = classWideFeatureList.includes('pause-now');

  useEffect(() => {
    if (!fetchingDefinitions && Object.keys(moduleDefinitions.modules).length === 0) dispatch(getDefinitions());
  }, []);

  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  const debug = query.get('debug');
  const _timeout: number = debug ? 15 : TIMEOUT;

  const clickAudioNotifHandler = (value) => {
    setAudioNotif(value);
    window.localStorage.setItem(MESSAGE_NOTIF_KEY, value ? 'true' : 'false');
  };

  useEffect(() => {
    const studentId = parseInt(query.get('student'));
    if (studentId && students.some((student) => student.id == studentId)) {
      setSelectedStudent(studentId);
    }
    // Force a state update on page load
    for (const classId of classIds) dispatch(getClassState({ classId }));
  }, []);

  const [pauseNow, setPauseNow] = useState<boolean>(false);
  const [pauseTimer, setPauseTimer] = useState<number | null>(null);
  const [autoLaunch, setAutoLaunch] = useState<ModulePart | undefined>(undefined);
  const [pauseAtTasks, setPauseAtTasks] = useState<PauseAtTasks | undefined>(undefined);
  const [settingsButton, setSettingsButton] = useState<boolean>(false);

  // Evaulate class states, setting pause, pauseTasks, & autoLaunch
  // BvdS:  need to extend to classrooms
  useEffect(() => {
    let _pauseNow: PauseNow = undefined;
    let _pauseAtTasks: PauseAtTasks | null = null;
    let _autoLaunch: ModulePart | null = null;
    let _settingsButton = false;
    const now = Date.now() / 1000;
    for (const thisClassState of theseClassStates)
      for (const us of thisClassState.userStates) {
        // Filter out student-specific overrides
        if (us.userId !== null) continue;
        try {
          if (us.name == UserStateName.pauseNow) {
            _pauseNow = PauseNowRecord.check(us.value);
          } else if (us.name == UserStateName.pauseAtTasks) {
            const possiblePauseAtTasks = PauseAtTasksRecord.check(us.value);
            if (possiblePauseAtTasks.expiresAt && possiblePauseAtTasks.expiresAt < now) _pauseAtTasks = null;
            else _pauseAtTasks = possiblePauseAtTasks;
          } else if (us.name == UserStateName.launchIntoModule) {
            const possibleAutoLaunch = ModulePartRecord.check(us.value);
            if (possibleAutoLaunch.expiresAt && possibleAutoLaunch.expiresAt < now) _autoLaunch = null;
            else _autoLaunch = possibleAutoLaunch;
          } else if (us.name == UserStateName.language || us.name == UserStateName.accessibility) {
            _settingsButton = true;
          }
        } catch (error) {
          const ref = us.name;
          if (!(ref in errorPrints)) {
            const message = `${ref} invalid form ${JSON.stringify(us.value)}`;
            setErrorPrints({ ...errorPrints, [ref]: message });
          }
        }
      }
    if (pauseTimer !== null) window.clearTimeout(pauseTimer);
    if (_pauseNow) {
      // all instances are pause == true
      const isPause: boolean = now > _pauseNow.timestamp && now < _pauseNow.timestamp + _pauseNow.timeout;
      const timeout = _pauseNow.timeout + _pauseNow.timestamp - now;
      setPauseNow(isPause);
      if (isPause) {
        // Expecting milliseconds
        setPauseTimer(window.setTimeout(() => setPauseNow(false), timeout * 1000));
      } else setPauseTimer(null);
    } else {
      setPauseNow(false);
      setPauseTimer(null);
    }
    setPauseAtTasks(_pauseAtTasks);
    setAutoLaunch(_autoLaunch);
    setSettingsButton(_settingsButton);
  }, [allClassStates]);

  useEffect(() => {
    if (classHasError) {
      const uniqueErrorRef: string = 'class-has-error-' + self.crypto.randomUUID();
      setErrorPrints({ ...errorPrints, [uniqueErrorRef]: classErrorMessage });
    } else {
      // set to blank this will be reset within about 5 seconds by the next thisClassState update.
      setErrorPrints({});
    }
  }, [classHasError]);

  const playPauseClicked = () => {
    if (!classStateLoading) {
      for (const thisClassState of theseClassStates) {
        const classId = thisClassState.classId;
        /*
         * pauseNow should behave in an event-like manner.
         * Thus any change to the class-wise value should unset
         * any student-specific overrides.
         */
        const data: UserState[] = [
          {
            userId: null, // class-wise value
            name: UserStateName.pauseNow,
            value: pauseNow ? undefined : { timestamp: Math.floor(Date.now() / 1000), timeout: _timeout },
          },
        ];
        dispatch(
          pushOrInsertClassState({
            classId,
            push: true,
            undoOverrides: true,
            data,
          }),
        );
      }
    }
  };

  const pauseAtTaskClicked: SetSelectedTask = (task) => {
    debug && console.log('pauseAtTaskClicked');
    if (!classStateLoading) {
      for (const thisClassState of theseClassStates) {
        const classId = thisClassState.classId;
        /*
         * pauseAtTask should unset student-specific overrides
         * if the classwise value is unset.
         */
        const data: UserState[] = [
          {
            userId: null, // class-wise value
            name: UserStateName.pauseAtTasks,
            value: task
              ? {
                  tasks: [task],
                  timeout: _timeout,
                  expiresAt: getEpochTimeAfterHoursInSeconds(EXPIRATION_AFTER_HOURS),
                }
              : undefined,
          },
        ];
        debug && console.log(data);
        dispatch(
          pushOrInsertClassState({
            classId,
            push: true,
            undoOverrides: true,
            data,
          }),
        );
      }
      setTaskSelectorState(null);
    }
  };

  const launchIntoTaskClicked: SetSelectedTask = (task) => {
    debug && console.log('launchIntoTaskClicked');
    if (!classStateLoading) {
      for (const thisClassState of theseClassStates) {
        const classId = thisClassState.classId;
        // TODO: improve for classroom multi-class launching
        debug && console.log(task);
        /*
         * launchIntoModule should unset student-specific overrides
         * if the classwise value is unset or if the student override is none.
         */
        const data: UserState[] = [
          {
            userId: null, // class-wise value
            name: UserStateName.launchIntoModule,
            value: task
              ? {
                  ...task,
                  expiresAt: getEpochTimeAfterHoursInSeconds(EXPIRATION_AFTER_HOURS),
                }
              : undefined,
          },
        ];
        debug && console.log(data);
        dispatch(
          pushOrInsertClassState({
            classId,
            push: true,
            undoOverrides: true,
            data,
          }),
        );
      }
      setTaskSelectorState(null);
    }
  };

  const moduleFormat = new ModuleFormat(moduleDefinitions.modules);

  // update tooltip text
  const getLaunchIntoModuleToolTipText = (): string => {
    if (allStudentsHaveLaunchIntoModule) {
      return autoLaunch
        ? 'Auto Launch at ' +
            (autoLaunch.taskId
              ? moduleFormat.taskName(autoLaunch.moduleId, autoLaunch.taskId)
              : moduleFormat.partName(autoLaunch.moduleId, autoLaunch.part))
        : 'Enable auto-launch';
    }
    return `Some students are using a version of the App that does not support Launch into Module.`;
  };

  const getPauseAtTasksToolTipText = (): string => {
    if (allStudentsHavePauseAtTasks) {
      return pauseAtTasks && pauseAtTasks.tasks.length > 0
        ? 'End at ' + moduleFormat.taskName(pauseAtTasks.tasks[0].moduleId, pauseAtTasks.tasks[0].taskId)
        : 'Enable end-at-task';
    }
    return `Some students are using a version of the App that does not support End at Task.`;
  };

  const getPauseNowToolTipText = (): string => {
    if (allStudentsHavePauseNow) {
      return (pauseNow ? 'Resume' : 'Pause') + ' ' + (classIds.length > 1 ? 'classes' : 'class');
    }
    return `Some students are using a version of the App that does not support Pause Class.`;
  };

  return (
    <Container>
      {Object.keys(errorPrints).length > 0 &&
        Object.entries(errorPrints).map(([key, message]) => (
          <Alert
            key={key}
            severity="warning"
            title={key.substring(0, 16) == 'class-has-error-' ? 'Error sending request' : 'Content error'}
          >
            {message}
          </Alert>
        ))}
      <HeaderRow>
        <ConfigHeader>
          <ActionButton
            id="classroom"
            onClick={() => {
              setClassroomModalState({ open: true, classId });
            }}
          >
            <AddClassroomIcon />
          </ActionButton>
          <Tooltip anchorSelect="#classroom">
            <Text variant="nav">View multiple classes</Text>
          </Tooltip>
          <ActionButton
            id="audio-notif"
            onClick={() => {
              clickAudioNotifHandler(!audioNotif);
            }}
          >
            {!audioNotif ? (
              <AudioOffIcon />
            ) : (
              <IconPrimary state={true}>
                <AudioOnIcon />
              </IconPrimary>
            )}
          </ActionButton>
          <Tooltip anchorSelect="#audio-notif">
            <Text variant="nav">Audio notifications {audioNotif ? 'enabled' : 'disabled'}</Text>
          </Tooltip>
          {
            <>
              <ActionButton
                data-cy="liveClassroom-set-language-class"
                id="set-language-class"
                disabled={classStateLoading}
                style={{
                  width: '1.5rem',
                }}
                onClick={() => {
                  setSettingsModalState({ open: true, classIds, student: null });
                }}
              >
                <IconPrimary state={settingsButton && !classStateLoading}>
                  <LanguageIcon />
                </IconPrimary>
              </ActionButton>
              <Tooltip anchorSelect="#set-language-class">
                <Text variant="nav">Set Language</Text>
              </Tooltip>

              <ConfigDropdown
                id="auto-launch-class"
                disabled={classStateLoading}
                onClick={() => {
                  setTaskSelectorState({
                    showTasks: true,
                    setSelectedTask: launchIntoTaskClicked,
                    selectedTask: autoLaunch,
                    taskFilter: (t) => ('snapshot' in t ? t.snapshot : true),
                    firstTaskIsPart: true,
                    setConfirmModalState: !allStudentsHaveLaunchIntoModule && setConfirmOrchestrationModalState,
                  });
                }}
                data-cy="liveClassroom-auto-launch-dropdown-class"
              >
                <ConfigDropdownIcon disabled={!allStudentsHaveLaunchIntoModule || classStateLoading}>
                  <IconPrimary state={!!autoLaunch && !classStateLoading}>
                    <LaunchIcon />
                  </IconPrimary>
                </ConfigDropdownIcon>
                <ConfigDropdownText variant="md">
                  {autoLaunch ? moduleFormat.name(autoLaunch.moduleId) : 'Auto Launch'}
                </ConfigDropdownText>
              </ConfigDropdown>
              <Tooltip anchorSelect="#auto-launch-class">
                <Text variant="nav">{getLaunchIntoModuleToolTipText()}</Text>
              </Tooltip>

              <ConfigDropdown
                id="pause-at-task-class"
                disabled={classStateLoading}
                onClick={() =>
                  setTaskSelectorState({
                    showTasks: true,
                    setSelectedTask: pauseAtTaskClicked,
                    selectedTask: pauseAtTasks && pauseAtTasks.tasks.length > 0 ? pauseAtTasks.tasks[0] : null,
                    firstTaskIsPart: false,
                    setConfirmModalState: !allStudentsHavePauseAtTasks && setConfirmOrchestrationModalState,
                  })
                }
                data-cy="liveClassroom-pause-at-task-dropdown-class"
              >
                <ConfigDropdownIcon disabled={!allStudentsHavePauseAtTasks || classStateLoading}>
                  <IconPrimary state={!!pauseAtTasks && pauseAtTasks.tasks.length > 0 && !classStateLoading}>
                    <PauseAtTaskIcon />
                  </IconPrimary>
                </ConfigDropdownIcon>
                <ConfigDropdownText variant="md">
                  {pauseAtTasks && pauseAtTasks.tasks.length > 0
                    ? moduleFormat.name(pauseAtTasks.tasks[0].moduleId)
                    : 'End At Task'}
                </ConfigDropdownText>

                <Tooltip anchorSelect="#pause-at-task-class">
                  <Text variant="nav">{getPauseAtTasksToolTipText()}</Text>
                </Tooltip>
              </ConfigDropdown>
            </>
          }
        </ConfigHeader>
        <ActionHeader>
          {classStateLoading && (
            <>
              <ConfigSpinner id="loading-class-icon">
                <Spinner />
              </ConfigSpinner>
              <Tooltip anchorSelect="#loading-class-icon">
                <Text variant="nav">Class information loading...</Text>
              </Tooltip>
            </>
          )}
          {
            <>
              <ActionButton
                id="play-pause-class"
                disabled={classStateLoading}
                onClick={() =>
                  allStudentsHavePauseNow
                    ? playPauseClicked()
                    : setConfirmOrchestrationModalState({
                        open: true,
                        onConfirm: playPauseClicked,
                      })
                }
                data-cy="liveClassroom-play-pause-button-class"
              >
                {pauseNow ? (
                  <IconPrimary state={!classStateLoading}>
                    <PlayIcon />
                  </IconPrimary>
                ) : (
                  <PauseIcon />
                )}
              </ActionButton>
              <Tooltip anchorSelect="#play-pause-class">
                <Text variant="nav">{getPauseNowToolTipText()}</Text>
              </Tooltip>
            </>
          }

          <ActionButton
            data-cy="liveClassroom-message-students"
            id="message-all-students"
            onClick={() => {
              setMessageModalState({ open: true, classIds });
            }}
          >
            <MessageIcon />
          </ActionButton>
          <Tooltip anchorSelect="#message-all-students">
            <Text variant="nav">Message students</Text>
          </Tooltip>
        </ActionHeader>
      </HeaderRow>
      {debug && (
        <>
          <Text variant="nav">Timeout {_timeout}s</Text>
          <Text variant="nav">class state (no scores):</Text>
          <ul>
            {theseClassStates.map((tcs) => (
              <li key={tcs.classId}>
                <Text variant="nav">Class {tcs.classId}</Text>
                <ul>
                  {tcs.userStates
                    .filter((s) => s.name != 'score')
                    .map((s, index) => (
                      <li key={'state:' + index}>
                        <Text variant="nav">{JSON.stringify(s)}</Text>
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        </>
      )}
    </Container>
  );
};

const Container = styled.div({
  borderTop: '1px solid #4d4d4d',
  borderBottom: '1px solid #4d4d4d',
});

const HeaderRow = styled.div({
  display: 'flex',
  padding: '1rem',
});

export const ConfigHeader = styled.div({
  margin: 'auto 0.4rem',
  padding: '0',
  display: 'flex',
  justifyContent: 'flex-start',
  flexGrow: 1,
});

export const ActionHeader = styled.div({
  margin: 'auto 0 auto 0',
  padding: '0',
  display: 'flex',
  justifyContent: 'flex-end',
});

export const IconPrimary = styled.div<{ state: boolean }>({}, ({ state }) =>
  state
    ? {
        color: primary,
      }
    : {},
);

export const ConfigDropdown = styled.button<{ disabled?: boolean; fullWidth?: boolean }>(
  {
    display: 'flex',
    border: '1px solid #4d4d4d',
    borderRadius: '0.5rem',
    minWidth: '8rem',
    cursor: 'pointer',
    color: '#4d4d4d',
    background: 'none',
    marginRight: '0.5rem',
  },
  ({ disabled, fullWidth }) => {
    let dropdownStateStyles = {};
    if (disabled) {
      dropdownStateStyles = { ...dropdownStateStyles, cursor: 'default', borderColor: darkgray30, color: darkgray30 };
    }
    if (fullWidth) {
      dropdownStateStyles = { ...dropdownStateStyles, width: '100%' };
    }
    return dropdownStateStyles;
  },
);

export const ConfigDropdownIcon = styled.div<{ disabled?: boolean }>(
  {
    borderRight: '1px solid #4d4d4d',
    width: '1.5rem',
    padding: '0.2rem 0.7rem 0 0.5rem',
  },
  ({ disabled }) =>
    disabled
      ? {
          borderColor: darkgray30,
        }
      : {},
);

export const ConfigDropdownText = styled(Text)({
  padding: '.2rem 1rem .2rem 1rem',
});

export const ConfigButton = styled.div({
  width: '1.5rem',
  cursor: 'pointer',
  color: '#4d4d4d',
  margin: 'auto 1rem',
  img: {
    width: '1.5rem',
    height: '1.5rem',
  },
});

export const ConfigSpinner = styled.div({
  width: '1.5rem',
  color: '#4d4d4d',
  margin: 'auto 1rem',
  img: {
    width: '1.5rem',
    height: '1.5rem',
  },
});

export const ActionButton = styled.button<{ disabled?: boolean }>(
  {
    alignSelf: 'end',
    width: '1.5rem',
    cursor: 'pointer',
    margin: 'auto 1rem',
    color: '#4d4d4d',
    img: {
      width: '1.5rem',
      height: '1.5rem',
    },
    background: 'none',
    border: 'none',
    padding: 0,
  },
  ({ disabled }) =>
    disabled
      ? {
          cursor: 'default',
          color: darkgray30,
          borderColor: darkgray30,
        }
      : {},
);
