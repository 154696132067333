import { createReducer } from '@reduxjs/toolkit';
import { getLtiPlatforms, registerLtiPlatform, updateLtiPlatform } from '../actions/admin';
import { PlatformParameters } from '../../../types/ltijs.d';

export interface State {
  fetchingLtiPlatforms: boolean;
  updatingLtiPlatform: boolean;
  ltiPlatforms: PlatformParameters[];
}

const initialState: State = {
  fetchingLtiPlatforms: false,
  updatingLtiPlatform: false,
  ltiPlatforms: [],
};

export default createReducer<State>(initialState, (builder) =>
  builder
    .addCase(getLtiPlatforms.pending, (state) => {
      state.fetchingLtiPlatforms = true;
      state.ltiPlatforms = [];
    })
    .addCase(getLtiPlatforms.fulfilled, (state, action) => {
      state.ltiPlatforms = action.payload;
      state.fetchingLtiPlatforms = false;
    })
    .addCase(registerLtiPlatform.pending, (state) => {
      state.fetchingLtiPlatforms = true;
    })
    .addCase(registerLtiPlatform.fulfilled, (state, action) => {
      state.fetchingLtiPlatforms = false;
      const p: PlatformParameters[] = [];
      let update = true;
      for (const platform of state.ltiPlatforms) {
        if (action.payload.url == platform.url && action.payload.clientId == platform.clientId) {
          p.push(action.payload);
          update = false;
        } else {
          p.push(platform);
        }
      }
      if (update) p.push(action.payload);
      state.ltiPlatforms = p;
    })
    .addCase(updateLtiPlatform.pending, (state) => {
      state.updatingLtiPlatform = true;
    })
    .addCase(updateLtiPlatform.fulfilled, (state, action) => {
      for (const platform of state.ltiPlatforms) {
        if (action.payload.id == platform.id) {
          platform.active = action.payload.active;
        }
      }
      state.updatingLtiPlatform = false;
    }),
);
