import { makeRequest } from '.';
import { routes } from '../../types/routes';
import {
  PostSessionMessageRequest,
  PostSessionMessageResponse,
  PostSessionMessageResponseRecord,
  PostSessionMessageClassRequest,
  PostSessionMessageClassResponse,
  PostSessionMessageClassResponseRecord,
} from '../../types/routes/session';

export const sendSessionMessage = async (data: PostSessionMessageRequest): Promise<PostSessionMessageResponse> => {
  const response = await makeRequest(routes.postSessionMessage.method, routes.postSessionMessage.path, data);
  return PostSessionMessageResponseRecord.check(response.data);
};

export const sendSessionMessageClass = async (
  data: PostSessionMessageClassRequest,
): Promise<PostSessionMessageClassResponse> => {
  const response = await makeRequest(routes.postSessionMessageClass.method, routes.postSessionMessageClass.path, data);
  return PostSessionMessageClassResponseRecord.check(response.data);
};
