// @ts-strict-ignore
import { makeRequest } from '.';
import { accountManagementRoutes as routes, Contract } from '../../types/routes/accountManagement';

export const apiGetRootInstitutions = async () => {
  // await new Promise(resolve => setTimeout(resolve, 2000));
  const response = await makeRequest(routes.getRootInstitutions.method, routes.getRootInstitutions.path);
  return routes.getRootInstitutions.responseData.check(response.data);
};

export const apiGetInstitutionChildren = async (institutionId: number) => {
  // await new Promise(resolve => setTimeout(resolve, 2000));
  const route = routes.getInstitutionChildren;
  const response = await makeRequest(route.method, route.path.replace(':institutionId', institutionId.toString()));
  return route.responseData.check(response.data);
};

export const apiGetInstitution = async (institutionId: number) => {
  const route = routes.getInstitution;
  const response = await makeRequest(route.method, route.path.replace(':institutionId', institutionId.toString()));
  return route.responseData.check(response.data);
};

export const toTimestamp = (x: string | null) => (x ? new Date(x) : null);

export const apiGetInstitutionClassesWithTimes = async (institutionId: number) => {
  // await new Promise(resolve => setTimeout(resolve, 2000));
  const route = routes.getInstitutionClassesWithTimes;
  const response = await makeRequest(route.method, route.path.replace(':institutionId', institutionId.toString()));
  // Convert timestamps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const classes = response.data.classes.map((c: any) => ({
    ...c,
    startAt: toTimestamp(c.startAt),
    endAt: toTimestamp(c.endAt),
    createdAt: toTimestamp(c.createdAt),
  }));
  return route.responseData.check({ classes, timing: response.data.timing });
};

export const apiGetClassWithTimes = async (classId: number) => {
  const route = routes.getClassWithTimes;
  const response = await makeRequest(route.method, route.path.replace(':classId', classId.toString()));
  // Convert timestamps
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const c = response.data;
  const theClass = {
    ...c,
    startAt: toTimestamp(c.startAt),
    endAt: toTimestamp(c.endAt),
    createdAt: toTimestamp(c.createdAt),
  };
  return route.responseData.check(theClass);
};

export const apiGetInstitutionClassesWithUsers = async (institutionId: number) => {
  // await new Promise(resolve => setTimeout(resolve, 2000));
  const route = routes.getInstitutionClassesWithUsers;
  const response = await makeRequest(route.method, route.path.replace(':institutionId', institutionId.toString()));
  const classes = response.data.classes.map((c: any) => ({
    ...c,
    createdAt: toTimestamp(c.createdAt),
  }));
  return route.responseData.check({ ...response.data, classes });
};

export const apiGetInstitutionContracts = async (institutionId: number) => {
  const route = routes.getInstitutionContracts;
  const response = await makeRequest(route.method, route.path.replace(':institutionId', institutionId.toString()));
  const contracts = response.data.contracts.map((c) => ({
    ...c,
    contractStartDate: toTimestamp(c.contractStartDate),
    contractEndDate: toTimestamp(c.contractEndDate),
  }));
  return route.responseData.check({ ...response.data, contracts });
};

export const apiGetInstitutionLicenseUsage = async (institutionId: number, filter: Contract[]) => {
  const route = routes.getInstitutionLicenseUsage;
  const query = new URLSearchParams();
  if (filter.length > 0) query.append('contracts', filter.map((c) => c.id).join(','));

  const response = await makeRequest(
    route.method,
    route.path.replace(':institutionId', institutionId.toString()) + '?' + query,
  );
  return route.responseData.check(response.data);
};

export const apiRemoveClass = async (classId: number) => {
  const route = routes.deleteClass;
  const response = await makeRequest(route.method, route.path.replace(':classId', classId.toString()));
  return route.responseData.check(response.data);
};

export const apiRemoveStudent = async (studentId: number) => {
  const route = routes.deleteUser;
  const response = await makeRequest(route.method, route.path.replace(':studentId', studentId.toString()));
  return route.responseData.check(response.data);
};
