// @ts-strict-ignore
import React from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from './text';
import { black, gray, primary, white } from '../utils/colors';
import { Link } from 'react-router-dom';
import { formatInitials, formatName } from '../utils/user';
import { InstitutionUserRole, UserRole, UserAccountStatus } from '../../types/models';
import { signout as signoutAction } from '../redux/actions/user';
import Logout from '../assets/icons/logout.svg';

export const Header: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const signout = () => dispatch(signoutAction());
  // UserRole.teacher is deprecated in favor of InstitutionUserRole.teacher
  const isEducator =
    user.roles.includes(UserRole.teacher) ||
    user.institutionRoles.some(
      (ir) => ir.roles.includes(InstitutionUserRole.institutionAdmin) || ir.roles.includes(InstitutionUserRole.teacher),
    );
  const accountSettings = false;
  return (
    <Container>
      <ProfileLink to={accountSettings ? '/account-settings' : '/'}>
        <UserInfo>
          <Name variant="nav" color={black}>
            {formatName(user)}
          </Name>
          <Text variant="p" color={gray}>
            {user.status == UserAccountStatus.pending ? 'Pending' : isEducator ? 'Educator' : 'Student'}
          </Text>
        </UserInfo>
        <ProfilePicture>
          <Text variant="md" color={white}>
            {formatInitials(user)}
          </Text>
        </ProfilePicture>
      </ProfileLink>
      <IconContainer data-cy="header-logout-button" onClick={signout}>
        <div style={{ color: primary }}>
          <Logout />
        </div>
        <Text variant="sm">Log out</Text>
      </IconContainer>
    </Container>
  );
};

const IconContainer = styled.div({
  marginLeft: '1rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: black,
  '&:hover': {
    color: primary,
  },
});

const Container = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '1rem 2rem',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.06)',
});

const ProfileLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const UserInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});

const Name = styled(Text)({
  marginBottom: '.2rem',
  '&:hover': {
    color: primary,
  },
});

const ProfilePicture = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: primary,
  width: '3rem',
  height: '3rem',
  borderRadius: '3rem',
  marginLeft: '1rem',
});
