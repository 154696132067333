// @ts-strict-ignore
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { includes } from 'lodash';
import { RouteMethod } from '../../types/routes/utils';

interface RequestConfig extends AxiosRequestConfig {
  retries?: number;
}

let apiAddress = '';

export const setApiAddress = (address: string): void => {
  apiAddress = address;
};

export const makeRequest = async (
  method: RouteMethod,
  path: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  data?: any,
  config?: RequestConfig,
): Promise<AxiosResponse> => {
  const finalConfig: RequestConfig = {
    ...config,
    withCredentials: true,
    retries: config?.retries === undefined ? 3 : config?.retries,
  };
  try {
    const fullPath = `${apiAddress}/api${path}`;
    let response: AxiosResponse;
    if (method === 'get') response = await axios.get(fullPath, finalConfig);
    else if (method === 'post') response = await axios.post(fullPath, data, finalConfig);
    else if (method === 'put') response = await axios.put(fullPath, data, finalConfig);
    else if (method === 'patch') response = await axios.patch(fullPath, data, finalConfig);
    else if (method === 'delete') response = await axios.delete(fullPath, finalConfig);
    if (response) return response;
    throw new Error(`unknown method ${method}`);
  } catch (error) {
    if (includes(error.message, 'Network Error') && finalConfig.retries > 1)
      return makeRequest(method, path, data, {
        ...finalConfig,
        retries: finalConfig.retries - 1,
      });
    throw error;
  }
};
