// @ts-strict-ignore
import {
  Runtype,
  Record,
  Partial,
  Literal,
  Union,
  String,
  Number as NumberType,
  Array as ArrayType,
  Static,
} from 'runtypes';

const union = <T extends Runtype>(xx: T[]) =>
  xx.slice(1).reduce<Runtype>((result, x) => Union(result, x), Union(xx[0]));

export const allLanguages = [
  { key: 'en', name: 'English' },
  { key: 'es', name: 'Spanish' },
  { key: 'zh', name: 'Chinese (Simplified)' },
  { key: 'ht', name: 'Haitian Creole' },
  { key: 'pt-BR', name: 'Portuguese (Brazil)' },
  { key: 'ru', name: 'Russian' },
  { key: 'vi', name: 'Vietnamese' },
];
export const offLanguages = [{ key: 'OFF', name: 'Off' }, ...allLanguages];
const languageLiterals = allLanguages.map((z) => Literal(z.key));
const offLanguageLiterals = offLanguages.map((z) => Literal(z.key));
const LanguageRecord = Record({
  spoken: union(languageLiterals),
  subtitles: union(offLanguageLiterals),
  text: union(languageLiterals),
});
type Language = Static<typeof LanguageRecord>;
const OldLanguageRecord = Record({
  spoken: Union(Literal('EN'), Literal('ES')),
  subtitles: Union(Literal('EN'), Literal('ES'), Literal('OFF')),
});
type OldLanguage = Static<typeof OldLanguageRecord>;
export const AnyLanguageFormatRecord = Record({ spoken: String, subtitles: String }).And(Partial({ text: String }));

// Update pre-June 2024 language fromat
export const updateLanguageFormat = (x: { [name: string]: string }): Language => {
  for (const key of Object.keys(x)) {
    if (x[key] == 'EN' || x[key] == 'ES') x[key] = x[key].toLowerCase();
  }
  // Borrow the setting for spoken language
  if (!('text' in x)) x['text'] = x['spoken'];
  return LanguageRecord.check(x);
};
// Back-port language format to pre-June 2024 version
// Can only have EN, ES, and OFF for subtitles.
// Fall back to english for other languages.
export const oldLanguageFormat = (x: { [name: string]: string }): OldLanguage => {
  for (const key of Object.keys(x)) {
    if (x[key] == x[key].toUpperCase()) {
      // do nothing
    } else if (x[key] == 'es' || x[key] == 'en') {
      x[key] = x[key].toUpperCase();
    } else x[key] = 'EN';
  }
  // Remove text field language
  if ('text' in x) delete x['text'];
  return OldLanguageRecord.check(x);
};

export const subtitleFontSizes = ['Small', 'Medium', 'Large'];
const FontSizeUnion = union(subtitleFontSizes.map((x) => Literal(x)));
export const AccessibilityRecord = Record({ font_size: FontSizeUnion });
export type Accessibility = Static<typeof AccessibilityRecord>;

export const PauseNowRecord = Record({ timestamp: NumberType, timeout: NumberType });
export type PauseNow = Static<typeof PauseNowRecord>;

// See https://developer.picoxr.com/reference/unity/latest/PXR_Enterprise/#PICOCastSetOption
export const ScreencastRecord = Record({
  options: Partial({ OPTION_RESOLUTION_LEVEL: String, OPTION_BITRATE_LEVEL: String, OPTION_AUDIO_ENABLE: String }),
  expiresAt: NumberType,
  requestId: NumberType,
});
export type Screencast = Static<typeof ScreencastRecord>;

export const PauseAtTasksRecord = Record({
  tasks: ArrayType(Record({ moduleId: String, taskId: String })),
  timeout: NumberType,
}).And(Partial({ expiresAt: NumberType }));
export type PauseAtTasks = Static<typeof PauseAtTasksRecord>;

export const ModulePartRecord = Record({ moduleId: String }).And(
  Partial({ part: NumberType, taskId: String, expiresAt: NumberType }),
);
export type ModulePart = Static<typeof ModulePartRecord>;
