// These are copied from Core file ScriptableObjects/Runtime/Scripts/SBuildSettings.cs
// see https://www.notion.so/prismsvr/Platform-Features-fbbde9c2f3604ba587053b18216abd58#fca08d31a3cb4eb583fb74ed289c9d66

export enum HardwareTypes {
  Pico = 'Pico',
  Quest = 'Quest',
  Unknown = 'Unknown',
}

enum ModelTypes {
  Unknown = 'Unknown',
  QuestOne = 'QuestOne',
  NeoTwo = 'NeoTwo',
  QuestTwo = 'QuestTwo',
  NeoThree = 'NeoThree',
  NeoFour = 'NeoFour',
  Sparrow = 'Sparrow',
  QuestPro = 'QuestPro',
  QuestThree = 'QuestThree',
}

export const modelTypeName = {
  Unknown: 'unknown',
  QuestOne: 'Quest 1',
  NeoTwo: 'Pico Neo 2',
  QuestTwo: 'Quest 2',
  NeoThree: 'Pico Neo 3',
  NeoFour: 'Pico Neo 4',
  Sparrow: 'Sparrow',
  QuestPro: 'QuestPro',
  QuestThree: 'QuestThree',
};

// See https://developer.picoxr.com/reference/unity/latest/PXR_Enterprise/#PICOCastInit
export const ScreencastModelTypes = new Set<string>([ModelTypes.NeoThree, ModelTypes.NeoFour, ModelTypes.Sparrow]);
