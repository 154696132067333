// @ts-strict-ignore
import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { getModuleUsage as apiGetModuleUsage, Filters } from '../../api/adminAnalytics';
import { GetModuleUsageResponse } from '../../../types/routes/module';
import { RejectedPayload } from './class';

export const resetModuleUsage = createAction('admin-analytics/module-usage/reset');
export const getModuleUsageAction = createAsyncThunk<
  GetModuleUsageResponse,
  { filters: Filters; debug: string | null },
  {
    rejectValue: RejectedPayload;
  }
>('admin-analytics/module-usage/get', async ({ filters, debug }, { rejectWithValue }) => {
  try {
    const result = await apiGetModuleUsage(filters, debug);
    if (result.timing && result.timing.length > 0 && debug) console.log('+++ module usage timing', result.timing);
    return result;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error);
  }
});
