import { Record, Static, Boolean, String, Number, Null } from 'runtypes';
import { Route } from './utils';
import { userRoutes } from './user';
import { classRoutes } from './class';
import { classroomRoutes } from './classroom';
import { moduleRoutes } from './module';
import { externalRoutes } from './external';
import { sessionRoutes } from './session';
import { adminRoutes } from './admin';
import { mailerRoutes } from './mailer';

export const PingResponseRecord = Record({
  commit: String,
  release: String,
  connections: Number,
  database: Boolean,
  tlsProtocol: String.Or(Null),
  cookieMaxAgeHours: Number,
  nodeEnv: String.Or(Null),
});
export type PingResponse = Static<typeof PingResponseRecord>;

export const routes: { [name: string]: Route } = {
  ping: {
    method: 'get',
    path: '/ping',
    requestBody: Record({}),
    responseData: PingResponseRecord,
  },

  ...userRoutes(),
  ...classRoutes(),
  ...classroomRoutes(),
  ...moduleRoutes(),
  ...externalRoutes(),
  ...sessionRoutes(),
  ...adminRoutes(),
  ...mailerRoutes(),
};
