import React from 'react';
import styled from '@emotion/styled';
import { Text } from './text';
import { primary } from '../utils/colors';
import { PageContainer } from './pageContainer';
import Paper from '@mui/material/Paper';

interface Props {
  title: string;
  children?: React.ReactNode;
}

export const AuthContainer: React.FC<Props> = ({ title, children }) => {
  return (
    <PageContainer header={<></>} leftNav={null}>
      <StyledLogoImgHolder>
        <StyledLogoImg src="/assets/images/logo-alpha-name.png" alt="Prisms VR" />
      </StyledLogoImgHolder>
      <Container elevation={6}>
        <Content>
          <Title center variant="lg2" color={primary}>
            {title}
          </Title>
          {children}
        </Content>
      </Container>
    </PageContainer>
  );
};

const StyledLogoImgHolder = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '4rem',
});

const StyledLogoImg = styled.img({
  width: '22rem',
  margin: 'auto',
});

const Container = styled(Paper)<{ children: React.ReactNode; elevation: number }>({
  width: '35rem',
  margin: '3rem auto 0',
  borderRadius: 0,
  marginBottom: '2rem',
});

const Content = styled.div({
  padding: '2.5rem',
});

const Title = styled(Text)({
  marginBottom: '1rem',
});
