// @ts-strict-ignore
import React, { useEffect, useRef } from 'react';
import { PageContainer } from '../components/pageContainer';
import { Header } from '../components/header';
import styled from '@emotion/styled';
import { Alert } from '../components/Alert';
import { TextField } from '../components/textField';
import { AuthOverlay } from '../components/authOverlay';
import { useSelector, useDispatch } from 'react-redux';

import {
  signout as signoutAction,
  updateProfile as updateProfileAction,
  updateProfileUi as updateProfileUiAction,
} from '../redux/actions/user';
import { Text } from '../components/text';
import { RadioButtonGroup } from '../components/RadioButtonOptions';
import { AuthContainer } from '../components/authContainer';
import { UserAccountStatus } from '../../types/models';
import { useNavigate } from 'react-router-dom';
import { UserAccountProfile } from '../../types/routes/user';

export const Profile: React.FC = () => {
  const fetchingUser = useSelector((state) => state.user.fetchingUser);
  const fetchedUser = useSelector((state) => state.user.fetchedUser);
  const profileResStyle = useSelector((state) => state.user.profileResStyle);
  const profileRes = useSelector((state) => state.user.profileRes);
  const profileFetching = useSelector((state) => state.user.profileFetching);
  const emails = useSelector((state) => state.user.emails);
  const profile = useSelector((state) => state.user.profile);
  const status = useSelector((state) => state.user.status);
  const didMountRef = useRef(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onProfileInfoChange =
    (attr: keyof UserAccountProfile) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const newProfile = { ...profile, [attr]: event.target.value };
      dispatch(updateProfileUiAction({ profile: newProfile }));
    };

  useEffect(() => {
    if (fetchedUser) {
      if (didMountRef.current) {
        const timeout = setTimeout(function () {
          if (!profileFetching) {
            dispatch(updateProfileAction({ profile }));
          }
        }, 1500);

        return function () {
          clearTimeout(timeout);
        };
      } else {
        didMountRef.current = true; // Attempt to prevent initial changes saved alert
      }
    }
  }, [profile]);

  // This was the only way I could find to re-authenticate all data endpoints correctly.
  useEffect(() => {
    if (!fetchingUser) {
      if (fetchedUser) {
        if (status != UserAccountStatus.pending) {
          dispatch(signoutAction());
        }
      }
    }
  }, [fetchingUser]);

  useEffect(() => {
    if (!fetchedUser) {
      navigate('/login');
    }
  }, [fetchedUser]);

  if (fetchingUser) return <AuthOverlay />;

  let message: string;
  if (profile) {
    message = 'Your account is being reviewed by Prisms staff.';
    if (emails.length > 0) message += `  After the review, you will receive an email at ${emails[0]}.`;
    else
      message +=
        '  However, there is no email associated with your account.  Please contact Prisms at support@prismsvr.com';
  } else {
    message = 'Please enter the information below.';
    message += ' After entering this information, Prisms staff will review your account.';
  }

  return (
    <PageContainer header={<Header />} leftNav={null}>
      <AuthContainer title={'Welcome to Prisms'}>
        <Text variant="p">{message}</Text>
        <StyledForm data-cy="profile-page" id="signup">
          <RadioButtonGroup
            title="Role"
            options={['Teacher', 'Tutor', 'Parent', 'District Administrator', 'School Administrator']}
            value={'profileRole' in profile ? profile.profileRole : null}
            onChange={onProfileInfoChange('profileRole')}
            other
          ></RadioButtonGroup>
          <StyledLabel variant="p">School or Organization (if applicable)</StyledLabel>
          <StyledInput
            data-cy="profile-page-school-name-text"
            placeholder="School"
            type="text"
            value={'school' in profile ? profile.school : ''}
            onChange={onProfileInfoChange('school')}
          />
          <StyledLabel variant="p">School District (if applicable)</StyledLabel>
          <StyledInput
            placeholder="District"
            type="text"
            value={'district' in profile ? profile.district : ''}
            onChange={onProfileInfoChange('district')}
          />
        </StyledForm>
        {profileRes && (
          <Alert severity={profileResStyle} isOpen hideX>
            {profileRes}
          </Alert>
        )}
      </AuthContainer>
    </PageContainer>
  );
};

const StyledForm = styled.form({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
});

const StyledLabel = styled(Text)({
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '2rem',
});
