// @ts-strict-ignore
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dictionary as DictionaryRecord,
  Record,
  Array,
  Number as NumberRecord,
  String as StringRecord,
  Static,
} from 'runtypes';

import { Text } from '../../components/text';
import { ModuleDefinition } from '../../../types/routes/module';
import { black, greenText, pinkText, red, yellowText } from '../../utils/colors';
import { UserStateName } from '../../../types/models';
import { ClassStateResponse } from '../../../types/routes/class';
import { Class as ClassWithStudents, Student } from '../../../types/routes/class';
import { pushOrInsertClassState } from '../../redux/actions/class';
import { TextField } from '../../components/textField';
import { Button } from '../../components/button';

interface Props {
  currentClass: ClassWithStudents;
  student: Student;
  module: ModuleDefinition;
  points: QuizItem;
}

export interface QuizItem {
  points: number;
  modulePart: number;
}

const ModuleAssessmentRecord = DictionaryRecord(
  Array(Record({ score: NumberRecord, total: NumberRecord, modulePart: NumberRecord })),
  StringRecord,
);
type ModuleAssessment = Static<typeof ModuleAssessmentRecord>;

const getModuleAssessments = (classStates: ClassStateResponse, student: Student): ModuleAssessment => {
  let moduleAssessments: ModuleAssessment = {};
  if (classStates) {
    for (const us of classStates.userStates) {
      if (
        us.userId == student.id &&
        us.name == UserStateName.moduleAssessment &&
        ModuleAssessmentRecord.guard(us.value)
      ) {
        moduleAssessments = ModuleAssessmentRecord.check(us.value);
      }
    }
  }
  return moduleAssessments;
};
const getScore = (moduleAssessments: ModuleAssessment, module: ModuleDefinition, points: QuizItem): number | null => {
  const moduleId = module.id;
  if (moduleId in moduleAssessments) {
    for (const quiz of moduleAssessments[moduleId]) {
      if (quiz.modulePart == points.modulePart) {
        return quiz.score;
      }
    }
  }
  return null;
};

const pickRecommendation = (score: number, points: number) => {
  if (score === null) return ['', black];
  if (score < 0.65 * points) return ['Support', pinkText];
  if (score < 0.8 * points) return ['Solidify', yellowText];
  return ['Extend', greenText];
};

export const QuizScore = ({ currentClass, student, module, points }: Props) => {
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;

  const dispatch = useDispatch();

  const [inputState, setInputState] = useState<boolean>(false);
  const [inputErrorState, setInputErrorState] = useState<boolean>(false);

  // The associated dispatch call is in the index.ts file.
  const thisClassStates = useSelector((state) =>
    state.class.classState.find((cs: ClassStateResponse) => cs.classId == currentClass.id),
  );
  const classStateLoading = useSelector((state) => state.class.fetchingClassState || state.class.postingClassState);

  const moduleAssessments = getModuleAssessments(thisClassStates, student);
  const score = getScore(moduleAssessments, module, points);
  // if there is no value in the box, allow input
  if (score === null && !inputState && !classStateLoading) {
    if (debugPrint) console.log(`*** getScore set input state true ${student.id}`);
    setInputState(true);
  }

  const saveScore = (value: string): boolean => {
    if (debugPrint) console.log(`*** saving ${JSON.stringify(value)} for ${student.id} inputState ${inputState}`);
    // This will convert whitespace to zero.
    const numberValue = Number(value);
    if (isNaN(numberValue) || numberValue < 0 || numberValue > points.points) {
      if (!inputErrorState) setInputErrorState(true);
      return false;
    }
    const tma = [];
    for (const a of moduleAssessments[module.id] || []) {
      if (a.modulePart != points.modulePart) tma.push(a);
    }
    // Remove value if only whitespace
    if (value.trim()) tma.push({ score: numberValue, total: points.points, modulePart: points.modulePart });
    const updatedModuleAssessments: ModuleAssessment = {
      ...moduleAssessments,
      [module.id]: tma,
    };
    dispatch(
      pushOrInsertClassState({
        classId: currentClass.id,
        push: true,
        undoOverrides: false,
        data: [
          {
            userId: student.id,
            name: UserStateName.moduleAssessment,
            value: updatedModuleAssessments,
          },
        ],
      }),
    );
    if (inputState && value.trim()) {
      if (debugPrint) console.log(`*** saveScore set input state false ${student.id}`);
      setInputState(false);
    }
    if (inputErrorState) setInputErrorState(false);
    return true;
  };

  const inputId = `$quiz-input-${student.id}-${module.id}-${points.modulePart}`;
  const percentScore = score === null ? '' : '' + Math.round((100 * score) / points.points) + '%';
  const scoreText: string = inputState ? '/' + points.points : percentScore;
  const [, scoreColor] = pickRecommendation(score, points.points);

  // The input element is material UI.  Setting the color is not obvious
  return (
    <AssignmentCell>
      <TextWrapper>
        <StyledInput
          id={inputId}
          type="text"
          defaultValue={score === null ? '' : String(score)}
          inputProps={{
            style: {
              color: inputErrorState ? red : 'inherit',
              paddingTop: '5px',
              paddingBottom: '5px',
            },
          }}
          style={{ display: inputState ? 'block' : 'none' }}
        />
        <Text variant="md" color={inputState ? black : scoreColor} center>
          {scoreText}
        </Text>
      </TextWrapper>
      <AssignmentButton
        disabled={classStateLoading && inputState}
        onClick={() => {
          if (inputState) {
            const input = document.getElementById(inputId) as HTMLInputElement;
            saveScore(input.value);
          } else {
            if (debugPrint) console.log(`*** button set input state true ${student.id}`);
            setInputState(true);
          }
        }}
      >
        {inputState ? 'Save' : 'Edit'}
      </AssignmentButton>
      {debug && <Text variant="nav">{JSON.stringify([score, inputState, inputErrorState])}</Text>}
    </AssignmentCell>
  );
};

export const Recommendation = ({ currentClass, student, module, points }: Props) => {
  // The associated dispatch call is in the index.ts file.
  const thisClassStates = useSelector((state) =>
    state.class.classState.find((cs: ClassStateResponse) => cs.classId == currentClass.id),
  );

  const moduleAssessments = getModuleAssessments(thisClassStates, student);
  const score = getScore(moduleAssessments, module, points);

  const [recommendation] = pickRecommendation(score, points.points);

  return (
    <AssignmentCell>
      <Text variant="nav">{recommendation}</Text>
    </AssignmentCell>
  );
};

const TextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end',
});

const AssignmentCell = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'end',
  justifyContent: 'center',
});

const StyledInput = styled(TextField)({
  width: '4rem',
  marginRight: '0.5rem',
});

const AssignmentButton = styled(Button)({
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: '0.5rem',
  paddingRight: '0.5rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  marginRight: '0.5rem',
  marginLeft: '0.5rem',
});
