// @ts-strict-ignore
import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signin as signinAction } from '../redux/actions/user';

import { Link, useNavigate } from 'react-router-dom';
import { Alert } from '../components/Alert';
import { AuthOverlay } from '../components/authOverlay';
import { AuthContainer } from '../components/authContainer';
import { TextField } from '../components/textField';
import { Button } from '../components/button';
import { Text } from '../components/text';
import { configPublic } from '../../config-public';
import { black } from '../utils/colors';

export const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pageInIframe, setPageInIframe] = useState(false);
  const authError = useSelector((state) => state.user.authError);
  const authStyle = useSelector((state) => state.user.authStyle);
  const authErrorHttpCode = useSelector((state) => state.user.authErrorHttpCode);
  const authErrorName = useSelector((state) => state.user.authErrorName);
  const fetchedUser = useSelector((state) => state.user.fetchedUser);
  const fetchingUser = useSelector((state) => state.user.fetchingUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clever = configPublic.clever;
  const canvas = configPublic.canvas;
  const google = configPublic.google;
  const gwinnett = configPublic.gwinnett;
  const classLink = configPublic.classLink;
  const query = new URLSearchParams(window.location.search);
  const all = query.get('all');
  const useGwinnett = query.get('gwinnett');
  const lastLoginMethod = query.get('last_login_method');
  const scopes = encodeURIComponent(google.scopes.join(' '));

  const signin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(signinAction({ email, password }));
  };
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);
  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const inIframe = () => window.self !== window.top;

  // If already logged in, go to root page.
  useEffect(() => {
    if (!fetchingUser && fetchedUser) {
      const nowDate = new Date();
      const now = nowDate.getTime();
      window.localStorage.setItem('customPersistIdleTimer', now.toString());
      navigate('/');
    } else {
      window.localStorage.removeItem('customPersistIdleTimer');
      if (inIframe()) {
        setPageInIframe(true);
      }
    }
  }, [fetchedUser, fetchingUser]);

  if (fetchingUser) return <AuthOverlay />;
  return (
    <AuthContainer title="">
      {pageInIframe && (
        <StyledSignInOptionDiv>
          <StyledLabel variant="lg3">Ensure Login Security</StyledLabel>
          <StyledLabel variant="p">
            For your security, our login system does not support loading in an iframe.
          </StyledLabel>
          <StyledLabel variant="p">
            Canvas users, please ensure that you check the box next to “Load in a new tab” when setting up the external
            tool.
          </StyledLabel>
          <StyledLabel variant="p">
            For more details, follow the <i>Canvas Instructions</i> link below.
          </StyledLabel>
          <StyledAnchor
            href="https://docs.google.com/document/d/17wC5ZrTzQwmh_7IWAK0pcRfKi1QPcqDFImkhrR4Fk5U/edit#bookmark=id.djvxp7lwe2v0"
            target="_blank"
          >
            Canvas Instructions
          </StyledAnchor>
        </StyledSignInOptionDiv>
      )}
      {!pageInIframe && lastLoginMethod && (
        <StyledSignInOptionDiv>
          <StyledLabel variant="lg3">Logout Successful</StyledLabel>
          <StyledLabel variant="p">You are logged out and may now safely close this window.</StyledLabel>
          <StyledLabel variant="p">Thank you for using Prisms.</StyledLabel>
        </StyledSignInOptionDiv>
      )}
      {!pageInIframe && !lastLoginMethod && (
        <>
          {(clever.active || all) && (
            <StyledSignInOptionDiv>
              <StyledAnchor
                href={
                  clever.oauth2Endpoint +
                  '?response_type=code&redirect_uri=' +
                  encodeURIComponent(clever.redirectURL) +
                  '&client_id=' +
                  clever.clientId +
                  (clever.districtId ? '&district_id=' + clever.districtId : '')
                }
              >
                <ButtonImageShadow src="/assets/images/login-with-clever.png" alt="Sign in with Clever" />
              </StyledAnchor>
            </StyledSignInOptionDiv>
          )}

          {canvas && (canvas.active || all) && (
            <StyledSignInOptionDiv>
              <StyledAnchor
                href={
                  canvas.providerURL +
                  '?response_type=code&redirect_uri=' +
                  encodeURIComponent(canvas.callbackURL) +
                  '&client_id=' +
                  canvas.clientId +
                  (canvas.providerName == '' ? '' : '&district_id=' + canvas.providerName)
                }
              >
                <Text variant="nav" center>
                  <ButtonImageShadow src="/assets/images/login-with-canvas.png" alt="Sign in with Canvas" />
                </Text>
              </StyledAnchor>
            </StyledSignInOptionDiv>
          )}

          {google && (google.active || all) && (
            <StyledSignInOptionDiv>
              <StyledAnchor
                href={
                  google.oauth2Endpoint +
                  '?response_type=code&redirect_uri=' +
                  encodeURIComponent(google.redirectURL) +
                  '&client_id=' +
                  google.clientId +
                  '&scope=' +
                  scopes +
                  // '&access_type=offline&prompt=consent' asks for consent every time,
                  // and enables server access when user is offline, which gets server
                  // a refresh token.
                  '&access_type=offline&prompt=consent'
                }
              >
                <ButtonImage
                  src="/assets/images/login-with-google.png"
                  alt="Sign in with Google"
                  data-cy="login-with-google-button"
                />
              </StyledAnchor>
            </StyledSignInOptionDiv>
          )}

          {gwinnett && (gwinnett.active || useGwinnett || all) && (
            <StyledSignInOptionDiv>
              <StyledAnchor
                href={
                  gwinnett.authorizationURL +
                  '?response_type=code&scope=openid&redirect_uri=' +
                  encodeURIComponent(gwinnett.callbackURL) +
                  '&client_id=' +
                  gwinnett.clientID
                }
              >
                <Text variant="nav" center>
                  <b>Sign in with Gwinnett County</b>
                </Text>
              </StyledAnchor>
            </StyledSignInOptionDiv>
          )}

          {classLink && (classLink.active || all) && (
            <StyledSignInOptionDiv>
              <StyledAnchor
                href={
                  /*
                   * All possible scopes for now, matches "Generate
                   * Authorization URI
                   */
                  classLink.authorizationURL +
                  '?response_type=code&scope=oneroster,profile,openid,full&redirect_uri=' +
                  encodeURIComponent(classLink.callbackURL) +
                  '&client_id=' +
                  classLink.clientId
                }
              >
                <ButtonImageShadow src="/assets/images/login-with-classlink.png" alt="Sign in with ClassLink" />
              </StyledAnchor>
            </StyledSignInOptionDiv>
          )}

          <StyledHr />

          <StyledForm onSubmit={signin}>
            {authError && !(authErrorHttpCode == 401 && authErrorName == 'no-user-session') && (
              <Alert severity={authStyle}>{authError}</Alert>
            )}
            <StyledLabel variant="p">Email/login name</StyledLabel>
            <StyledInput
              data-cy="login-input-email"
              placeholder="Email/login"
              type="text"
              value={email}
              onChange={onEmailChange}
            />
            <StyledLabel variant="p">Password</StyledLabel>
            <StyledInput
              data-cy="login-input-password"
              placeholder="Password"
              type="password"
              value={password}
              onChange={onPasswordChange}
            />
            <Text variant="sm" right={true}>
              <StyledLink to="/password-reset/start">Forgot your password?</StyledLink>
            </Text>
            <StyledButton type="submit" data-cy="sign-in">
              Sign in
            </StyledButton>
            <StyledLink data-cy="new-account-button" to="/signup">
              <Text variant="nav" center>
                <b>New Account</b>
              </Text>
            </StyledLink>
          </StyledForm>
        </>
      )}
    </AuthContainer>
  );
};

const StyledForm = styled.form({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const StyledLabel = styled(Text)({
  marginTop: '.5rem',
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '.2rem',
});

// Accessibility:  Links visually distinguishable from non-links
const StyledLink = styled(Link)({
  color: black,
});

// Accessibility:  Links visually distinguishable from non-links
const StyledAnchor = styled.a({
  width: '12rem',
  color: black,
  // border: '1px solid black',
  // borderRadius: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  height: '4rem',
  padding: '0',
  margin: 'auto',
});

const ButtonImage = styled.img({
  width: '12rem',
  alignItems: 'center',
  display: 'flex',
  padding: '0',
});

const ButtonImageShadow = styled.img({
  width: '12rem',
  alignItems: 'center',
  display: 'flex',
  padding: '0',
  boxShadow: '0 1px 2px 2px #ddd',
  borderRadius: '3px',
});

const StyledButton = styled(Button)({
  width: '12rem',
  margin: '2rem auto',
});

const StyledSignInOptionDiv = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: '2rem',
});

const StyledHr = styled.hr({
  color: '#cccccc',
  marginTop: '2rem',
  marginBottom: '2rem',
});
