import styled from '@emotion/styled';
import { charcoal15, green, red, gray, primary, orange, yellow } from '../utils/colors';
import { Text } from './text';

export enum Evaluation {
  notStarted = 'notStarted',
  incomplete = 'incomplete',
  incorrect = 'incorrect',
  wrongForm = 'wrongForm',
  correctWithDifficulties = 'correctWithDifficulties',
  correct = 'correct',
  complete = 'complete', // distinct since tooltip is different
}

export const evaluationColors: { [K in Evaluation]: string } = {
  notStarted: gray,
  incomplete: gray,
  incorrect: red,
  wrongForm: orange,
  correctWithDifficulties: yellow,
  correct: green,
  complete: green,
};

export const ActionBar = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem',
  borderBottom: `1px solid ${charcoal15}`,
});

/*
 * Google font download set in index.html
 */
export const AccessKey = styled(Text)({
  fontFamily: 'Roboto Mono',
  textTransform: 'uppercase',
});

export const StyledTable = styled.table({
  borderSpacing: 0,
  margin: '1rem auto 0',

  'th:first-of-type, td:first-of-type': {
    textAlign: 'left',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },

  'th:not(:first-of-type), td:not(:first-of-type)': {
    borderLeft: `1px solid ${gray}`,
  },

  'thead tr': {
    verticalAlign: 'bottom',
  },

  'thead tr th': {
    borderBottom: `2px solid ${primary}`,
    paddingBottom: '.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },

  td: {
    padding: '1rem',
    borderBottom: `1px solid ${gray}`,
  },

  'tbody tr:last-child td': {
    borderBottom: 0,
  },
});
