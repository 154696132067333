export function getEpochTimeAfterHoursInSeconds(hours: number): number {
  return Math.floor((Date.now() + hours * 60 * 60 * 1000) / 1000);
}

export const getDifferenceInMinutes = (time1: Date, time2: Date): string => {
  return ((time2.getTime() - time1.getTime()) / (1000 * 60)).toFixed(1);
};

export const dateToHHMM = (date: Date): string => {
  return (date.getHours() % 12 || 12) + ':' + date.getMinutes().toString().padStart(2, '0');
};

export const secondsToHHMMSS = (time: number): string => {
  if (time < 0) return '-' + secondsToHHMMSS(-time);
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - 3600 * hours) / 60);
  const seconds = time - minutes * 60 - hours * 3600;
  if (hours > 0) {
    return hours.toString() + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toFixed().padStart(2, '0');
  } else if (minutes > 0) {
    return minutes.toString() + ':' + seconds.toFixed().padStart(2, '0');
  } else {
    return seconds.toFixed();
  }
};
