import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, Cell } from 'recharts';

import { FinishedTasks } from '../../../types/routes/module';
import { Text, textStyle } from '../../components/text';

/*
 * Plotting package criteria:
 *   Open Source, SVG based, small, widely used, large user base.
 *   Can do time servies, stacked, line, and histogram charts
 *   with axes labeling.
 * D3 is nice but hard to program.
 * Review articles that helped with the library choice:
 *   https://flatlogic.com/blog/best-19-javascript-charts-libraries/
 *   https://www.softwaretestinghelp.com/best-javascript-visualization-libraries/
 *
 * Recharts increased the size of main.js from 1.6 to 2.1 MB
 *
 * We want a fixed size based on the number of bins.
 *
 * For rendering bar labels, see bug work-around:
 * https://stackoverflow.com/questions/55722306/
 */

export default function TaskHistogram({
  finishedTasks,
  moduleId,
  title,
}: {
  finishedTasks: FinishedTasks;
  moduleId: string;
  title?: string;
}) {
  const debugPrint = false;
  const taskParts = finishedTasks.tasks.map((task) => task.modulePart);
  const width = finishedTasks.tasks.length * 30 + 100;
  const parts = Array.from(new Set<number>(taskParts));

  let completionTipText = '';
  finishedTasks.counts.forEach((bin, index) => {
    if (index > 0) {
      completionTipText += taskParts[index] == taskParts[index - 1] ? ', ' : ' | ';
    }
    completionTipText += bin;
  });

  const data: { number: number; count: number; modulePart: number; hue: number }[] = finishedTasks.counts.map(
    (bin, index) => {
      const task = finishedTasks.tasks[index];
      return {
        number: task.number,
        count: bin,
        modulePart: task.modulePart,
        // Avoid the ugly green that is 180 opposite "primary"
        hue: (parts.indexOf(task.modulePart) * 120 - parts.length * 60 + 333 + 60) % 360,
      };
    },
  );
  if (debugPrint) console.log(`*** module ${moduleId} data`, data);

  // Note that the color "primary" is hsl(333°, 74%, 49%)
  // Tweak axis label position:
  //   https://github.com/recharts/recharts/issues/709
  return (
    <>
      <Text variant="p" center data-cy="admin-analytics-module-usage-started-time-on-task-histogram">
        {title || 'The number of students who have completed each task in the module'}
      </Text>
      {debugPrint && <Text variant="p">{completionTipText}</Text>}
      <BarChart width={width} height={200} data={data} margin={{ top: 20, right: 10, bottom: 10, left: 10 }}>
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis label={{ value: 'Task', ...textStyle('normal'), position: 'insideBottom', dy: 10 }} dataKey="number" />
        <YAxis
          dataKey="count"
          type="number"
          label={{ value: 'Students', angle: -90, position: 'insideLeft', ...textStyle('normal') }}
        />
        <Bar isAnimationActive={false} dataKey="count">
          {Math.max(...data.map((entry) => entry.count)) < 1000 && <LabelList dataKey="count" position="top" />}
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`hsl(${entry.hue},74%, 49%)`} />
          ))}
        </Bar>
      </BarChart>
    </>
  );
}
