import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getLtiPlatforms as apiGetLtiPlatforms,
  registerLtiPlatform as apiRegisterLtiPlatform,
  updateLtiPlatform as apiUpdateLtiPlatform,
  UpdateLtiPlatformId,
} from '../../api/admin';
import { RegisterLtiPlatformRequest } from '../../../types/routes/admin';

export const getLtiPlatforms = createAsyncThunk('admin/getLtiPlatforms', async () => {
  return apiGetLtiPlatforms();
});

export const registerLtiPlatform = createAsyncThunk(
  'admin/registerLtiPlatform',
  async (data: RegisterLtiPlatformRequest) => {
    return apiRegisterLtiPlatform(data);
  },
);

export const updateLtiPlatform = createAsyncThunk('admin/updateLtiPlatform', async (data: UpdateLtiPlatformId) => {
  return apiUpdateLtiPlatform(data);
});
