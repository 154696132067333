// @ts-strict-ignore
import React, { useState } from 'react';
import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import { parse } from 'papaparse';
import { black, primary, white } from '../../utils/colors';
import { Button } from '../../components/button';
import { Text } from '../../components/text';
import { NewStudent, Student } from '../../../types/routes/class';
import Input from '@mui/material/Input';

interface Props {
  closeModal: () => void;
  students: (Student | NewStudent)[];
  setStudents: (students: (Student | NewStudent)[]) => void;
}

export const AddStudentCSVModal: React.FC<Props> = ({ closeModal, students, setStudents }) => {
  const [newStudents, setNewStudents] = useState<NewStudent[]>([]);
  const [parseError, setParseError] = useState('');
  const [success, setSuccess] = useState('');

  const addStudents = () => {
    setStudents([...students, ...newStudents]);
    closeModal();
  };

  const parseCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      delimiter: ',',
      fastMode: false,
      transformHeader: (header: string) => header.trim().toLowerCase(),
      transform: (val: string) => val.trim(),
      complete: (res: { data: { [key: string]: any }[] }) => setStudent(res.data),
    });
  };

  const setStudent = (data: { [key: string]: any }[]) => {
    if (data.length == 0) {
      setSuccess('');
      setParseError('Error: No data found');
      return;
    }

    // Case-insensitive column headers
    const keys = Object.keys(data[0]);

    if (
      !(
        (keys.includes('givenname') || keys.includes('firstname')) &&
        (keys.includes('familyname') || keys.includes('lastname')) &&
        (keys.includes('username') || keys.includes('email'))
      )
    ) {
      setSuccess('');
      setParseError('Error: CSV missing required headers');
      return;
    }

    const data2: NewStudent[] = data.map((student) => ({
      firstName: student.givenname || student.firstname || null,
      middleName: student.middlename || null,
      lastName: student.familyname || student.lastname || null,
      email: student.username || student.email || '',
    }));

    const emails = students.map((s) => s.email);
    for (const row of data2) {
      if (!(row.firstName || row.lastName || row.email)) {
        setSuccess('');
        setParseError('Error: missing data');
        return;
      }
      if (row.email && emails.includes(row.email)) {
        setSuccess('');
        setParseError(`Error: repeated login name ${row.email}.`);
        return;
      }
      if (row.email) emails.push(row.email);
    }

    setNewStudents(data2);
    setSuccess(`Upload successful! Select "Add Students" to add ${data.length} students`);
    setParseError('');
  };

  return (
    <Container>
      <Overlay onClick={closeModal} />
      <Content>
        <StyledText color={primary} variant="lg2">
          Upload Class Roster CSV
        </StyledText>
        <StyledText variant="md">
          CSV must contain the following headers: “firstName,” “lastName,” and “userName” or “email.”
        </StyledText>
        <StyledText variant="md">
          For “userName,” we recommend using the part of the student’s email address before the @ symbol. Students will
          type this into the Username on their login keyboard in the Prisms app. Once this CSV is uploaded, our system
          will generate a 5-digit Password for them to type in underneath the Username.
        </StyledText>
        <StyledInput
          onChange={parseCSV}
          disableUnderline
          type="file"
          inputProps={{ accept: '.csv', 'data-cy': 'class-inport-csv-file-select' }}
          fullWidth
        />
        {parseError && (
          <StyledAlert severity="error" title="Format Error">
            {parseError}
          </StyledAlert>
        )}
        {success && <StyledAlert severity="success" title="Success" />}
        <Actions>
          <StyledButton onClick={closeModal} variant="cancel">
            Cancel
          </StyledButton>
          <StyledButton
            data-cy="class-create-add-students-button"
            disabled={(parseError ? true : false) || !success}
            onClick={addStudents}
          >
            Add Students
          </StyledButton>
        </Actions>
      </Content>
    </Container>
  );
};

const Container = styled.div({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  zIndex: 100,
});

const Overlay = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: black,
  opacity: '.5',
});

const Content = styled.div({
  position: 'relative',
  width: '30rem',
  padding: '3.75rem 7.5rem',
  backgroundColor: white,
});

const StyledText = styled(Text)({
  marginTop: '1rem',
  textAlign: 'center',
});

const Student = styled.div({
  margin: '2rem 0',
});

const StyledInput = styled(Input)({
  marginTop: '1rem',
  marginBottom: '1rem',
  border: '1px solid rgb(150, 150, 150)',
  padding: '0.75rem 0.75rem',
  borderRadius: '3px',
  fontSize: '0.875rem',
  backgroundColor: 'transparent !important',
});

const Actions = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 -1rem',
});

const StyledButton = styled(Button)({
  flex: 1,
  margin: '0 1rem',
});

const StyledAlert = styled(Alert)({
  margin: '0 0 1rem',
});
