import React, { useState } from 'react';
import styled from '@emotion/styled';
import Alert from '@mui/material/Alert';
import { black, primary, white } from '../../utils/colors';
import { TextField } from '../../components/textField';
import { Button } from '../../components/button';
import { Text } from '../../components/text';
import { Student, NewStudent } from '../../../types/routes/class';

interface Props {
  closeModal: () => void;
  students: (NewStudent | Student)[];
  setStudents: (students: (NewStudent | Student)[]) => void;
}

const initNewStudent: NewStudent = {
  firstName: null,
  middleName: null,
  lastName: null,
  email: '',
};

export const AddStudentModal: React.FC<Props> = ({ closeModal, students, setStudents }) => {
  const [newStudent, setNewStudent] = useState<NewStudent>(initNewStudent);
  const dataError: string | null =
    newStudent.email && students.some((s) => s.email == newStudent.email) ? 'User name already exists' : null;

  const setStudent = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    attribute: 'name' | 'email' | 'firstName' | 'middleName' | 'lastName',
  ) => {
    setNewStudent({ ...newStudent, [attribute]: event.target.value });
  };

  const addStudent = () => {
    setStudents([...students, newStudent]);
    closeModal();
  };

  return (
    <Container>
      <Overlay onClick={closeModal} />
      <Content>
        <StyledText color={primary} variant="lg2">
          Add Student
        </StyledText>
        <Student>
          <StyledLabel variant="p">Student&apos;s First Name</StyledLabel>
          <StyledInput
            onChange={(event) => setStudent(event, 'firstName')}
            data-cy="add-student-modal-input-first-name"
            placeholder="Enter the student's first name."
          />
          <StyledLabel variant="p">Middle Initial</StyledLabel>
          <StyledInput onChange={(event) => setStudent(event, 'middleName')} placeholder="Middle initial" />
          <StyledLabel variant="p">Last Name</StyledLabel>
          <StyledInput
            onChange={(event) => setStudent(event, 'lastName')}
            data-cy="add-student-modal-input-last-name"
            placeholder="Last name"
          />
          <StyledLabel variant="p">Username or Email</StyledLabel>
          <StyledInput
            onChange={(event) => setStudent(event, 'email')}
            data-cy="add-student-modal-input-email"
            placeholder="This will be used to log into the VR app."
          />
        </Student>
        {dataError && (
          <StyledAlert severity="error" title="Format Error">
            {dataError}
          </StyledAlert>
        )}
        <Actions>
          <StyledButton onClick={closeModal} variant="cancel">
            Cancel
          </StyledButton>
          <StyledButton
            data-cy="add-student-modal-confirm-button"
            disabled={!(newStudent.email || newStudent.firstName || newStudent.lastName) || (dataError ? true : false)}
            onClick={addStudent}
          >
            Add Student
          </StyledButton>
        </Actions>
      </Content>
    </Container>
  );
};

const Container = styled.div({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  zIndex: 100,
});

const Overlay = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: black,
  opacity: '.5',
});

const Content = styled.div({
  position: 'relative',
  width: '30rem',
  padding: '3.75rem 7.5rem',
  backgroundColor: white,
});

const StyledText = styled(Text)({
  textAlign: 'center',
});

const Student = styled.div({
  margin: '2rem 0',
});

const StyledLabel = styled(Text)({
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '2rem',
});

const Actions = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0 -1rem',
});

const StyledButton = styled(Button)({
  flex: 1,
  margin: '0 1rem',
});

const StyledAlert = styled(Alert)({
  margin: '0 0 1rem',
});
