import {
  PostClassRequest,
  PostClassResponse,
  PostClassResponseRecord,
  EditClassRequest,
  EditClassResponse,
  EditClassResponseRecord,
  Class,
  ClassRecord,
  GetClassesResponse,
  GetClassesResponseRecord,
  ClassContracts,
  ClassContractsRecord,
  GetLearnerTypesResponse,
  GetLearnerTypesResponseRecord,
  updateLearnerTypeResponseRecord,
  updateLearnerTypeResponse,
  ClassStateResponseRecord,
  ClassStateResponse,
  EditClassNickNameRequest,
  EditClassNickNameResponse,
  EditClassNickNameResponseRecord,
} from '../../types/routes/class';
import { GetStudentUsageResponse, GetStudentUsageResponseRecord } from '../../types/routes/module';
import {
  GetExternalClassesResponse,
  GetExternalClassesResponseRecord,
  ImportExternalClassRequest,
  ImportExternalClassResponse,
  ImportExternalClassResponseRecord,
  UpdateExternalClassResponse,
  UpdateExternalClassResponseRecord,
} from '../../types/routes/external';
import { makeRequest } from '.';
import { routes } from '../../types/routes';
import { map, omit } from 'lodash';
import {
  GetClassStateArgs,
  SetClassStateArgs,
  GetLearnerTypesArgs,
  updateLearnerTypeArgs,
  UpdateLicenseArgs,
} from '../redux/actions/class';
import { toTimestamp } from './accountManagement';

export type GetClassModuleUsageArgs = {
  classId: number;
  moduleIds: string[];
};

export const get = async (classId: number): Promise<Class> => {
  const path = routes.getClass.path.replace(':classId', classId.toString());
  const response = await makeRequest(routes.getClass.method, path);
  return ClassRecord.check(mapClass(response.data));
};

export const getAll = async (): Promise<GetClassesResponse> => {
  const response = await makeRequest(routes.getClasses.method, routes.getClasses.path);
  return GetClassesResponseRecord.check({
    classes: map(response.data.classes, mapClass),
  });
};

export const getExternal = async (): Promise<GetExternalClassesResponse> => {
  const response = await makeRequest(routes.getExternalClasses.method, routes.getExternalClasses.path);
  return GetExternalClassesResponseRecord.check(response.data);
};

export const create = async (data: PostClassRequest): Promise<PostClassResponse> => {
  const response = await makeRequest(routes.createClass.method, routes.createClass.path, data);
  return PostClassResponseRecord.check(mapClass(response.data));
};

export const importExternal = async (data: ImportExternalClassRequest): Promise<ImportExternalClassResponse> => {
  const response = await makeRequest(routes.importExternalClass.method, routes.importExternalClass.path, data);
  return ImportExternalClassResponseRecord.check(mapClass(response.data));
};

export const updateExternal = async (classId: number): Promise<UpdateExternalClassResponse> => {
  const response = await makeRequest(
    routes.updateExternalClass.method,
    routes.updateExternalClass.path.replace(':classId', classId.toString()),
  );
  return UpdateExternalClassResponseRecord.check(mapClass(response.data));
};

export const edit = async (data: EditClassRequest): Promise<EditClassResponse> => {
  const response = await makeRequest(routes.editClass.method, routes.editClass.path, data);
  return EditClassResponseRecord.check(mapClass(response.data));
};
export const editNickName = async (data: EditClassNickNameRequest): Promise<EditClassNickNameResponse> => {
  const response = await makeRequest(
    routes.editClassNickName.method,
    routes.editClassNickName.path.replace(':classId', data.id.toString()),
    data,
  );
  return EditClassNickNameResponseRecord.check({ id: response.data.id, nickName: response.data.nickName });
};

export const remove = async (classId: number): Promise<void> => {
  await makeRequest(routes.removeClass.method, routes.removeClass.path.replace(':classId', classId.toString()));
};

export const updateLicense = async (data: UpdateLicenseArgs): Promise<UpdateLicenseArgs> => {
  await makeRequest(
    routes.updateLicenseStatus.method,
    routes.updateLicenseStatus.path
      .replace(':userId', data.studentId.toString())
      .replace(':classId', data.classId.toString()),
    {
      contractId: data.contractId,
      licenseStatus: data.licenseStatus,
    },
  );
  return data;
};

export const getContracts = async (classId: number): Promise<ClassContracts> => {
  const response = await makeRequest(
    routes.getContracts.method,
    routes.getContracts.path.replace(':classId', classId.toString()),
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const contracts = response.data.contracts.map((c: any) => ({
    ...c,
    contractStartDate: toTimestamp(c.contractStartDate),
    contractEndDate: toTimestamp(c.contractEndDate),
  }));
  return ClassContractsRecord.check({ ...response.data, contracts });
};

export const getClassLearnerTypes = async (data: GetLearnerTypesArgs): Promise<GetLearnerTypesResponse> => {
  const response = await makeRequest(
    routes.getClassLearnerTypes.method,
    routes.getClassLearnerTypes.path.replace(':classId', data.classId.toString()),
  );
  const learnerTypes = GetLearnerTypesResponseRecord.check(response.data);
  return learnerTypes;
};

export const postClassUserLearnerType = async (data: updateLearnerTypeArgs): Promise<updateLearnerTypeResponse> => {
  const response = await makeRequest(
    routes.updateLearnerType.method,
    routes.updateLearnerType.path
      .replace(':classId', data.classId.toString())
      .replace(':userId', data.userId.toString()),
    {
      firstLearnerType: data.firstLearnerType,
      secondLearnerType: data.secondLearnerType,
    },
  );
  return updateLearnerTypeResponseRecord.check(response.data);
};

export const getClassState = async (data: GetClassStateArgs): Promise<ClassStateResponse> => {
  const response = await makeRequest(
    routes.getClassState.method,
    routes.getClassState.path.replace(':classId', data.classId.toString()),
  );
  const classState = ClassStateResponseRecord.check(mapClassState(response.data));
  return classState;
};

export const pushOrInsertClassState = async (data: SetClassStateArgs): Promise<ClassStateResponse> => {
  const classId = data.classId.toString();
  const reqData = omit(data, ['classId']);
  const response = await makeRequest(
    routes.pushOrInsertClassState.method,
    routes.pushOrInsertClassState.path.replace(':classId', classId),
    reqData,
  );
  return ClassStateResponseRecord.check(mapClassState(response.data));
};

export const getClassStudentUsage = async (data: GetClassModuleUsageArgs): Promise<GetStudentUsageResponse> => {
  const response = await makeRequest(
    routes.getClassStudentUsage.method,
    routes.getClassStudentUsage.path.replace(':classId', data.classId.toString()),
  );
  return GetStudentUsageResponseRecord.check(response.data);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapClass = (rawClass: any): Class => ({
  ...rawClass,
  createdAt: new Date(rawClass.createdAt),
  startAt: rawClass.startAt ? new Date(rawClass.startAt) : rawClass.startAt,
  endAt: rawClass.endAt ? new Date(rawClass.endAt) : rawClass.endAt,
  students: map(rawClass.students, (student) => ({
    ...student,
    createdAt: new Date(rawClass.createdAt),
  })),
  teachers: map(rawClass.teachers, (teacher) => ({
    ...teacher,
    createdAt: new Date(rawClass.createdAt),
  })),
});

const mapClassState = (rawClassState: any): ClassStateResponse => ({
  ...rawClassState,
  userStates: map(rawClassState.userStates, (cs) => ({
    ...cs,
    createdAt: new Date(cs.createdAt),
  })),
});
