import React from 'react';
import styled from '@emotion/styled';
import { white } from '../utils/colors';

interface Props {
  header: React.ReactNode;
  leftNav: React.ReactNode;
  children?: React.ReactNode;
}

export const PageContainer: React.FC<Props> = ({ header, leftNav, children }) => {
  return (
    <Container>
      {leftNav}
      <Content>
        {header}
        {children}
      </Content>
    </Container>
  );
};

const Container = styled.div({
  backgroundColor: white,
  height: '100vh',
  width: '100vw',
  display: 'flex',
});

const Content = styled.div({
  flex: '1',
  overflowY: 'scroll',
  overflowX: 'hidden',
});
