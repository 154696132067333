import { GetModuleUsageResponseRecord, GetModuleUsageResponse } from '../../types/routes/module';

import { makeRequest } from '.';
import { routes } from '../../types/routes';
import { URL_QUERY_ARRAY_JOINER } from '../utils/query';

export type Filters = {
  institutions: number[];
  classes: number[];
  start?: Date | null;
  end?: Date | null;
  strategy?: string | null;
};

export const makeFilter = (): Filters => {
  // The earlier of three months ago or the most recent August 1
  const now = new Date();
  // January is month 0
  const mostRecentAugustFirst = (now.getUTCMonth() < 7 ? now.getUTCFullYear() - 1 : now.getUTCFullYear()) + '-08-01';
  // subtracting 2 months, due to January being 0, gives us 3 months ago,
  // which will be 5, 6, 7 (May, June, July) respectively.
  const beginDate = [7, 8, 9].includes(now.getUTCMonth())
    ? now.getUTCFullYear() + '-' + String(now.getUTCMonth() - 2).padStart(2, '0') + '-01'
    : mostRecentAugustFirst;
  return {
    institutions: [],
    classes: [],
    start: new Date(beginDate),
    end: null,
  };
};

export const getModuleUsage = async (filters: Filters, debug: string | null): Promise<GetModuleUsageResponse> => {
  const query = new URLSearchParams();
  if (filters.institutions.length > 0) query.append('institutions', filters.institutions.join(URL_QUERY_ARRAY_JOINER));
  if (filters.classes.length > 0) query.append('classes', filters.classes.join(URL_QUERY_ARRAY_JOINER));
  if (debug) query.append('debug', debug);

  // ISO 8601 date only strings are treated as UTC
  if (filters.start) query.append('start', filters.start.toISOString());
  if (filters.end) query.append('end', filters.end.toISOString());
  if (filters.strategy) query.append('strategy', filters.strategy);
  const response = await makeRequest(routes.getModuleUsage.method, routes.getModuleUsage.path + '?' + query);
  return GetModuleUsageResponseRecord.check(response.data);
};
