// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Text } from '../components/text';
import { resetPasswordFinish, signout } from '../redux/actions/user';
import { Alert } from '../components/Alert';
import { AuthContainer } from '../components/authContainer';
import { Navigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { TextField } from '../components/textField';

import { Button } from '../components/button';

export const ResetPasswordForm: React.FC = () => {
  const params = useParams();
  const { token } = params;
  const { resetPassword } = useSelector((state) => state.user);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState<string | undefined>();
  const dispatch = useDispatch();
  const resetPasswordFn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    dispatch(resetPasswordFinish({ token, password }));
  };
  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
  const onConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setConfirmPassword(event.target.value);
  useEffect(() => {
    dispatch(signout());
  }, []);
  return (
    <AuthContainer title="Enter your new password">
      {!token && <Navigate to="/" replace />}
      {resetPassword === 'success' && <Navigate to="/password-reset/success" replace />}
      <StyledForm onSubmit={resetPasswordFn}>
        {(error || resetPassword === 'failed') && (
          <Alert
            severity="error"
            title={error || 'Your password reset token is expired, please request another password reset email'}
          />
        )}
        <StyledLabel variant="p">Password</StyledLabel>
        <StyledInput placeholder="Password" type="password" value={password} onChange={onPasswordChange} />
        <StyledLabel variant="p">Confirm Password</StyledLabel>
        <StyledInput
          placeholder="Confirm password"
          type="password"
          value={confirmPassword}
          onChange={onConfirmPasswordChange}
        />
        <StyledButton type="submit">Reset Password</StyledButton>
      </StyledForm>
    </AuthContainer>
  );
};

const StyledForm = styled.form({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledLabel = styled(Text)({
  marginBottom: '.5rem',
});

const StyledInput = styled(TextField)({
  marginBottom: '2rem',
});

const StyledButton = styled(Button)({
  width: '100%',
  marginBottom: '2rem',
});
