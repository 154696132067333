// @ts-strict-ignore
import React from 'react';

import { black } from '../../utils/colors';
import { StudentRowDetails } from '../../components/StyledComponents';
import { LicenseCheck } from '../../components/LicenseCheck';
import { Student as StudentItem, Contract } from '../../../types/routes/accountManagement';
import { formatName } from '../../utils/user';

export const StudentRow: React.FC<{
  classId: number;
  student: StudentItem;
  filter: Contract[];
}> = ({ classId, student, filter }) => {
  // Disable delete since we don't have undelete
  return (
    <StudentRowDetails style={{ color: black }}>
      <td>{formatName(student)}</td>
      <td>
        <LicenseCheck classId={classId} student={student} contracts={filter} clickable={true} />
      </td>
    </StudentRowDetails>
  );
};
