import React from 'react';
import { Tooltip, ITooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { charcoal15, white, black } from '../utils/colors';

export const CustomTooltip: React.FC<ITooltip> = ({ children, ...rest }) => {
  // return null;
  const id = React.useId();
  return (
    <Tooltip
      id={id}
      style={{ backgroundColor: `${white}`, color: `${black}`, boxShadow: `0 1px 3px ${charcoal15}`, zIndex: 1000 }}
      delayShow={200}
      delayHide={200}
      {...rest}
    >
      {children}
    </Tooltip>
  );
};
