import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { some } from 'lodash';
import React, { FC } from 'react';
import { primary, red, white } from '../../../utils/colors';
import { StudentStatus, UserWithClassId } from '../types';
import { CustomTooltip as Tooltip } from '../../../components/tooltip';
import { Text } from '../../../components/text';

interface Prop {
  student: UserWithClassId;
  studentStatus: StudentStatus;
}

export const UnreadMessageNotification: FC<Prop> = ({ student, studentStatus }) => {
  const messages = studentStatus.unreadMessages;
  /*
   * Work-around for
   * https://www.notion.so/prismsvr/29294fbcb4cd47f0985472600c43f866
   */
  const critical = some(messages, (message) => message.properties.message.match(/emergency/i));
  const label = `unread-messages-${student.id}`;
  return (
    <Container>
      <UnreadMessages critical={critical} id={label}>
        {messages.length < 10 ? String(messages.length) : '!'}
      </UnreadMessages>
      <Tooltip anchorSelect={'#' + label} place="left">
        <Text variant="nav">
          The student has sent {messages.length > 1 ? messages.length + ' messages' : 'a message'}
        </Text>
      </Tooltip>
    </Container>
  );
};

const notificationRotate = keyframes`
  0% {
    transform: rotate(-10deg);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(-10deg);
  }
`;

const Container = styled.div({
  position: 'absolute',
  right: '-.7rem',
  top: '-.7rem',
  zIndex: 99,
});

const UnreadMessages = styled.div<{ critical?: boolean }>(({ critical }) => ({
  borderRadius: '50%',
  color: white,
  width: '1.5rem',
  height: '1.5rem',
  fontSize: '1.3rem',
  fontFamily: 'Roboto, sans-serif',
  padding: '0.2rem',
  margin: 'auto',
  textAlign: 'center',
  textJustify: 'auto',
  backgroundColor: critical ? red : primary,
  animation: critical ? `${notificationRotate} 1s infinite` : '',
}));
