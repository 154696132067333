import { ClassRecord } from './class';
import { Array, Record, String, Static, Boolean, Null, Unknown, Dictionary, Partial } from 'runtypes';
import { Route } from './utils';

export const externalRoutes = (): { [name: string]: Route } => ({
  getExternalClasses: {
    method: 'get',
    path: '/external/classes',
    requestBody: GetExternalClassesRequestRecord,
    responseData: GetExternalClassesResponseRecord,
  },
  importExternalClass: {
    method: 'post',
    path: '/external/import-class',
    requestBody: ImportExternalClassRequestRecord,
    responseData: ImportExternalClassResponseRecord,
  },
  updateExternalClass: {
    method: 'put',
    path: '/external/update-class/:classId',
    requestBody: Record({}),
    responseData: UpdateExternalClassResponseRecord,
  },
});

const ExternalClassRecord = Record({
  id: String, // external id
  course: String.Or(Null), // Clever-specific
  name: String,
  section: String.Or(Null),
  teachers: Array(String).Or(Null),
  students: Array(String).Or(Null),
  imported: Boolean.Or(Null),
  school: String.Or(Null), // external id of parent institution
  profile: Dictionary(Unknown).Or(Null),
}).And(
  Partial({
    createdAt: String,
  }),
);

export const GetExternalClassesRequestRecord = Record({});
export const GetExternalClassesResponseRecord = Record({
  data: Array(ExternalClassRecord),
  externalSource: String.Or(Null),
  externalInstance: String.Or(Null),
});
export const ImportExternalClassRequestRecord = ExternalClassRecord.And(
  Record({
    externalSource: String.Or(Null),
    externalInstance: String.Or(Null),
  }),
);
export const ImportExternalClassResponseRecord = ClassRecord;
export const UpdateExternalClassResponseRecord = ClassRecord;

export type ExternalClass = Static<typeof ExternalClassRecord>;
export type GetExternalClassesRequest = Static<typeof GetExternalClassesRequestRecord>;
export type GetExternalClassesResponse = Static<typeof GetExternalClassesResponseRecord>;
export type ImportExternalClassRequest = Static<typeof ImportExternalClassRequestRecord>;
export type ImportExternalClassResponse = Static<typeof ImportExternalClassResponseRecord>;
export type UpdateExternalClassResponse = Static<typeof UpdateExternalClassResponseRecord>;
