// @ts-strict-ignore
import styled from '@emotion/styled';
import React, { useEffect, useState, Fragment } from 'react';
import { Dictionary, map, sortBy } from 'lodash';

import { Text } from '../../components/text';
import { evaluationColors, StyledTable, ActionBar, Evaluation } from '../../components/styles';
import { ModuleDefinition, Part, PartType, TaskDefinition } from '../../../types/routes/module';
import { primary } from '../../utils/colors';
import { formatName, sortOrder, StudentTaskEvalDict, studentTaskEvalutationSortOrder } from '../../utils/user';
import { CustomTooltip as Tooltip } from '../../components/tooltip';
import { ExportToCsv } from 'export-to-csv';
import SortLightbulb from '../../assets/icons/sort-lightbulb.svg';
import SortAlpha from '../../assets/icons/sort-alpha.svg';
import { ModuleEventWithUserClass } from '../../../types/models';
import { Class as ClassWithStudents, Student } from '../../../types/routes/class';
import {
  StudentInformationModalData,
  StudentInformationModal,
  defaultStudentInformationModalData,
} from '../../components/StudentInformationModal';
import { classDisplayName } from '../../utils/class';
import { QuizScore, QuizItem, Recommendation } from './QuizScore';

export interface Props {
  currentClass: ClassWithStudents;
  module: ModuleDefinition;
  eventsByStudent: Dictionary<ModuleEventWithUserClass[]>;
  isOpen: boolean;
}

interface StedTaskListItem {
  task: TaskDefinition;
  moduleId: string;
  modulePart: number;
  partType?: string;
}

/*
 * For tasks without "answer submission" log events or tasks
 * with "answer submission" events have "evaluation" = "none"
 * we mark these as color green, tooltip "completed" if there
 * is a "finished" event.
 *
 * A task may have serveral ungraded "answer sumitted" activities
 * but only one correct/incorrect activity.
 */
export const ModuleSummaryTable = ({ currentClass, module, eventsByStudent, isOpen }: Props) => {
  const debugPrint = false;
  const query = new URLSearchParams(window.location.search);
  const debug = query.get('debug') ? true : false;

  const [studentSortByNames, setStudentSortByNames] = useState(false);

  const [startedEventExists, setStartedEventExists] = useState(false);
  const [sted, setSted] = useState<StudentTaskEvalDict>({});
  const [stedTaskList, setStedTaskList] = useState<(StedTaskListItem | QuizItem)[]>([]);

  // use a single tooltip for all popups
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [tooltipAnchorId, setTooltipAnchorId] = useState('tooltip-anchor');
  const [tooltipStats, setTooltipStats] = useState('');

  const eventsByStudentAndModule: Dictionary<ModuleEventWithUserClass[]> = {};
  // From profiling, this is a top cpu user
  if (isOpen) {
    Object.keys(eventsByStudent).forEach((k) => {
      eventsByStudentAndModule[k] = eventsByStudent[k].filter((e) => e.moduleId == module.id);
    });
  }

  if (debugPrint)
    console.log(
      `*** ModuleSummaryTable starting for ${currentClass?.id}, ${module?.id}, ${Object.values(
        eventsByStudentAndModule,
      ).reduce((x, e) => x + e.length, 0)} events`,
    );

  const [studentInformationModalData, setStudentInformationModalData] = useState<StudentInformationModalData>(
    defaultStudentInformationModalData,
  );

  const updateTooltip = (anchorId, stats) => {
    setTooltipAnchorId(anchorId);
    setTooltipStats(stats);
    setTooltipIsOpen(true);
  };

  const closeTooltip = () => {
    setTooltipIsOpen(false);
    setTooltipAnchorId('tooltip-anchor');
  };

  useEffect(() => {
    if (isOpen && Object.values(eventsByStudentAndModule).reduce((x, e) => x + e.length, 0) > 0) {
      if (Object.values(eventsByStudentAndModule).some((ee) => ee.some((e) => e.action === 'started'))) {
        setStartedEventExists(true);
        debugPrint && console.log('Events Updated');
        debugPrint && console.log(eventsByStudentAndModule);
        const newStedTaskList: (StedTaskListItem | QuizItem)[] = [];
        module.parts.forEach((part: Part) => {
          part.tasks.forEach((task) => {
            newStedTaskList.push({ task: task, moduleId: module.id, modulePart: part.modulePart, partType: part.type });
          });
          if ('quizPoints' in part) {
            newStedTaskList.push({ points: part.quizPoints, modulePart: part.modulePart });
          }
        });
        const newSted: StudentTaskEvalDict = {};
        currentClass.students.forEach((student) => {
          newStedTaskList.forEach((t) => {
            if ('task' in t) {
              if (!newSted[student.id]) newSted[student.id] = {};
              newSted[student.id][t.task.id] = evaluateTask(student.id, t.task.id);
            }
          });
        });
        if (newStedTaskList.length > 0 && module.id == 'dna' && false)
          console.log(`*** ${module.id} newStedTaskList ${JSON.stringify(newStedTaskList)}`);
        setStedTaskList(newStedTaskList);
        setSted(newSted);
      }
    }
  }, [eventsByStudent, isOpen]);

  if (stedTaskList.length > 0 && false) console.log(`*** ${module.id} stedTaskList ${stedTaskList.length}`);

  const chosenSortMethod = () => {
    return studentSortByNames ? sortOrder : studentTaskEvalutationSortOrder(sted);
  };

  const evaluateTask = (studentId: number, taskId: string): Evaluation => {
    //   debugPrint && console.log(String(studentId) + " : " + taskId);
    if (eventsByStudentAndModule && studentId in eventsByStudentAndModule) {
      // Avoid creating copies of the event array
      const lastAttemptIndex = eventsByStudentAndModule[studentId].findLastIndex(
        (e) => e.taskId == taskId && e.action == 'started',
      );
      let incorrectCount = 0;
      let completedCount = 0;
      if (lastAttemptIndex > -1) {
        for (let i = lastAttemptIndex; i < eventsByStudentAndModule[studentId].length; i++) {
          const event = eventsByStudentAndModule[studentId][i];
          if (event.taskId != taskId) continue;
          if (event.action == 'answer submitted') {
            if (event.properties.evaluation == 'incorrect') incorrectCount++;
            else if (event.properties.evaluation == 'correct')
              return incorrectCount == 0 ? Evaluation.correct : Evaluation.correctWithDifficulties;
          } else if (event.action == 'finished') {
            completedCount++;
            break;
          }
        }
        if (incorrectCount > 0) return Evaluation.incorrect;
        if (completedCount > 0) return Evaluation.complete;
        return Evaluation.incomplete;
      }
    }
    return Evaluation.notStarted;
  };

  const tipText: { [K in Evaluation]: string } = {
    notStarted: 'Not Attempted',
    incomplete: 'Incomplete',
    incorrect: 'Incorrect',
    correctWithDifficulties: 'Correct With Difficulties',
    complete: 'Completed',
    correct: 'Correct',
    wrongForm: 'Wrong Form',
  };

  const exportModuleSummaryReportCSV = () => {
    const data = sortBy<Student>(currentClass.students, chosenSortMethod()).map((student) => ({
      FirstName: student.firstName,
      LastName: student.lastName,
      username: student.email,
      ...Object.keys(sted[student.id]).reduce(
        (attrs, key) => ({
          ...attrs,
          [key]: sted[student.id][key] ? tipText[sted[student.id][key]] : tipText.notStarted,
        }),
        {},
      ),
    }));
    const classNameString = classDisplayName(currentClass);
    const fileName = (classNameString + '-' + module.id).replace('/s+/g', '-').slice(0, 50);
    const csvExporter = new ExportToCsv({
      filename: fileName,
      useKeysAsHeaders: true,
    });
    csvExporter.generateCsv(data);
  };

  /*
   * If the width is not set explicitly here, the
   * width of the dialog box blows up as you stretch
   * the window.
   */

  if (!isOpen || Object.keys(eventsByStudentAndModule).length == 0) {
    return (
      <>
        <br />
        <Text variant="p">Loading event data for module...</Text>
      </>
    );
  } else {
    if (!startedEventExists || Object.keys(stedTaskList).length === 0) {
      return (
        <>
          <br />
          <Text variant="p">No event summary data is currently available for this module.</Text>
        </>
      );
    } else {
      return (
        <>
          <Tooltip isOpen={tooltipIsOpen} delayShow={0} anchorSelect={'#' + tooltipAnchorId}>
            <Text variant="p">{tipText[tooltipStats]}</Text>
          </Tooltip>
          <Tooltip anchorSelect=".cfu-header">
            <Text variant="nav">Check For Understanding Score</Text>
          </Tooltip>
          <Tooltip anchorSelect=".supplement-header">
            <Text variant="nav">Supplemental activity</Text>
          </Tooltip>
          <ActionBar>
            {debug && (
              <Text variant="nav">
                {module.id};&nbsp; quizzes{' '}
                {module.parts.map((p) => ('quizPoints' in p ? String(p.quizPoints) : '-')).join(', ')};&nbsp; type{' '}
                {module.parts.map((p) => ('type' in p ? p.type : '-')).join(', ')}
              </Text>
            )}
            <Actions>
              <TextButton onClick={exportModuleSummaryReportCSV} variant="nav" color={primary}>
                Export CSV
              </TextButton>
            </Actions>
          </ActionBar>
          <TableContainer>
            <StyledTable>
              <thead>
                <tr>
                  <StickyCorner key="students">
                    <HeadText>
                      <Text variant="nav">Students</Text>
                      <SortIconContainer id="sortIcon" onClick={() => setStudentSortByNames(!studentSortByNames)}>
                        {studentSortByNames ? <SortAlpha /> : <SortLightbulb />}
                      </SortIconContainer>
                      <Tooltip anchorSelect="#sortIcon">
                        <Text variant="p">
                          {studentSortByNames ? 'Sorting Alphabetically' : 'Sorting by Proficiency'}
                        </Text>
                      </Tooltip>
                    </HeadText>
                  </StickyCorner>
                  {map(stedTaskList, (t) => {
                    if ('task' in t) {
                      return (
                        <StickyTop key={t.task.id}>
                          <TaskContainer className={t.partType === PartType.supplement ? 'supplement-header' : null}>
                            <Text key={t.task.id} variant="nav">
                              {t.task.name + (t.partType === PartType.supplement ? '*' : '')}
                            </Text>
                          </TaskContainer>
                        </StickyTop>
                      );
                    } else if ('points' in t) {
                      // Explicit use of "Fragment" with a key value
                      // suppresses a REACT key warning
                      return (
                        <Fragment key={'quiz-' + t.modulePart}>
                          <StickyTop>
                            <HeadText className="cfu-header">
                              <Text variant="nav">CFU Score</Text>
                            </HeadText>
                          </StickyTop>
                          <StickyTop>
                            <HeadText>
                              <Text variant="nav">Recommended Problem Set</Text>
                            </HeadText>
                          </StickyTop>
                        </Fragment>
                      );
                    } else {
                      <Text variant="nav">Data Error</Text>;
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {currentClass.students &&
                  sortBy<Student>(currentClass.students, chosenSortMethod()).map((student: Student) => (
                    <tr key={student.id}>
                      <StickyLeft>
                        <Text variant="nav">
                          {formatName(student) +
                            (debug ? ' ' + student.id + ', Sort ' + chosenSortMethod()[0](student) : '')}
                        </Text>
                      </StickyLeft>
                      {map(stedTaskList, (t) => {
                        if ('task' in t) {
                          const evalt = evaluateTask(student.id, t.task.id);
                          const id = t.task.id + '-' + String(student.id);
                          return (
                            <td key={t.task.id}>
                              <Tasks>
                                <TaskContainer
                                  key={t.task.id}
                                  onMouseEnter={() => updateTooltip(id, evalt)}
                                  onMouseLeave={() => closeTooltip()}
                                >
                                  <Task
                                    evaluation={evalt}
                                    onClick={() => {
                                      if (
                                        student.id in eventsByStudent &&
                                        eventsByStudentAndModule[student.id].length > 0
                                      ) {
                                        setStudentInformationModalData({
                                          open: true,
                                          student,
                                          currentClass,
                                          studentEvents: eventsByStudentAndModule[student.id],
                                          moduleId: t.moduleId,
                                          modulePart: t.modulePart,
                                          taskId: t.task.id,
                                          expectEvents: eventsByStudentAndModule[student.id].length > 0,
                                        });
                                      }
                                    }}
                                    id={id}
                                  />
                                </TaskContainer>
                              </Tasks>
                            </td>
                          );
                        }
                        if ('points' in t) {
                          const id = 'quiz-' + t.modulePart + '-' + String(student.id);
                          // Explicit use of "Fragment" with a key value
                          // suppresses a REACT key warning
                          return (
                            <Fragment key={id}>
                              <td>
                                <QuizScore currentClass={currentClass} student={student} module={module} points={t} />
                              </td>
                              <td>
                                <Recommendation
                                  currentClass={currentClass}
                                  student={student}
                                  module={module}
                                  points={t}
                                />
                              </td>
                            </Fragment>
                          );
                        }
                      })}
                    </tr>
                  ))}
              </tbody>
            </StyledTable>
          </TableContainer>
          <StudentInformationModal
            studentInformationModalData={studentInformationModalData}
            setStudentInformationModalData={setStudentInformationModalData}
          />
        </>
      );
    }
  }
};

const TableContainer = styled.div({
  overflow: 'scroll',
  marginTop: '1rem',
  maxHeight: '70vh',

  table: {
    tableLayout: 'fixed',
    whiteSpace: 'nowrap',
    margin: 0,
  },
});

const Tasks = styled.div({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
});

const TaskContainer = styled.div({
  width: '4rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const Task = styled.div<{ evaluation: Evaluation }>(({ evaluation }) => {
  const color = evaluationColors[evaluation];
  return {
    height: '1rem',
    width: '1rem',
    borderRadius: '2rem',
    border: `2px solid ${color}`,
    backgroundColor: evaluation == Evaluation.notStarted ? 'transparent' : color,
    cursor: 'pointer',
  };
});

const Actions = styled.div({
  // right-align since there is nothing on the left side
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
});

const TextButton = styled(Text)({
  marginRight: '3rem',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
});

const StickyCorner = styled.th({
  position: 'sticky',
  top: 0,
  left: 0,
  background: 'white',
  zIndex: 2,
});

const StickyTop = styled.th({
  position: 'sticky',
  top: 0,
  background: 'white',
  boxShadow: '0 7px 7px -4px rgba(0, 0, 0, 0.4)',
  zIndex: 1,
});

const StickyLeft = styled.th({
  position: 'sticky',
  left: 0,
  background: 'white',
  zIndex: 1,
});

const HeadText = styled.div({
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'end',
});

const SortIconContainer = styled.div({
  marginLeft: '0.5rem',
  marginRight: '0rem',
  cursor: 'pointer',
  color: primary,
});
