import { Array, Record, Number, Static } from 'runtypes';
import { Route } from './utils';

export const classroomRoutes = (): { [name: string]: Route } => ({
  createClassroom: {
    method: 'post',
    path: '/classroom',
    requestBody: ClassroomRequestRecord,
    responseData: ClassroomResponseRecord,
  },
  editClassroom: {
    method: 'put',
    path: '/classroom/:classroomId',
    requestBody: ClassroomRequestRecord,
    responseData: ClassroomResponseRecord,
  },
});

export const ClassroomRequestRecord = Record({
  classIds: Array(Number),
});

export const ClassroomResponseRecord = Record({
  id: Number,
  classIds: Array(Number),
});

export type ClassroomRequest = Static<typeof ClassroomRequestRecord>;
export type ClassroomResponse = Static<typeof ClassroomResponseRecord>;
