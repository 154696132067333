import styled from '@emotion/styled';

import { CompletionCircle } from '../pages/liveClassroom/completionCircle';
import { Button } from './button';
import { Text, textStyle } from './text';
import { charcoal15, primary, red, white, black } from '../utils/colors';

import Information from '../assets/icons/information.svg';

export const LicenseUsageText = styled(Text)({
  marginBottom: '0.5rem',
});

export const InformationButton = styled(Information)({
  height: '1rem',
  right: '1rem',
  top: '1rem',
  cursor: 'pointer',
});

export const LicenseUsageStats = styled('div')({
  border: `1px solid ${charcoal15}`,
  borderRadius: 3,
  margin: '1rem',
  padding: '1rem',
  display: 'inline-block',
});

export const LicenseUsageFlexBox = styled('div')({
  display: 'flex',
});

export const LicenseUsageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '1rem',
});

export const Block = styled.div({
  maxWidth: '30.125rem',
  padding: '1rem 2rem',
  marginBottom: '1rem',
  position: 'relative',
});

export const StyledCompletionCircle = styled(CompletionCircle)({
  marginRight: '1rem',
  flexShrink: 0,
});

export const StyledButton = styled(Button)({
  margin: '1rem 0 1rem',
});

export const RemoveSchool = styled.div({
  fontWeight: 600,
  cursor: 'pointer',
  backgroundColor: red,
  color: white,
  borderRadius: '100%',
  marginLeft: 'auto',
  width: '2rem',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SubjectRowLabel = styled('summary')({
  ...textStyle('md'),
  '&:hover': {
    color: primary,
  },
  letterSpacing: '1px',
  color: black,
});

export const RowDetails = styled('details')({
  border: `1px solid ${charcoal15}`,
  borderRadius: 3,
  margin: '1rem',
  padding: '1rem',
});

export const ClassRowDetails = styled('details')({
  paddingTop: '1rem',
  marginLeft: '1rem',
});

export const StudentRowDetails = styled('tr')({
  color: black,
  ...textStyle('normal'),
});

export const RowSummary = styled('summary')({
  cursor: 'pointer',
  ...textStyle('md'),
  '&:hover': {
    color: primary,
  },
});
