import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { charcoal15, gray, primary, secondary, black, white } from '../utils/colors';
import { PageContainer } from '../components/pageContainer';
import { Header } from '../components/header';
import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';

import { getExternal, importExternal, resetExternalGet, resetExternalImport } from '../redux/actions/class';
import { Alert } from '../components/Alert';
import { LeftNav } from '../components/leftNav';
import { Text } from '../components/text';
import { ActionBar } from '../components/styles';
import Target from '../assets/icons/target.svg';
import { ExternalClass } from '../../types/routes/external';

import { Modal } from '../components/modal';
import { Spinner } from '../components/Spinner';
interface ClassTileProps {
  externalClass: ExternalClass;
  externalSource: string | null;
  externalInstance: string | null;
}

export const ImportClasses: React.FC = () => {
  const externalClasses = useSelector((state) => state.class.externalClasses);
  const externalSource = useSelector((state) => state.class.externalSource);
  const externalInstance = useSelector((state) => state.class.externalInstance);
  // console.log('*** External class list ', externalClasses);
  const navigate = useNavigate();
  const hasError = useSelector((state) => state.class.hasError);
  const errorMessage = useSelector((state) => state.class.errorMessage);
  const fetchingExternalClasses = useSelector((state) => state.class.fetchingExternalClasses);
  const importingExternalClasses = useSelector((state) => state.class.importingExternalClasses);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getExternal());
  }, []);
  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar>
        <Text variant="lg2" color={primary}>
          <StyledTarget />
          Select classes to import
        </Text>
      </ActionBar>
      <Classes>
        {map(externalClasses, (c) => (
          <ClassTile key={c.id} externalClass={c} externalSource={externalSource} externalInstance={externalInstance} />
        ))}
      </Classes>
      {!fetchingExternalClasses && !externalClasses.length && <Alert severity="warning" title="No Available Classes" />}
      {fetchingExternalClasses && !externalClasses?.length && <Spinner label="Getting classes ..." center />}
      <Alert
        isOpen={hasError && !fetchingExternalClasses}
        closeAlert={() => {
          dispatch(resetExternalGet());
          dispatch(resetExternalImport());
          navigate('/class-list');
        }}
        severity="error"
        title="Error"
        blocking={true}
      >
        {errorMessage}
      </Alert>
      <Modal
        isOpen={importingExternalClasses && !fetchingExternalClasses}
        title={'Importing Class'}
        closeModal={() => {}}
      >
        <Spinner size="1.5rem" center />
      </Modal>
    </PageContainer>
  );
};

const StyledTarget = styled(Target)({
  marginRight: '1rem',
});

const Classes = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '1rem 1rem',
});

const ClassTile: React.FC<ClassTileProps> = ({ externalClass, externalSource, externalInstance }) => {
  /*
   * BvdS:  need switch on externalSource value
   */
  // const externalSource = useSelector((state) => state.class.externalSource);
  const debugPrint = false;

  if (debugPrint) {
    console.log('*** class tile for ', externalClass);
  }
  const hasError = useSelector((state) => state.class.hasError);
  const importingExternalClasses = useSelector((state) => state.class.importingExternalClasses);

  // Emilio: this state would be better handled by the redux store.
  // have an state item which is an array of classes that are available to be
  // imported. Note that the useDispatch call would have to be reworked along.
  const [isImported, setImported] = useState(externalClass.imported);
  const dispatch = useDispatch();

  if (debugPrint && false) {
    console.log('*** class tile for ', externalClass);
  }
  const importClass = async () => {
    if (!externalClass.imported) {
      if (debugPrint) {
        console.log('*** importClasses importing ', externalClass.id);
      }
      const addedClass = dispatch(
        importExternal({
          ...externalClass,
          externalSource,
          externalInstance,
        }),
      );
      /*
       * BvdS: need up update externaClass.imported
       * and add class to state.class.classes
       */
      if (debugPrint) {
        console.log('*** added ', addedClass);
      }
      setImported(true);
    }
  };
  return (
    <ClassTileContainer>
      <ClassTileInnerContainer>
        <HeaderText variant="md" center color={black}>
          {externalClass.name}
        </HeaderText>
        {externalClass.section && (
          <SectionText variant="p" center color={gray}>
            Section {externalClass.section}
          </SectionText>
        )}
        {externalClass.students && (
          <Text variant="nav" center color={primary}>
            <b>{externalClass.students.length}</b> Student{externalClass.students.length === 1 ? '' : 's'}
          </Text>
        )}
        {externalClass.teachers && externalClass.teachers.length > 1 && (
          <Text variant="nav" center color={primary}>
            <b>{externalClass.teachers.length}</b> Teachers
          </Text>
        )}
      </ClassTileInnerContainer>
      {isImported ? (
        <PushedUpText variant="nav" center>
          {!importingExternalClasses && !hasError && 'Imported'}
        </PushedUpText>
      ) : (
        <Actions onClick={() => importClass()}>
          <Text variant="nav" center color={white}>
            Import
          </Text>
        </Actions>
      )}
    </ClassTileContainer>
  );
};

const ClassTileContainer = styled.div({
  border: `2px solid ${primary}`,
  borderRadius: 6,
  boxShadow: `0 5px 10px ${charcoal15}`,
  position: 'relative',
  margin: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&:hover': {
    'a:last-of-type': {
      backgroundImage: `linear-gradient(to right, ${primary} 25%, ${secondary} 75%)`,
    },
  },
});

const ClassTileInnerContainer = styled.div({
  textDecoration: 'none',
  padding: '1.25rem 1.25rem 1.25rem',
  width: '21.375rem',
  cursor: 'pointer',
  display: 'block',
  textAlign: 'center',
  flexGrow: 1,
});

const HeaderText = styled(Text)({
  marginBottom: '.5rem',
});

const SectionText = styled(Text)({
  marginBottom: '1rem',
});

const Actions = styled.div({
  backgroundImage: `linear-gradient(to right, ${primary}, ${secondary})`,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  height: '3rem',
  padding: '0 1rem',
  cursor: 'pointer',
});

const PushedUpText = styled(Text)({
  marginBottom: '1rem',
});
