import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { primary } from '../utils/colors';
import { ListSubheader } from '@mui/material';
import { TaskChecklist, TaskChecklistItem } from '../pages/liveClassroom/types';
import { textStyle, Text } from './text';
import styled from '@emotion/styled';
import { CustomTooltip as Tooltip } from './tooltip';

type Props = {
  checklist: TaskChecklist;
};

// July 2024 testing had some items with "text" and "html"
// instead of "description" and "descriptionHtml"
// Add it back in for backwards compatibility.

export const TaskModalChecklist: React.FC<Props> = ({ checklist }) => {
  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 360 }}
        subheader={
          <ListSubheader sx={{ lineHeight: '12px', padding: '2rem 1rem 1rem 1rem' }}>{checklist.title}</ListSubheader>
        }
      >
        {checklist.items.map((item: TaskChecklistItem & { html?: string; text?: string }, i: number) => {
          const labelId = `checkbox-list-label-${item.title}`;
          const description = item.description || item.text;
          const descriptionHtml = item.descriptionHtml || item.html;

          return (
            <>
              <ListItem
                id={`checklist-item-${i}`}
                key={i}
                sx={{ border: '1px black solid', borderRadius: '3px', mb: '5px', p: '0 10px' }}
              >
                <ListItemText
                  sx={{ borderRadius: 2, width: '10%' }}
                  primary={`${i + 1}`}
                  primaryTypographyProps={{ fontSize: '0.8rem' }}
                />
                <ListItemText
                  sx={{ margin: 'auto', borderRadius: 2, width: '80%' }}
                  id={labelId}
                  primary={item.title}
                  primaryTypographyProps={{ fontSize: '0.8rem' }}
                />

                <ListItemIcon sx={{ borderRadius: 2, width: '10%', justifyContent: 'center' }}>
                  {item.evaluation !== 'in-progress' && (
                    <Checkbox
                      edge="start"
                      checked={item.evaluation === 'completed'}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                      sx={{
                        margin: 'auto',
                        color: primary,
                        '&.Mui-checked': {
                          color: primary,
                        },
                      }}
                    />
                  )}

                  {item.evaluation === 'in-progress' && <StyledInProgress>{`. . .`}</StyledInProgress>}
                </ListItemIcon>
              </ListItem>

              <Tooltip anchorSelect={`#checklist-item-${i}`} style={{ maxWidth: 300, zIndex: 999 }}>
                {descriptionHtml ? (
                  <Text variant="sm" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                ) : (
                  <Text variant="sm">{description}</Text>
                )}
              </Tooltip>
            </>
          );
        })}
      </List>
    </>
  );
};

const StyledInProgress = styled.p({
  ...textStyle('p'),
  fontWeight: 1000,
  color: primary,
});
