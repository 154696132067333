import React from 'react';
import styled from '@emotion/styled';
import { TextField as MuiTextField, StandardTextFieldProps } from '@mui/material';

export const TextField = (props: StandardTextFieldProps) => {
  return (
    <>
      <StyledTextField fullWidth inputProps={{ style: { padding: '.5rem 0.75rem' } }} margin={'none'} {...props} />
    </>
  );
};

const StyledTextField = styled(MuiTextField)<StandardTextFieldProps>({
  fontSize: '.875rem',
  // Since we don't have an MUI theme configured, this is to overwrite the
  // default coloring behavior for the TextField component
  '&:focus': {
    borderColor: '#21232533',
    outline: 0,
    boxShadow: 'none',
  },
  '& fieldset': { border: '1px solid #21232533' },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid #21232533',
    },
    '&:hover fieldset': {
      border: '1px solid #21232533',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #21232533',
    },
  },
});
