// @ts-strict-ignore
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { SubjectRowLabel, RowDetails } from '../components/StyledComponents';

import { Class as ClassWithStudents, Teacher } from '../../types/routes/class';
import { Alert, ExtraAlertText } from '../components/Alert';
import { resetCreate, resetEdit, resetRemove } from '../redux/actions/class';
import { configPublic } from '../../config-public';
import { makeFilter } from '../api/adminAnalytics';
import { Spinner } from './Spinner';

export interface ClassLeafProps {
  classData: ClassWithStudents;
  leafData: any;
  date?: boolean;
}

// Get default start of academic year used by filters
export const RecentClass = () => {
  const filter = makeFilter();
  return (c: ClassWithStudents): boolean => c.createdAt > filter.start || (c.endAt && c.endAt > filter.start);
};

export const ClassList = ({
  emptyListMessage,
  excludeClasses,
  leafData,
  LeafComponent,
}: {
  emptyListMessage: string;
  excludeClasses: Array<number>;
  leafData: any;
  LeafComponent: React.FC<ClassLeafProps>;
}) => {
  const classesState = useSelector((state) => state.class.classes);
  const userId = useSelector((state) => state.user.id);
  const fetchingClasses = useSelector((state) => state.class.fetchingClasses);
  const hasError = useSelector((state) => state.class.hasError);
  const errorMessage = useSelector((state) => state.class.errorMessage);
  const dispatch = useDispatch();

  const [classes, setClasses] = useState<ClassWithStudents[]>([]);

  useEffect(() => {
    setClasses(
      excludeClasses.length > 0 ? classesState.filter((c) => excludeClasses.some((x) => x != c.id)) : classesState,
    );
  }, [classesState, fetchingClasses]);

  // Use localStorage instead of REACT state mechanism
  const statePath = 'class-list/archive';
  const state = JSON.parse(window.localStorage.getItem(statePath)) || {
    open: false,
  };
  const clickHandler = () => {
    state.open = !state.open;
    window.localStorage.setItem(statePath, JSON.stringify(state));
  };

  const recentClass = RecentClass();

  return (
    <>
      {!fetchingClasses || classes?.length ? (
        <>
          <Classes>
            {classes
              .filter((c) => c.teachers.some((u) => u.id == userId) && recentClass(c))
              .map((c) => (
                <LeafComponent key={c.id} classData={c} date={false} leafData={leafData} />
              ))}
          </Classes>
          {!fetchingClasses && !classes.length && <Alert severity="success">{emptyListMessage}</Alert>}
          {classes.filter((c) => c.teachers.some((u: Teacher) => u.id == userId)).length > 0 && (
            <RowDetails {...(state.open ? { open: true } : {})}>
              <SubjectRowLabel onClick={clickHandler}>Class Archive</SubjectRowLabel>
              <Classes>
                {classes
                  .filter((c) => c.teachers.some((u: Teacher) => u.id == userId) && !recentClass(c))
                  .map((c) => (
                    <LeafComponent key={c.id} classData={c} date={true} leafData={leafData} />
                  ))}
              </Classes>
            </RowDetails>
          )}
        </>
      ) : (
        <Spinner label="Getting classes ..." center />
      )}
      <Alert
        isOpen={hasError && !fetchingClasses}
        closeAlert={() => {
          dispatch(resetCreate());
          dispatch(resetEdit());
          dispatch(resetRemove());
        }}
        severity="error"
        title="Error Retrieving Class Data"
        blocking={true}
      >
        {'Class data could not be retrieved.  This could be due to a loss of network connection.  Please send a screenshot to ' +
          configPublic.supportEmail.staff +
          '.'}
        {errorMessage && <ExtraAlertText>Error message: {errorMessage}</ExtraAlertText>}
      </Alert>
    </>
  );
};

const Classes = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '1rem 1rem',
});
