import { Record, Static, Number, String, Union, Boolean, Literal, Array, Partial, Undefined } from 'runtypes';
import { Route, route } from './utils';

export const adminRoutes = (): { [name: string]: Route } => ({
  getLtiPlatforms: {
    method: 'get',
    path: '/lti-platform/getAll',
    requestBody: GetLtiPlatformsRequestRecord,
    responseData: GetLtiPlatformsResponseRecord,
  },

  registerLtiPlatform: {
    method: 'put',
    path: '/lti-platform/register',
    requestBody: RegisterLtiPlatformRequestRecord,
    responseData: RegisterLtiPlatformResponseRecord,
  },

  updateLtiPlatform: {
    method: 'put',
    path: '/lti-platform/id/:id',
    requestBody: UpdateLtiPlatformRecord,
    responseData: UpdateLtiPlatformRecord,
  },

  contractRepairLicenses: route({
    method: 'patch',
    path: '/contract/repair-license-counts',
    requestBody: ContractRepairLicensesRequestRecord,
    responseData: UpdateCountRecord,
  }),

  institutionUpdateLicenses: route({
    method: 'patch',
    path: '/institution/update-licenses',
    requestBody: InstitutionUpdateLicensesRequestRecord,
    responseData: UpdateCountRecord,
  }),

  contractUnreserveLicenses: route({
    method: 'patch',
    path: '/contract/:contractId/unreserve-licenses',
    requestBody: ContractUnreserveLicensesRequestRecord,
    responseData: UpdateCountRecord,
  }),

  classUpdateLicenses: route({
    method: 'patch',
    path: '/class/update-licenses',
    requestBody: ClassUpdateLicensesRequestRecord,
    responseData: UpdateCountRecord,
  }),

  cleanup: route({
    method: 'patch',
    path: '/cleanup',
    requestBody: Record({ secret: String }),
    responseData: Undefined,
  }),

  deidentifyStudents: route({
    method: 'patch',
    path: '/institution/deidentify-students',
    requestBody: DeidentifyStudentsRequestRecord,
    responseData: DeidentifyStudentsResponseRecord,
  }),

  generateVitallyData: {
    method: 'post',
    path: '/vitally',
    requestBody: Record({}),
    responseData: Record({}),
  },
});

// From https://cvmcosta.me/ltijs/#/provider?id=async-providerregisterplatformplatform
// This should match PlatformConfig in types/ltijs.d.ts
const ltiRequest = {
  url: String,
  name: String,
  clientId: String,
  authenticationEndpoint: String,
  accesstokenEndpoint: String,
  authConfig: Record({
    method: Union(Literal('RSA_KEY'), Literal('JWK_KEY'), Literal('JWK_SET')),
    key: String,
  }),
};
export const RegisterLtiPlatformRequestRecord = Record(ltiRequest);

// This should match PlatformParameters in types/ltijs.d.ts
export const RegisterLtiPlatformResponseRecord = Record({
  ...ltiRequest,
  id: String,
  authorizationServer: String,
  publicKey: String,
  active: Boolean,
});

export const GetLtiPlatformsRequestRecord = Record({});

export const GetLtiPlatformsResponseRecord = Array(RegisterLtiPlatformResponseRecord);

export const UpdateLtiPlatformRecord = Record({
  active: Boolean,
});

export const ContractRepairLicensesRequestRecord = Record({
  contractId: Number,
  secret: String,
});

export const ContractUnreserveLicensesRequestRecord = Record({
  secret: String,
});

export const InstitutionUpdateLicensesRequestRecord = Record({
  institutionId: Number,
  secret: String,
});

export const ClassUpdateLicensesRequestRecord = Record({
  classId: Number,
  secret: String,
});

const UpdateCountRecord = Record({
  updated: Number,
  updateFailed: Number,
}).And(Partial({ message: String }));

const DeidentifyStudentsRequestRecord = Record({
  institutionId: Number,
  dryRun: Boolean,
});

const DeidentifyStudentsResponseRecord = Record({
  updated: Array(String),
  message: String,
});

// Vitally typing
const VitallyPropertiesRecord = Record({
  count: Number,
  interval: Number,
});

const VitallyModulesUsedRecord = Record({
  id: String,
  name: String,
});

const VitallyModulesUsedListRecord = Record({
  modules: Array(VitallyModulesUsedRecord),
});
// TODO modules-used record:
/*
"modules": [
     {"id": "linear", "name": "Linear Functions"}, 
     {"id": "quadratics", "name": "Quadratics"}, 
     {"id": "tutorial", "name": "Tutorial"}
   ]
*/

const VitallyTrackRecord = Record({
  type: String,
  accountId: Number.Or(Undefined), // school id
  organizationId: Number, // district id
  event: String,
  properties: VitallyPropertiesRecord.Or(VitallyModulesUsedListRecord).Or(Undefined), // TODO expand for all events
  messageId: String, //ex <school-institutionId>:contract-licenses:2024-05-09:7
  timestamp: String,
});

const VitallyClientTraitsRecord = Record({
  name: String,
});

// Client can be school (Vitally account) or district ( Vitally organization)
const VitallyClientRecord = Record({
  traits: VitallyClientTraitsRecord,
  type: String,
  externalId: Number,
  accountId: Number.Or(Undefined),
  organizationId: Number.Or(Undefined),
  messageId: String, //ex <school-institutionId>:contract-licenses:2024-05-09:7
  timestamp: String,
});

/*
  Event types
  "contract-licenses" is the total number of licenses provided in any applicable contract during the specified time period.  For schools with district contracts, subtract the licenses reserved/consumed by other schools in the district.
  "students-rostered" is the number of students with a license that is applicable during the specified time period.  This is a subset of "contract-licenses".
  "students-ever-active" is the subset of "students-rostered" who are "active" (as defined above) at any time before or during the specified time interval, under the same license.
  "students-active" is the subset of "students-rostered" who are "active" (as defined above) during the specified time interval.
  "teachers-rostered" is the number of teachers associated with at least one class containing a student in "students-rostered".
  "teachers-ever-active" is the number of teachers associated with at least one class containing a student in "students-ever-active"
  "teachers-active" is the number of teachers associated with at least one class containing a student in "students-active"
  "modules-used" an array of modules used, with format:
  "modules": [
      {"id": "linear", "name": "Linear Functions"}, 
      {"id": "quadratics", "name": "Quadratics"}, 
      {"id": "tutorial", "name": "Tutorial"}
    ]
*/
export const VitallyEvents = [
  'contract-licenses',
  'students-rostered',
  'students-ever-active',
  'students-active',
  'teachers-rostered',
  'teachers-ever-active',
  'teachers-active',
  'modules-used',
] as const;

export const GetVitallyClientRequestRecord = Record({
  data: Array(VitallyClientRecord),
});
export const GetVitallyTrackRequestRecord = Record({
  data: Array(VitallyTrackRecord),
});

export type VitallyClientRecord = Static<typeof VitallyClientRecord>;
export type VitallyTrackRecord = Static<typeof VitallyTrackRecord>;
export type GetVitallyClientRequest = Static<typeof GetVitallyClientRequestRecord>;
export type GetVitallyTrackRequest = Static<typeof GetVitallyTrackRequestRecord>;

export type GetLtiPlatformsRequest = Static<typeof GetLtiPlatformsRequestRecord>;
export type GetLtiPlatformsResponse = Static<typeof GetLtiPlatformsResponseRecord>;
export type RegisterLtiPlatformRequest = Static<typeof RegisterLtiPlatformRequestRecord>;
export type RegisterLtiPlatformResponse = Static<typeof RegisterLtiPlatformResponseRecord>;
export type UpdateLtiPlatform = Static<typeof UpdateLtiPlatformRecord>;
export type UpdateCount = Static<typeof UpdateCountRecord>;
export type DeidentifyStudentsResponse = Static<typeof DeidentifyStudentsResponseRecord>;
