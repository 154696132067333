import { Record, Literal, Array, Runtype } from 'runtypes';

export type RouteMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

export interface Route<Req = any, Res = Runtype | Record<any, any> | Literal<any> | Array<any, any>> {
  method: RouteMethod;
  path: string;
  requestBody: Req;
  responseData: Res;
}

/**
 * Convenience method to create a route: all it does is just
 * pass the route back to the caller. This is only useful because
 * it makes it easy to use routes in ad-hoc types, e.g.:
 *
 *   const myRoutes = {
 *     foo: route({ ... }),
 *     bar: route({ ... }),
 *   }
 *
 * The above allows us to refer to `myRoutes.foo` in a type-safe way.
 */
export function route<Req, Res>(route: Route<Req, Res>): Route<Req, Res> {
  return route;
}
