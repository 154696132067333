// @ts-strict-ignore
import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Button } from '../components/button';
import { PageContainer } from '../components/pageContainer';
import { Header } from '../components/header';
import { ClassLeafProps, ClassList } from '../components/ClassList';

import { Class as ClassWithStudents } from '../../types/routes/class';
import { primary, gray, black, secondary, charcoal15 } from '../utils/colors';
import { Link } from 'react-router-dom';
import { LeftNav } from '../components/leftNav';
import Target from '../assets/icons/target.svg';
import { Text } from '../components/text';
import { ActionBar } from '../components/styles';
import { makeFilter } from '../api/adminAnalytics';
import { classDisplayName } from '../utils/class';

// Get default start of academic year used by filters
export const RecentClass = () => {
  const filter = makeFilter();
  return (c: ClassWithStudents): boolean => c.createdAt > filter.start || (c.endAt && c.endAt > filter.start);
};

export const ClassListPage: React.FC = () => {
  const external: boolean = useSelector((state) => (state.user.externalSource ? true : false));

  const emptyListMessage =
    'Welcome!  Click the "Create a Class"' + (external ? ' or "Import a class"' : '') + ' button above to get started.';
  return (
    <PageContainer header={<Header />} leftNav={<LeftNav />}>
      <ActionBar>
        <Text variant="lg2" color={primary}>
          <StyledTarget />
          Class Overview
        </Text>
        <ButtonContainer>
          {external && <StyledButton to="/import-classes">Import a class</StyledButton>}
          <StyledButton data-cy="class-list-create-class-button" to="/create-class">
            Create a class
          </StyledButton>
        </ButtonContainer>
      </ActionBar>
      <ClassList emptyListMessage={emptyListMessage} excludeClasses={[]} leafData={null} LeafComponent={ClassTile} />
    </PageContainer>
  );
};

const ButtonContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const StyledButton = styled(Button)({
  marginLeft: '1rem',
});

const StyledTarget = styled(Target)({
  marginRight: '1rem',
});

const ClassTile: React.FC<ClassLeafProps> = ({ classData, date }) => {
  return (
    <ClassTileContainer>
      <ClassTileInnerContainer to={`/class/${classData.id}`}>
        <HeaderText variant="md" center color={black}>
          {(date ? classData.createdAt.toLocaleString('default', { year: 'numeric', month: 'short' }) + ':  ' : '') +
            classDisplayName(classData)}
        </HeaderText>
        {classData.section && (
          <SectionText variant="p" center color={gray}>
            Section {classData.section}
          </SectionText>
        )}
        <Text variant="nav" center color={primary}>
          <b>{classData.students.length}</b> Student{classData.students.length === 1 ? '' : 's'}
        </Text>
      </ClassTileInnerContainer>
      <Actions to={`/class/${classData.id}`}></Actions>
    </ClassTileContainer>
  );
};

const ClassTileContainer = styled.div({
  border: `2px solid ${primary}`,
  borderRadius: 6,
  boxShadow: `0 5px 10px ${charcoal15}`,
  position: 'relative',
  margin: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '&:hover': {
    'a:last-of-type': {
      backgroundImage: `linear-gradient(to right, ${primary} 25%, ${secondary} 75%)`,
    },
  },
});

const ClassTileInnerContainer = styled(Link)({
  textDecoration: 'none',
  padding: '1.25rem 1.25rem 1.25rem',
  width: '21.375rem',
  cursor: 'pointer',
  display: 'block',
  textAlign: 'center',
  flexGrow: 1,
});

const HeaderText = styled(Text)({
  marginBottom: '.5rem',
});

const SectionText = styled(Text)({
  marginBottom: '1rem',
});

const Actions = styled(Link)({
  backgroundImage: `linear-gradient(to right, ${primary}, ${secondary})`,
  height: '3rem',
  padding: '0 1rem',
});
