import styled from '@emotion/styled';
import React, { FC } from 'react';
import { MoonLoader } from 'react-spinners';
import { Text } from './text';

export const AuthOverlay: FC<{ text?: string }> = ({ text }) => {
  return (
    <Overlay>
      <SpinnerContainer>
        <MoonLoader />
      </SpinnerContainer>
      <Text variant="lg3" data-testid="authenticating">
        {text || 'Authenticating'} &hellip;
      </Text>
    </Overlay>
  );
};

const Overlay = styled.div({
  backgroundColor: 'rgba(0, 0, 0, .5)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
});

const SpinnerContainer = styled.div({
  marginBottom: '1rem',
});
