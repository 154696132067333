import React, { useState } from 'react';
import { primary, white } from '../utils/colors';
import styled from '@emotion/styled';
import { Text } from './text';
import { Record, Static, String, Number } from 'runtypes';
import ChevronIcon from '../assets/icons/chevron.svg';

export const DropdownMenuItemRecord = Record({ key: Number, value: String, name: String });
export type DropdownMenuItem = Static<typeof DropdownMenuItemRecord>;

interface Props {
  itemArray: DropdownMenuItem[];
  selectedItem?: DropdownMenuItem;
  setSelectedItem: (item: DropdownMenuItem) => void;
  children?: React.ReactNode;
}

export const DropdownMenu: React.FC<Props> = ({ itemArray, selectedItem, setSelectedItem, children, ...rest }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const setAndClose = (item: DropdownMenuItem) => {
    setSelectedItem(item);
    setMenuOpen(false);
  };

  const isSelectedOption = (item: DropdownMenuItem): boolean => {
    return !!selectedItem && selectedItem?.key === item.key;
  };

  return (
    <StyledDropdownOptionsSelect
      {...rest}
      onClick={() => setMenuOpen(!menuOpen)}
      onMouseLeave={() => setMenuOpen(false)}
    >
      <div onClick={() => setMenuOpen(!menuOpen)}>{children}</div>
      {menuOpen && (
        <StyledDropdownOptionsContainer>
          {itemArray.map((currentItem: DropdownMenuItem) => (
            <StyledDropdownOption
              key={currentItem.key}
              selected={isSelectedOption(currentItem)}
              onClick={() => setAndClose(currentItem)}
            >
              <Text variant="md">{currentItem.name}</Text>
            </StyledDropdownOption>
          ))}
        </StyledDropdownOptionsContainer>
      )}
      <StyledChevron>
        <ChevronIcon />
      </StyledChevron>
    </StyledDropdownOptionsSelect>
  );
};

const StyledChevron = styled.div({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  transform: 'rotate(90deg)',
});
const StyledDropdownOptionsSelect = styled.div({
  width: '100%',
  position: 'relative',
});

const StyledDropdownOptionsContainer = styled.div({
  position: 'absolute',
  background: white,
  marginLeft: '-1px',
  border: '1px solid black',
  borderRadius: '0 0.5rem 0.5rem 0.5rem',
  padding: '0.5rem 0',
  zIndex: 1,
  width: '100%',
});

const StyledDropdownOption = styled.div<{ selected?: boolean }>(
  {
    padding: '0.3rem 0rem',
    '&:hover': {
      color: primary,
    },
  },
  ({ selected }) =>
    selected
      ? {
          color: primary,
        }
      : {},
);
