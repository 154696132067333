// @ts-strict-ignore
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { InstitutionContracts, InstitutionLicenseUsage, Contract } from '../../../types/routes/accountManagement';
import { ClassContracts } from '../../../types/routes/class';
import {
  apiGetRootInstitutions,
  apiGetInstitutionChildren,
  apiGetInstitution,
  apiGetInstitutionClassesWithTimes,
  apiGetClassWithTimes,
  apiGetInstitutionClassesWithUsers,
  apiGetInstitutionLicenseUsage,
  apiGetInstitutionContracts,
} from '../../api/accountManagement';
import { getContracts as getClassContracts } from '../../api/class';
import { RejectedPayload } from './class';

export const getRootInstitutionsAction = createAsyncThunk(
  'accountManagement/getRootInstitutions',
  async (debug: string | null) => {
    const result = await apiGetRootInstitutions();
    if (result.timing && result.timing.length > 0 && debug) console.log('+++ root institutions timing', result.timing);
    return result;
  },
);

export const getInstitutionChildrenAction = createAsyncThunk(
  'accountManagement/institution/children',
  async ({ id, timing }: { id: number; timing: boolean }) => {
    const result = await apiGetInstitutionChildren(id);
    if (result.timing && result.timing.length > 0 && timing)
      console.log(`+++ institution ${id} children timing`, result.timing);
    return result;
  },
);

export const getInstitutionAction = createAsyncThunk(
  'accountManagement/getInstitution',
  async (institutionId: number) => {
    const result = await apiGetInstitution(institutionId);
    return result;
  },
);

export const getInstitutionClassesWithTimesAction = createAsyncThunk(
  'accountManagement/institution/classesWithTimes',
  async ({ id, timing }: { id: number; timing: boolean }) => {
    const result = await apiGetInstitutionClassesWithTimes(id);
    if (result.timing && result.timing.length > 0 && timing)
      console.log(`+++ institution ${id} classes timing`, result.timing);
    return result;
  },
);
export const getClassWithTimesAction = createAsyncThunk('accountManagement/classWithTimes', async (id: number) => {
  const result = await apiGetClassWithTimes(id);
  return result;
});

export const getInstitutionClassesWithUsersAction = createAsyncThunk(
  'accountManagement/institution/classesWithStudents',
  async ({ id, timing }: { id: number; timing: boolean }) => {
    const result = await apiGetInstitutionClassesWithUsers(id);
    if (result.timing && result.timing.length > 0 && timing)
      console.log(`+++ institution ${id} classes with children timing`, result.timing);
    return result;
  },
);

export const getInstitutionContractsAction = createAsyncThunk<
  InstitutionContracts,
  number,
  {
    rejectValue: RejectedPayload;
  }
>('accountManagement/institution/contracts', async (institutionId: number, { rejectWithValue }) => {
  try {
    return await apiGetInstitutionContracts(institutionId);
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error);
  }
});

export const resetGetInstitutionLicenseUsageAction = createAction('accountManagement/institutions/license-usage/reset');
export const getInstitutionLicenseUsageAction = createAsyncThunk<
  InstitutionLicenseUsage,
  { institutionId: number; filter: Contract[] },
  {
    rejectValue: RejectedPayload;
  }
>('accountManagement/institution/license-usage/get', async ({ institutionId, filter }, { rejectWithValue }) => {
  try {
    const result = await apiGetInstitutionLicenseUsage(institutionId, filter);
    return result;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error);
  }
});

export const getClassContractsAction = createAsyncThunk<
  ClassContracts,
  number,
  {
    rejectValue: RejectedPayload;
  }
>('accountManagement/class/contracts', async (classId: number, { rejectWithValue }) => {
  try {
    return await getClassContracts(classId);
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error);
  }
});
