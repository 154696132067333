import React from 'react';
import styled from '@emotion/styled';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Text } from '../../components/text';
import { black, charcoal15, primary, secondary } from '../../utils/colors';

interface Props {
  completionPercentage: number;
  text: string;
}

export const CompletionCircle: React.FC<Props> = ({ completionPercentage, text, ...rest }) => {
  return (
    <Container {...rest}>
      <StyledCircularProgressbar value={completionPercentage} />
      <StyledGradient>
        <defs>
          <linearGradient id="circle-gradient" gradientTransform={'rotate(0)'}>
            <stop offset="0%" stopColor={primary} />
            <stop offset="100%" stopColor={secondary} />
          </linearGradient>
        </defs>
      </StyledGradient>
      <Text variant="lg2" color={black}>
        {Math.round(completionPercentage)}%
      </Text>
      <AverageCompletionText variant="sm" color={black}>
        {text}
      </AverageCompletionText>
    </Container>
  );
};

const Container = styled.div({
  width: '8.75rem',
  height: '8.75rem',
  // borderRadius: '100%',
  // border: `.75rem solid ${charcoal15}`,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  // marginRight: '8rem',
});

const StyledCircularProgressbar = styled(CircularProgressbar)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  '.CircularProgressbar-trail': {
    stroke: charcoal15,
  },
  '.CircularProgressbar-path': {
    stroke: 'url(#circle-gradient)',
  },
});

const StyledGradient = styled.svg({
  position: 'absolute',
});

const AverageCompletionText = styled(Text)({
  textAlign: 'center',
  width: '5.5rem',
});
