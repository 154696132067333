import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  sendSessionMessage as apiSendSessionMessage,
  sendSessionMessageClass as apiSendSessionMessageClass,
} from '../../api/session';
import { PostSessionMessageRequest } from '../../../types/routes/session';

export const sendSessionMessage = createAsyncThunk('sessions/message/post', async (data: PostSessionMessageRequest) => {
  return apiSendSessionMessage(data);
});

export const sendSessionMessageClass = createAsyncThunk(
  'sessions/message-class/post',
  async ({ classId, message }: { classId: number; message: string }) => {
    return apiSendSessionMessageClass({ classId, message });
  },
);
