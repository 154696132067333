// @ts-strict-ignore
import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from '@emotion/styled/base';

import { primary, secondaryBright, white, black } from '../../../utils/colors';
import { displayName } from '../../../utils/institution';

import { Text } from '../../../components/text';
import { Filters } from '../../../api/adminAnalytics';
import { getInstitutionAction, getClassWithTimesAction } from '../../../redux/actions/accountManagement';
import { classDisplayName } from '../../../utils/class';

export const ActiveFilters: FC<{ filters: Filters }> = ({ filters }) => {
  const debugPrint = false;
  const dispatch = useDispatch();
  const childInstitutions = useSelector((state) => state.accountManagement.institutions);
  const fetchingInstitution = useSelector((state) => state.accountManagement.fetchingInstitution);
  const rootInstitutions = useSelector((state) => state.accountManagement.rootInstitutions);
  const classesWithTimes = useSelector((state) => state.accountManagement.classesWithTimes);
  const fetchingClassWithTimes = useSelector((state) => state.accountManagement.fetchingClassWithTimes);
  const noInstitutions: number[] = [];
  const institutions = filters.institutions
    .map((id) => {
      const result = id in childInstitutions ? childInstitutions[id] : rootInstitutions.find((x) => x.id == id);
      if (!result) noInstitutions.push(id);
      return result;
    })
    .filter((x) => x);
  const noClasses: number[] = [];
  const classes = filters.classes
    .map((id) => {
      const result = classesWithTimes[id];
      if (!result) noClasses.push(id);
      return result;
    })
    .filter((x) => x);

  // Fetch one at a time.
  useEffect(() => {
    if (!fetchingInstitution && noInstitutions.length > 0) {
      if (debugPrint) console.log(`*** Active Filters fetching institution ${noInstitutions[0]}`);
      dispatch(getInstitutionAction(noInstitutions[0]));
    }
  }, [fetchingInstitution, filters]);

  // Fetch one at a time.
  useEffect(() => {
    if (!fetchingClassWithTimes && noClasses.length > 0) {
      if (debugPrint) console.log(`*** Active Filters fetching class ${noClasses[0]}`);
      dispatch(getClassWithTimesAction(noClasses[0]));
    }
  }, [fetchingClassWithTimes, filters]);

  const borderColor = (level: string | null): string => {
    const colors = {
      district: primary,
      school: 'transparent',
      class: 'transparent',
    };
    return level in colors ? colors[level] : black;
  };

  const backgroundColor = (level: string | null): string => {
    const colors = {
      district: 'transparent',
      school: primary,
      class: secondaryBright,
    };
    return level in colors ? colors[level] : 'transparent';
  };

  const textColor = (level: string | null): string => {
    const colors = {
      district: primary,
      school: white,
      class: white,
    };
    return level in colors ? colors[level] : black;
  };

  return (
    <ActiveFiltersContainer>
      {filters && filters.institutions.length + filters.classes.length > 0 && filters.start && (
        <ActiveFilter>
          <Text variant="p">Start {filters.start.toISOString().substring(0, 10)}</Text>
        </ActiveFilter>
      )}
      {filters && filters.end && (
        <ActiveFilter>
          <Text variant="p">End {filters.end.toISOString().substring(0, 10)}</Text>
        </ActiveFilter>
      )}
      {filters &&
        institutions.map((activeFilter) => (
          <ActiveFilter
            key={`institution-${activeFilter.id}`}
            style={{
              borderColor: borderColor(activeFilter.level),
              backgroundColor: backgroundColor(activeFilter.level),
            }}
            title={activeFilter.level}
          >
            <Text color={textColor(activeFilter.level)} variant={'p'}>
              {displayName(activeFilter)}
            </Text>
          </ActiveFilter>
        ))}
      {filters &&
        classes.map((activeFilter) => (
          <ActiveFilter
            key={`institution-${activeFilter.id}`}
            style={{
              borderColor: borderColor('class'),
              backgroundColor: backgroundColor('class'),
            }}
            title={'class'}
          >
            <Text color={textColor('class')} variant={'p'}>
              {classDisplayName(activeFilter, { section: true })}
            </Text>
          </ActiveFilter>
        ))}
    </ActiveFiltersContainer>
  );
};

const ActiveFiltersContainer = styled('div')({
  display: 'flex',
  // maxWidth: '50rem',
  flexWrap: 'wrap',
  // overflowX: 'auto',
  marginLeft: '0.2rem',
  marginRight: '0.2rem',
});

const ActiveFilter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '0.25rem 0.5rem 0.25rem 0.5rem',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: primary,
  borderRadius: '0.5rem',
  margin: '0.1rem 0.2rem 0.1rem 0.2rem',
  whiteSpace: 'nowrap',
});
