import React from 'react';

import { TableRow, TableCell, TableHead } from '@mui/material';

export const TeacherTableHeader: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Teacher Name</TableCell>
        <TableCell>Username</TableCell>
        <TableCell>Password</TableCell>
      </TableRow>
    </TableHead>
  );
};
