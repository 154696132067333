// @ts-strict-ignore
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import user, { State as UserState } from './reducers/user';
import classReducer, { State as ClassState } from './reducers/class';
import adminReducer, { State as AdminState } from './reducers/admin';
import adminAnalyticsReducer, { State as AdminAnalyticsState } from './reducers/adminAnalytics';
import module, { State as ModuleState } from './reducers/module';
import { DefaultRootState } from 'react-redux';
import { accountManagementReducer, AccountManagementState } from './reducers/accountManagement';

export interface APIState<T> {
  data: T;
  hasError: boolean;
  errorMessage: string | null;
  fetching: boolean;
}

/**
 * Augmenting react-redux's definition of the default root state allows us to
 * easily use hooks like `useSelector` without having to explicitly tell it
 * the type of our state.
 */
declare module 'react-redux' {
  export interface DefaultRootState {
    user: UserState;
    class: ClassState;
    admin: AdminState;
    adminAnalytics: AdminAnalyticsState;
    module: ModuleState;
    accountManagement: AccountManagementState;
  }
}

const reducers = {
  user,
  module,
  class: classReducer,
  admin: adminReducer,
  adminAnalytics: adminAnalyticsReducer,
  accountManagement: accountManagementReducer,
};

const allReducers = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === 'users/signout/fulfilled') {
    state = undefined;
  }
  return allReducers(state, action);
};

export const store = configureStore<DefaultRootState>({
  reducer: rootReducer,
  middleware: [thunkMiddleware],
});
