// @ts-strict-ignore
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { Text } from '../../components/text';
import { black, charcoal15, primary } from '../../utils/colors';
import { moduleListStyles } from '../moduleList';
import { Institution, Contract } from '../../../types/routes/accountManagement';
import { getInstitutionLicenseUsageAction, getInstitutionContractsAction } from '../../redux/actions/accountManagement';
import { INCLUDE_RESERVED } from './util';
import { displayName } from '../../utils/institution';

export const InstitutionTile: React.FC<{
  institution: Institution;
}> = ({ institution }) => {
  const debugPrint = false;
  const dispatch = useDispatch();
  const query = new URLSearchParams(window.location.search);
  const verify = query.get('verify');

  // Currently active contracts
  const institutionContracts = useSelector((s) => s.accountManagement.institutionContracts[institution.id]);

  const institutionLicenseUsage = useSelector((s) => s.accountManagement.institutionLicenseUsage[institution.id]);
  useEffect(() => {
    if ((debugPrint || verify) && !institutionLicenseUsage && institutionContracts)
      dispatch(getInstitutionLicenseUsageAction({ institutionId: institution.id, filter: institutionContracts }));
  }, [institutionContracts]);
  const debugUsage = debugPrint || verify ? institutionLicenseUsage : null;

  useEffect(() => {
    if (!institutionContracts) dispatch(getInstitutionContractsAction(institution.id));
  }, []);

  // Filter for currently active contracts
  const now = new Date();
  const licenseUsage: { issued: number; reserved: number; consumed: number; unreserved: number } = institutionContracts
    ? institutionContracts
        .filter((x: Contract) => x.contractStartDate < now && (!x.contractEndDate || now < x.contractEndDate))
        .reduce(
          (x, y) => ({
            issued: x.issued + y.licensesIssued,
            reserved: x.reserved + y.reserved,
            consumed: x.consumed + y.consumed,
            unreserved: x.unreserved + y.unreserved,
          }),
          { issued: 0, reserved: 0, consumed: 0, unreserved: 0 },
        )
    : null;

  return (
    <Institution>
      <InstitutionContent to={`/admin/licenses/institution/${institution.id}`}>
        <Text variant="sm" center color={black}>
          {institution.level ? institution.level[0].toUpperCase() + institution.level.slice(1) : ' '}
        </Text>
        <Title variant="md" center color={black}>
          {displayName(institution)}
        </Title>
        {(debugPrint || verify) &&
          debugUsage &&
          (debugUsage.licenseUsage.consumed != licenseUsage?.consumed ||
            debugUsage.licenseUsage.reserved != licenseUsage?.reserved) && (
            <Text variant="normal">
              Institution id {debugUsage?.institutionId} Licenses:&nbsp; {debugUsage?.licenseUsage.consumed} consumed,{' '}
              {debugUsage?.licenseUsage.reserved} reserved
            </Text>
          )}
        {institutionContracts && institutionContracts.length > 0 && licenseUsage && (
          <Text variant="sm" center color={black}>
            Licenses:&nbsp;
            {licenseUsage?.issued > 0 ? (
              <>
                <b> {licenseUsage.issued}</b> purchased,{' '}
              </>
            ) : null}
            <b>{licenseUsage.consumed + INCLUDE_RESERVED * licenseUsage.reserved}</b>{' '}
            {INCLUDE_RESERVED ? 'used' : 'consumed'}.
          </Text>
        )}
      </InstitutionContent>
    </Institution>
  );
};

const Institution = styled.div({
  border: `1px solid ${primary}`,
  borderRadius: 3,
  boxShadow: `0 5px 10px ${charcoal15}`,
  position: 'relative',
  margin: '1rem',
});

const InstitutionContent = styled(moduleListStyles.ModuleContent)({
  padding: '1.25rem 1.25rem 1.25rem',
});

const { Title } = moduleListStyles;
