import React from 'react';
import styled from '@emotion/styled';
import {
  primary,
  white,
  secondary,
  darkgray30,
  darkgray40,
  secondaryDesaturated,
  primaryDesaturated,
} from '../utils/colors';
import { useNavigate } from 'react-router-dom';
import { Text } from './text';

export type ButtonVariant = 'default' | 'cancel';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string;
  variant?: ButtonVariant;
  displaySize?: 'lg';
  children?: React.ReactNode;
}

export const Button: React.FC<Props> = (props) => {
  const debugPrint = false;
  const navigate = useNavigate();

  /* We do not inlcude the original "onClick" or "to" in the htmlButtonProps
   * This pattern allows granular control over onClick and routing behavior.
   * This pattern allows all other standard ButtonHTMLAttribute to be
   * passed through
   *
   *  This handler allows the same uniform click handling consistency in the
   * UI while allowing the button to function as both a button or a link
   * as needed.
   */
  const { to, onClick, ...otherParams } = props;
  const htmlButtonProps = otherParams;

  const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (to) {
      navigate(to);
    } else if (onClick) {
      onClick(event);
    } else {
      if (debugPrint) console.warn('Button press has no defined "to" or "onClick" attributes.');
    }
  };

  return (
    <StyledHtmlButton {...htmlButtonProps} onClick={onClickButton}>
      <Text center variant="nav">
        {props.children}
      </Text>
    </StyledHtmlButton>
  );
};

const StyledHtmlButton = styled.button<Props>(
  {
    background: 0,
    outline: 0,
    boxShadow: 'none',
    borderRadius: '.3rem',
    fontSize: '1.3rem',
    cursor: 'pointer',
    marginLeft: 0,
    marginRight: 0,
    textDecoration: 'none',
  },
  ({ variant = 'default', displaySize, disabled }) => ({
    backgroundImage:
      variant === 'default' && disabled
        ? `linear-gradient(206deg, ${darkgray30} 0%, ${darkgray40} 95%)`
        : variant === 'cancel'
        ? `linear-gradient(206deg, ${secondaryDesaturated} 0%, ${primaryDesaturated} 95%)`
        : `linear-gradient(206deg, ${secondary} 0%, ${primary} 95%)`,
    border: 0,
    color: white,
    padding: displaySize === 'lg' ? '1.2rem 3rem' : '.6rem 2.5rem',
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      backgroundImage:
        variant === 'default' && disabled
          ? `linear-gradient(206deg, ${darkgray30} 0%, ${darkgray40} 95%)`
          : variant === 'cancel'
          ? `linear-gradient(206deg, ${secondaryDesaturated} 25%, ${primaryDesaturated} 75%)`
          : `linear-gradient(206deg, ${secondary} 25%, ${primary} 75%)`,
    },
  }),
);
