import { omit } from 'lodash';
import {
  GetLtiPlatformsResponse,
  GetLtiPlatformsResponseRecord,
  RegisterLtiPlatformRequest,
  RegisterLtiPlatformResponse,
  RegisterLtiPlatformResponseRecord,
  UpdateLtiPlatform,
  UpdateLtiPlatformRecord,
} from '../../types/routes/admin';

import { makeRequest } from '.';
import { routes } from '../../types/routes';

export const getLtiPlatforms = async (): Promise<GetLtiPlatformsResponse> => {
  const response = await makeRequest(routes.getLtiPlatforms.method, routes.getLtiPlatforms.path);
  return GetLtiPlatformsResponseRecord.check(response.data);
};

export const registerLtiPlatform = async (data: RegisterLtiPlatformRequest): Promise<RegisterLtiPlatformResponse> => {
  const response = await makeRequest(routes.registerLtiPlatform.method, routes.registerLtiPlatform.path, data);
  return RegisterLtiPlatformResponseRecord.check(response.data);
};

export interface UpdateLtiPlatformId extends UpdateLtiPlatform {
  id: string;
}
export const updateLtiPlatform = async (data: UpdateLtiPlatformId): Promise<UpdateLtiPlatformId> => {
  const response = await makeRequest(
    routes.updateLtiPlatform.method,
    routes.updateLtiPlatform.path.replace(':id', data.id),
    omit(data, ['id']),
  );
  return { id: data.id, ...UpdateLtiPlatformRecord.check(response.data) };
};
